import * as React from "react";
import Select from "@material-ui/core/Select";
// Customizable Area Start
import InputLabel from "@material-ui/core/InputLabel";
import "../css/Dropdown.css";
import { Grid, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles({
   
    select: {
      "& ul": {
          backgroundColor: "#cccccc",
      },
      "& li": {
          fontSize: 12,
      },
  },
  dropdownCss : { 
        minWidth:"310px !important",
        left:"65% !important",
        top: "40% !important" ,
        padding: "2px 2px",
        right:"20% !important",
        "& .MuiPaper-root.MuiMenu-paper.makeStyles-dropdownCss-70.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded":{
          minWidth: "300px !important",
          backgroundColor:"blue"
        },
        "@media screen and (max-width:600px)":{
          width: "180px",
        left:"15% !important",
        top: "10% !important" ,
        padding: "2px 2px",
        right:"10% !important",
        "& div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4":{
          maxWidth: "50%"
        },
      "& div.MuiMenu-paper":{
          maxWidth: "300px",
          maxHeight: "290px"
      }
      },
},
    dropDownContainer : {
        height:"100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
       
      },
    button: {
        display: "inline",
        width: "85%",
        marginLeft: "12px",
        marginRight: "2px",
        borderRadius: "20px",
        border: "2px solid #336699",
        color: "#336699",
        padding: "0px",
        textTransform:"lowercase",
        
        "&:hover": {
            backgroundColor: "#336699",
            color: "#ffffff"
        }
    },  
  });
// Customizable Area End
export default function DropDown(props: any) {
  // Customizable Area Start
  const [btn, setbtn] = React.useState({
    usa: true,
    canada: false,
  });

  const list = [
    {
        label: "10 am",
        isSelected: false
    },
    {
        label: "10 am",
        isSelected: false
    },
    {
        label: "10 am",
        isSelected: false
    },
    {
        label: "10 am",
        isSelected: false
    },
    {
        label: "10 am",
        isSelected: false
    }
  ];

  const classes = useStyles();

  // Customizable Area End
  // Customizable Area Start
  // Customizable Area End

  return (
    // Customizable Area Start
    <div style={{ 
      width: "100%",
   
    }}>
      <InputLabel style={{ marginBottom: "10px",color:"black" }}>{props.label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.value}
        style={{ borderRadius: 20, width: "100%", marginBottom:"12px",
       color:"grey", textTransform:"lowercase"
      }} 
    
      MenuProps={{ classes:{paper:classes.dropdownCss},
          variant: 'menu',
          //setting variant to menu makes it appear below the element
        }}
      // variant: 'menu' 
      //setting variant to menu makes it appear below the element
    // }}

      variant="outlined"
      IconComponent={KeyboardArrowDownIcon}
      >
       

      
        <Box className={classes.dropDownContainer}>
        <Grid container spacing={1}>
          {props.options.map((item: any, id: number) => {
            return (
              <Grid
                item
                xs={12}
                sm={4}
                style={{
                  display: "flex",
                  padding: "2px 2px",
                  marginLeft: "0px"
                }}
              >
                <Button
                    className={classes.button}
                    variant="outlined"
                    
                    onClick={() => {
                        
                        console.log(props.options);
                        props.updateText(props.options[id], props.index);
                      }}
                >
                  {item}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        </Box>
        <option style={{display: "none"}} value={props.value}>{props.value}</option>
      </Select>
    </div>
    // Customizable Area End
  );
}
