const Colors = {
  primary: '#006633',
  secondary: '#919191',
  darkSilver: '#989898',
  lightSilver: '#D8D8D8',
  veryLightSilver: '#ebebeb',
  whiteBackground: '#f8f8f8',
  white: '#ffff',
  black: 'black',
  lightBlack: '#494949',
  mediumBlue: '#35699E',
  darkBlue: '#336699',
  darkSkyBlue: '#064F6F',
  red: '#E93939',
  grey: '#494949',
  blue:'#0044AA',
  lightGrey:"#9E9D9A",
  yellow:"#FCE501"
};

export default Colors;
