import React from "react";
// Customizable Area Start
import { Container, Box,Typography, Accordion,AccordionSummary,AccordionDetails } from "@material-ui/core";
import "../css/LandingPage6.css";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { questionIcon } from "./assets";
const classes: any = {
    root: {
        borderRadius: 30,
      },
      container: {
        width: "100%",
      },
      box6:{
        width: "100%",
        marginLeft: "10%",
        marginTop: "12%",
        marginBottom: "10%", 
      },
      heading: {
        color: "rgb(51, 102, 153)",
        fontWeight: "bolder",
        marginTop: "15%",
        marginLeft: "40%",
        marginBottom: "4%",
        fontSize: "2.45rem",
      },
      quesitonContainer: {
        padding: "10px",
        marginTop: "10px",
        marginBottom: "20px",
        width: "90%"
      },
      questionIcon: {
        color: "#006633",
        marginRight: "10px",
        width: "45px",
        height:"45px"
      },
      questionText: {
        color: "black",
        fontWeight: "bold"
      }
  }
  
// Customizable Area End

const SplashscreenLanding6 = (props: any) => {
  // Customizable Area Start
  // Customizable Area End
  return (
    // Customizable Area Start
    <Container maxWidth="lg" className="landingPage6Container">
          <Box style={classes.box6}>
          <Typography className="faqHeading" variant="h5">
            FAQ's
          </Typography>
          {
                props.faqList.map((item: any, index: number) => {
                    console.log(item?.attributes);
                    return <Accordion 
        
                      style={{borderRadius: "40px", width:"80%", marginLeft:"2%"}}>
                      
                      <AccordionSummary
                        expandIcon={<KeyboardArrowRightIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        style={{borderRadius: "40px"}}
                      >
                        <img src={questionIcon}
                        alt=""
                            style={classes.questionIcon}
                            className="questionIconResponsive"
                            />
                        
                        <Typography style={{fontWeight: "bold", paddingTop: "5px"}} variant="body1">{item?.attributes?.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Typography variant="body1" style={{marginLeft:"15px", textAlign:"justify"}}>{item?.attributes?.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  })
            }
          </Box>
    </Container>
    // Customizable Area End
  );
};

export default SplashscreenLanding6;
