import React from "react";

// Customizable Area Start

import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
const MyTeme = createTheme({
  palette: {
    primary: {
      main: "#006633",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});
import { Logo,verificationSuccess } from "./assets";
// Customizable Area End



import EmailAccountRegistrationController, {Props} from "./EmailAccountRegistrationController";
import SimpleBottomNavigation from "../../dashboard/src/Footer/index.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class VerificationSuccess extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
  }



  // Customizable Area End

  render() {
    return (
      // Required for all blocks
      // Customizable Area Start
      <ThemeProvider theme={MyTeme}>
        <div className="container" style={{backgroundColor:"white"}}>
          <div className="header">
            <img data-test-id="imagelogo" src={Logo} alt="" className="logoImg" onClick={() => {this.props.navigation.navigate("Home")}}/>
          </div>
          <div className="bodyContainer" >
            <img src={verificationSuccess} alt="" style={{marginBottom:"-20px"}} />
            <Typography data-test-id="typo-verified" variant="h6" style={{ fontWeight: "bold",fontSize:"", color: "#336699",marginRight:"4px"}} >You are now verified!</Typography>
            <hr style={{ width:"300px", marginTop:"10px" }}/>    
             <Typography variant="body1" style={{marginBottom:"0px", width:"20%", textAlign:"center"}}>Voila! You have successfully verified the account.</Typography>         
              <Button data-test-id="lets-start-btn" style={{ color: "white!important",marginTop:"30px" ,borderRadius: 20, textTransform: "none", width: "300px" }} variant="contained" color="primary"
              onClick={() => {this.props.navigation.navigate("EmailAccountLoginBlock")
              history.pushState(null,"",location.href);
              window.onpopstate=()=>{
                history.go(1)
              }
              }}>
              Let's Start!
            </Button>
          </div>
        </div>
        <SimpleBottomNavigation/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
