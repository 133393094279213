//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  fleetStatus:any;
  token: string;
  errorMsg: string;
  loading: boolean;
  batteryPercentage: number;
  batterySliderStatus:boolean;
  unreadCount:number;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  apiDashboardFleetStatusId: string="";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiDashboardBatteryValueId:string="";
  apiDashboardGetBatteryValueId:string="";
  getDataCallId:string="";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      fleetStatus:{},
      errorMsg: "",
      token: "",
      loading: false,
      batteryPercentage:0,
      batterySliderStatus:false,
      unreadCount:0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    await this.getDashboardData();
    this.updateBatteryValues();
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("windToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  // Customizable Area Start
  getFleetStatus(): boolean {
  
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("windToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardFleetStatusId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.dashboardGetFleetStatusUrl}?late_night_status=false`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getNotifications= ()=> {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const token = localStorage.getItem("windToken")
    this.getDataCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointNotifications
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }
    // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        
        this.getFleetStatus();
        this.getNotifications();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if(apiRequestCallId && responseJson && !responseJson.errors && responseJson !== undefined){
        console.log("Response json",responseJson);
        switch(apiRequestCallId){
          case this.apiDashboardItemCallId:
            this.setState({
              dashboardData:responseJson.response,
              errorMsg:"",
              loading:false
            });
            break;
          case this.apiDashboardFleetStatusId:
            this.setState({
              fleetStatus:responseJson,
              errorMsg:"",
              loading:false
            });
            break;
          case this.apiDashboardGetBatteryValueId:  
          this.setState({
              batterySliderStatus: false,
              batteryPercentage: responseJson.battery_percentage,
              errorMsg:"",
              loading:false
            });
            break;
          case this.getDataCallId:
            this.setState({
              unreadCount:responseJson?.meta?.unread_count,
              errorMsg:"",
              loading:false
            });
            localStorage.setItem("unreadCount",JSON.stringify(responseJson?.meta?.unread_count));
            break;
          default:
            this.setState({
              errorMsg: errorReponse,
              loading: false
            });
            break;
        }
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false
          });
        } 
      } else if( responseJson.errors && responseJson.errors[0].token !== undefined){
        console.log("Response json",responseJson);
        localStorage.removeItem("windUser");
        localStorage.removeItem("windToken");
        this.props.navigation.navigate("EmailAccountLoginBlock"); 
      }
    }
      
    // Customizable Area End
  }

  // Customizable Area Start
  setBatteryPercentage=(batteryValue:number)=>{
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("windToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const userId = JSON.parse(localStorage.getItem("windUser")!) && JSON.parse(localStorage.getItem("windUser")!).data.id;
    this.apiDashboardBatteryValueId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.dashboardPutBatteryValueUrl}/${userId}/add_battery_percentage?battery_percentage=${batteryValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  updateBatteryValues=():boolean=>{
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("windToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardGetBatteryValueId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetBatteryValueUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  // Customizable Area End

}
