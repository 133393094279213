import React from "react";

// Customizable Area Start
import "../css/Login.css";
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { googleIcon, Logo, sideCover } from "./assets";
import GTranslateSharpIcon from "@material-ui/icons/GTranslateSharp";

const MyTeme = createTheme({
  palette: {
    primary: {
      main: "#006633",
      contrastText: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class WebEmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={MyTeme}>
        <div className="containerBlock">
          <div className="leftBlock">
            <img
              src={sideCover}
              alt=""
            />
          </div>
          <div className="rightBlock">
            <img src={Logo} alt="" onClick={() => {this.props.navigation.navigate("Home")}}/>
            <div className="loginForm">
              <div className="formGroup">
                <label htmlFor="loginEmail">Email</label>
                <input
                  type="text"
                  id="loginEmail"
                  name="loginEmail"
                  value={this.state.email}
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="formGroup">
                <label htmlFor="loginPassword">Password</label>
                <input
                  type="password"
                  id="loginPassword"
                  name="loginPassword"
                  value={this.state.password}
                  onChange={(e) => {
                    this.setState({
                      password: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="loginForgetpasswordContainer">
                <p>Forget passwrod?</p>
              </div>

              <Button
                style={{
                  width: "50%",
                  borderRadius: "30px",
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                variant="contained"
                color="primary"
                onClick={this.doEmailLogIn}
              >
                Login
              </Button>

              <Button
                variant="contained"
                color="secondary"
                style={{
                  width: "50%",
                  borderRadius: "30px",
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                startIcon={<img
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: '0',
                    padding: "0"
                  }}
                   src={googleIcon} alt=""/>}
              >
                Google Login
              </Button>

              <p className="signupOption">
                Not a member? <span>Signup</span>
              </p>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
