import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import "firebase/messaging";
import { fireBaseApp } from "../../social-media-account/src/GoogleFirebase.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token:string;
  email:string;
  pass:string;
  fcmToken1:string;
  isTokenFound:boolean;
  notificationTitle:string;
  notificationBody:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BroadcastMessageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotifyApiCallId:string="";
  apiEmailLoginId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      fcmToken1:"",
      email:"",
      pass:"",
      isTokenFound:false,
      notificationTitle:"",
      notificationBody:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) 
    {//istanbul ignore next
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      if (apiRequestCallId != null) //istanbul ignore next 
      {
               
          if (!responseJson.errors)//istanbul ignore next
           {
            if (apiRequestCallId === this.apiEmailLoginId) //istanbul ignore next
            {

              if (!responseJson.errors)//istanbul ignore next
               {
                runEngine.debugLog(responseJson.data,'@@@@@ API Response')
                alert("Device Token Sucessfully Send")
                this.setupNotification();
                this.setState({email:"",pass:""})
            }                 
         }
        }
      }
    }
    // Customizable Area End
  }
  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

 
  // Customizable Area Start
  async componentDidMount() {
    await this.getFcmToken();
   }
  
  setEmail = (emailValue:string)=>{
    this.setState({
      email:emailValue
    })
  }
  handleClose=()=>{
    this.setState({
      isTokenFound:!this.state.isTokenFound
    })
  }
  setPassword = (passValue:string)=>{
    this.setState({
      pass:passValue
    })
  }
  setupNotification = () => //istanbul ignore next
  {
      try{
        fireBaseApp.messaging().onMessage((payload)=>{ //istanbul ignore next
          if(payload!==''){
            this.setState({
              isTokenFound:true,
              notificationTitle:payload.notification.title,
              notificationBody:payload.notification.body
            })
        }
        })
      } catch(errFirebase){
        runEngine.debugLog("Error", errFirebase)
      } 
          
    
  };

  onRegister = () => {
     const header = 
         {
        'Content-Type': "application/json",
      };
        const attrs = 
        {
        email: this.state.email,
        password:this.state.pass,
      };
      const registerData = {
        type: 'email_account',
        attributes: attrs,
        device_token :this.state.fcmToken1
  
      };
      
      const httpBody = {
        data: registerData,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiEmailLoginId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
         "https://windeverest-225652-ruby.b225652.stage.eastus.az.svc.builder.ai/login/login/"
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
  };

  onNotification = (notify: string) => {
  };

  onOpenNotification = (notify: string) => {
  };

  getFcmToken = async () => 
  {
    const messaging = fireBaseApp.messaging();
    messaging.requestPermission().then(()=>{
      return messaging.getToken();
    }).then(token=>{
      this.setState({fcmToken1:token});
    }).catch(errToken=>{
      runEngine.debugLog("Error",errToken);
    })
  }

 onlogin=async()=>//istanbul ignore next
 {
  
 } 
  // Customizable Area End
}
