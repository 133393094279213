Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Gamification";
exports.labelBodyText = "Gamification Body";
exports.exampleMessage = "Badges cannot be displayed";
exports.exampleBadgesLoadingError = "Badges are showing an error";
exports.LoadingBadge = "Loading...";
exports.getBadgesAPI = "/bx_block_gamification/user_badges";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End