import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  checkedActionBar: boolean;
  checkedActionBarText: string;
  notificationData: any;
  token: string;
  reminder:boolean;
  vacation:boolean;
  notificationReminderSettings:any;
  firstReminder:string;
  secondReminder:string;
  morningReminder:string;
  firstReminderDropDown:string[];
  secondReminderDropDown:string[];
  thirdReminderDropDown:string[];
  vacationBegin:any;
  vacationEnd:any;
  vacationDefault:string|number;
  defaultVacationCommitment:{name:string,value:string|number}[];
  vacationModeSettings:any;
  easyModePremium:boolean;
  easyModeDefault:string|number;
  easyModeFirst:string;
  easyModeSecond:string;
  easyModeDefaultList:{name:string,value:string|number}[];
  easyModeFirstList:string[];
  easyModeSecondList:string[];
  easyMode:boolean;
  easyModeSettings:any;
  isError:boolean;
  errorMessage:string;
  vacationBeginDate:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NotificationsettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  notificationAPICallId: any;
  setNotificationAPICall: any;
  setNotificationGroupAPICall: any;
  setNotificationSubGroupAPICall: any;
  // Code Exception Area Start
  setNotificationReminderAPICall:any;
  getNotificationReminderAPICall:any;
  setVacationModeAPICall:any;
  setEasyModeAPICall:any;
  getVacationReminderAPICall:any;
  getEasyModeReminderAPICall:any;
  // Code Exception Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      checkedActionBar: false,
      checkedActionBarText: configJSON.notificationGroup1,
      notificationData: [],
      token: "",
      reminder:false,
      vacation:false,
      firstReminder:"None",
      secondReminder:"None",
      morningReminder:"None",
      firstReminderDropDown:[ "None","04:00 pm","04:15 pm","04:30 pm","04:45 pm","05:00 pm","05:15 pm","05:30 pm","05:45 pm","06:00 pm","06:15 pm","06:30 pm","06:45 pm",
      "07:00 pm", "07:15 pm", "07:30 pm", "07:45 pm","08:00 pm","08:15 pm","08:30 pm","08:45 pm","09:00 pm","09:15 pm","09:30 pm","09:45 pm",
      "10:00 pm","10:15 pm","10:30 pm","10:45 pm"],
      secondReminderDropDown:["None","05:00 pm", "05:15 pm", "05:30 pm", "05:45 pm","06:00 pm","06:15 pm","06:30 pm","06:45 pm",
      "07:00 pm", "07:15 pm", "07:30 pm", "07:45 pm","08:00 pm","08:15 pm","08:30 pm","08:45 pm","09:00 pm","09:15 pm","09:30 pm","09:45 pm",
      "10:00 pm","10:15 pm","10:30 pm","10:45 pm","11:00 pm","11:15 pm","11:30 pm","11:45 pm"],
      thirdReminderDropDown:["None","02:00 am", "02:15 am", "02:30 am", "02:45 am","03:00 am","03:15 am","03:30 am","03:45 am",
      "04:00 am","04:15 am","04:30 am","04:45 am","05:00 am","05:15 am","05:30 am","05:45 am","06:00 am","06:15 am","06:30 am","06:45 am",
      "07:00 am","07:15 am","07:30 am","07:45 am","08:00 am","08:15 am","08:30 am","08:45 am","09:00 am","09:15 am","09:30 am","09:45 am",
      "10:00 am","10:15 am","10:30 am","10:45 am","11:00 am","11:15 am","11:30 am","11:45 am"],
      vacationBegin:"DD/MM/YYYY",
      vacationEnd:"DD/MM/YYYY",
      vacationBeginDate:"",
      vacationDefault:"",
      easyMode:false,
      easyModeDefault:"",
      easyModeFirst:"None",
      easyModeSecond:"None",
      easyModePremium:true,
      easyModeDefaultList:[{name:"None",value:""},{name:"Decline charge",value:0},{name:"5% charge",value:5},{name:"10% charge",value:10},{name:"15% charge",value:15},{name:"20% charge",value:20},{name:"25% charge",value:25},{name:"30% charge",value:30},{name:"35% charge",value:35},{name:"40% charge",value:40},],
      easyModeFirstList:["None","12:00 pm","12:15 pm","12:30 pm","12:45 pm",
      "01:00 pm", "01:15 pm", "01:30 pm", "01:45 pm","02:00 pm","02:15 pm","02:30 pm","02:45 pm",
      "03:00 pm", "03:15 pm", "03:30 pm", "03:45 pm","04:00 pm","04:15 pm","04:30 pm","04:45 pm",
      "05:00 pm", "05:15 pm", "05:30 pm", "05:45 pm","06:00 pm","06:15 pm","06:30 pm","06:45 pm",
      "07:00 pm", "07:15 pm", "07:30 pm", "07:45 pm","08:00 pm","08:15 pm","08:30 pm","08:45 pm","09:00 pm","09:15 pm","09:30 pm","09:45 pm",
      "10:00 pm","10:15 pm","10:30 pm","10:45 pm","11:00 pm","11:15 pm","11:30 pm","11:45 pm",
      "12:00 am", "12:15 am", "12:30 am", "12:45 am","01:00 am","01:15 am","01:30 am","01:45 am",
      "02:00 am",],
      easyModeSecondList:["None","12:00 pm","12:15 pm","12:30 pm","12:45 pm",
      "01:00 pm", "01:15 pm", "01:30 pm", "01:45 pm","02:00 pm","02:15 pm","02:30 ","02:45 pm",
      "03:00 pm", "03:15 pm", "03:30 pm", "03:45 pm","04:00 pm","04:15 pm","04:30 pm","04:45 pm",
      "05:00 pm", "05:15 pm", "05:30 pm", "05:45 pm","06:00 pm","06:15 pm","06:30 pm","06:45 pm",
      "07:00 pm", "07:15 pm", "07:30 pm", "07:45 pm","08:00 pm","08:15 pm","08:30 pm","08:45 pm","09:00 pm","09:15 pm","09:30 pm","09:45 pm",
      "10:00 pm","10:15 pm","10:30 pm","10:45 pm","11:00 pm","11:15 pm","11:30 pm","11:45 pm",
      "12:00 am", "12:15 am", "12:30 am", "12:45 am","01:00 am","01:15 am","01:30 am","01:45 am",
      "02:00 am"],
      defaultVacationCommitment:[{name:"None",value:""},{name:"Decline charge",value:0},{name:"2% charge",value:2},{name:"4% charge",value:4},{name:"10% charge",value:10}],
      notificationReminderSettings:{},
      vacationModeSettings:{},
      easyModeSettings:{},
      isError:false,
      errorMessage:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    await this.getNotificationSettings();
    await this.getEasyModeSettings();
    await this.getVacationSettings();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
      });
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson.data) {
        switch(apiRequestCallId){
          case this.notificationAPICallId:
            this.setState({ notificationData: responseJson.data });
            break;
          case this.setNotificationReminderAPICall:
            this.setState({ notificationData: responseJson.data });
            break;
          case this.getNotificationReminderAPICall:
            localStorage.setItem("windUserNotificationSettings",JSON.stringify(responseJson.data));
            this.setState({ notificationReminderSettings: responseJson?.data?.attributes });
            this.setState({
              firstReminder: responseJson?.data?.attributes?.first_reminder,
              secondReminder: responseJson?.data?.attributes?.second_reminder,
              morningReminder: responseJson?.data?.attributes?.morning_reminder,
              reminder: responseJson?.data?.attributes?.active
            })
            break;
          case this.setVacationModeAPICall:
            this.setState({ vacationModeSettings: responseJson?.data?.attributes });
            this.setState({
              vacationBegin: responseJson?.data?.attributes?.vacation_begin,
              vacationEnd: responseJson?.data?.attributes?.vacation_end,
              vacation: responseJson?.data?.attributes?.active 
            })
            this.setVacationDefaultPercentage(responseJson);
            let [dayBegin,monthBegin,yearBegin] = this.state.vacationBegin.split("/");
            let [dayEnd,monthEnd,yearEnd] = this.state.vacationEnd.split("/");
            this.setState({
              vacationBegin:dayBegin.concat("/",monthBegin,"/",yearBegin),
              vacationEnd:dayEnd.concat("/",monthEnd,"/",yearEnd)
            })
            break;
            case this.getEasyModeReminderAPICall:
                this.getEasyModeReminderMethod(responseJson);  
             break;
             case this.getVacationReminderAPICall:
              this.setState({ vacationModeSettings: responseJson?.data?.attributes });
              this.setState({
                vacationBegin: responseJson?.data?.attributes?.vacation_begin,
                vacationEnd: responseJson?.data?.attributes?.vacation_end,
                vacation: responseJson?.data?.attributes?.active 
              })
              this.setVacationDefaultPercentage(responseJson);
              const [dayBeginAPI,monthBeginAPI,yearBeginAPI] = this.state.vacationBegin.split("/");
              const [dayEndAPI,monthEndAPI,yearEndAPI] = this.state.vacationEnd.split("/");
              this.setState({
                vacationBegin:dayBeginAPI.concat("/",monthBeginAPI,"/",yearBeginAPI),
                vacationEnd:dayEndAPI.concat("/",monthEndAPI,"/",yearEndAPI)
              })
              break;
          default:
            this.parseApiErrorResponse(responseJson);
            this.setState({ notificationData: this.state.notificationData });
            this.parseApiCatchErrorResponse(errorReponse);
            break;
          }
    } else if(responseJson.errors && responseJson.errors[0].token !== undefined){
      localStorage.removeItem("windUser");
      localStorage.removeItem("windToken");
      this.props.navigation.navigate("EmailAccountLoginBlock"); 
    } 
  }
    // Customizable Area End
  }

  // Customizable Area Start
  getEasyModeReminderMethod=(responseJsonData:any)=>{
    if(responseJsonData.errors){
      this.setState({
        easyModePremium: true
      })
    }else{
      this.setState({ easyModeSettings: responseJsonData?.data?.attributes });
      this.setState({
        easyModeFirst: responseJsonData?.data?.attributes?.easy_prompt_time,
        easyModeSecond: responseJsonData?.data?.attributes?.second_easy_prompt_time,
        easyMode: responseJsonData?.data?.attributes?.active,
        easyModePremium: !JSON.parse(localStorage.getItem("windUser")!).data.attributes.premium_user
      })
            this.setEasyModeDefaultPercentage(responseJsonData);     
    }
  }
    setVacationDefaultPercentage=(responseJsonData:any)=>{
      let vacationDefaultVar = this.state.defaultVacationCommitment.find((item)=>{

        return item.value===responseJsonData?.data?.attributes?.default_commitment
      })
        this.setState({
          vacationDefault:vacationDefaultVar?.value!
        })
    }
    setEasyModeDefaultPercentage=(responseJsonData:any)=>{
      let easyModeDefaultVar = this.state.easyModeDefaultList.find((item)=>{
        return item.value===responseJsonData?.data?.attributes?.nights_ahead
      })
        this.setState({
          easyModeDefault:easyModeDefaultVar?.value!
        })
    }

    handleFirstReminder= (event:any)=>{
      let firstReminder = (event.target.value as string);
  
    this.setState({
      firstReminder:firstReminder,
      notificationReminderSettings:{
        "first_reminder":firstReminder,
        "second_reminder":this.state.secondReminder,
        "morning_reminder":this.state.morningReminder,
        "active":this.state.reminder

        }
    } ,()=>{
      this.postNotificationReminderSettings();
    })
  }
   handleSecondReminder=(event:any)=>{
    let secondReminder = (event.target.value as string);
    this.setState({
      secondReminder:secondReminder,
      notificationReminderSettings:{
        "first_reminder":this.state.firstReminder,
        "second_reminder":secondReminder,
        "morning_reminder":this.state.morningReminder,
        "active":this.state.reminder
        }
    } ,()=>{
      this.postNotificationReminderSettings();
    })
  }
   handleMorningReminder=(event:any)=>{
    let morningReminder = (event.target.value as string);
    this.setState({
      morningReminder:morningReminder,
      notificationReminderSettings:{
          "first_reminder":this.state.firstReminder,
          "second_reminder":this.state.secondReminder,
          "morning_reminder":morningReminder,
          "active":this.state.reminder
        }
    } ,()=>{
      this.postNotificationReminderSettings();
    })
  }
   handleVacationBeginChange=(date:any)=>{
    let dayBegin = date.getDate();
    let monthBegin = date.getMonth()+1;
    let yearBegin = date.getFullYear();
    let vacationBegin = `${dayBegin}/${monthBegin}/${yearBegin}`;
    this.setState({
      vacationBegin:vacationBegin,
      vacationBeginDate:date,
      vacationModeSettings:{
        "vacation_begin":vacationBegin,
        "vacation_end":this.state.vacationEnd,
        "default_commitment":this.state.vacationDefault,
        "active":this.state.vacation
      }
    } ,()=>{
      this.postVacationModeSettings();
    })
  }
  handleVacationEndChange=(date:any)=>{
    let dayEnd = date.getDate();
    let monthEnd = date.getMonth()+1;
    let yearEnd = date.getFullYear();
    let vacationEnd = `${dayEnd}/${monthEnd}/${yearEnd}`
    this.setState({
      vacationEnd:vacationEnd,
      vacationModeSettings:{
        "vacation_begin":this.state.vacationBegin,
        "vacation_end":vacationEnd,
        "default_commitment":this.state.vacationDefault,
        "active":this.state.vacation
      }
    } ,()=>{
      this.postVacationModeSettings();
    })
  }
  handleVacationDefault=(event:any)=>{
    let vacationDefault = (event.target.value as string);
    this.setState({
      vacationDefault:vacationDefault,
      vacationModeSettings:{
        "vacation_begin":this.state.vacationBegin,
        "vacation_end":this.state.vacationEnd,
        "default_commitment":vacationDefault,
        "active":this.state.vacation
      }
    } ,()=>{
      this.postVacationModeSettings();
    })
  }
  handleEasyModeFirst=(event:any)=>{
    let easyModeFirst = (event.target.value as string)
    this.setState({
      easyModeFirst:easyModeFirst,
      easyModeSettings:{
        "nights_ahead":this.state.easyModeDefault,
        "easy_prompt_time":easyModeFirst,
        "second_easy_prompt_time":this.state.easyModeSecond,
        "active":this.state.easyMode
      }
    } ,()=>{
      this.postEasyModeSettings();
    })
  }
  handleEasyModeSecond=(event:any)=>{
    let easyModeSecond = (event.target.value as string);
    this.setState({
      easyModeSecond:easyModeSecond,
      easyModeSettings:{
        "nights_ahead":this.state.easyModeDefault,
        "easy_prompt_time":this.state.easyModeFirst,
        "second_easy_prompt_time": easyModeSecond,
        "active":this.state.easyMode
      }
    } ,()=>{
      this.postEasyModeSettings();
    })
  }
  handleEasyModeDefault=(event:any)=>{
    let easyModeDefault = (event.target.value as string);
    this.setState({
      easyModeDefault:easyModeDefault,
      easyModeSettings:{
        "nights_ahead":easyModeDefault,
        "easy_prompt_time":this.state.easyModeFirst,
        "second_easy_prompt_time":this.state.easyModeSecond,
        "active":this.state.easyMode
      }
    } ,()=>{
      this.postEasyModeSettings();
    })
  }
   getNotificationSettings=():boolean=>{
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationReminderAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationAPIEndPoint
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getVacationSettings=():boolean=>{
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVacationReminderAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationVacationModeAPIEndPoint
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getEasyModeSettings=():boolean=>{
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEasyModeReminderAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationEasyModesAPIEndPoint
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  postNotificationReminderSettings = (): boolean => {
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

      this.setNotificationReminderAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({"data":{"notification_reminder_settings":this.state.notificationReminderSettings}})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  postVacationModeSettings =(): boolean =>{
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

      this.setVacationModeAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationVacationModeAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({"data":{"vacation_mode_settings":this.state.vacationModeSettings}})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }
  postEasyModeSettings =(): boolean =>{
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

      this.setEasyModeAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationEasyModesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({"data":{"easy_modes":this.state.easyModeSettings}})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }
  // Customizable Area End
}
