import React from "react";

// Customizable Area Start
import "../css/Login.css";
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  Snackbar,

} from "@material-ui/core";
// import MuiAlert, { AlertProps } from '@material-ui/core/Ale';
import Alert from '@material-ui/lab/Alert';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { googleIcon, Logo, sideCover } from "./assets";
import GTranslateSharpIcon from "@material-ui/icons/GTranslateSharp";

const MyTeme = createTheme({
  palette: {
    primary: {
      main: "#006633",
      contrastText: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

import CoustomTextInput from "../../email-account-registration/src/CoustomTextInput.web";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class LoginScreen extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkCredentials =()=>{
    if(this.state.emailError && this.state.email==""){
      this.setState({
        isError: true,
        errorMessage: "Please Enter your email id"
      });
    }
    if(this.state.passwordError && this.state.password==""){
      this.setState({
        isError: true,
        errorMessage: "Please Enter your Password"
      });
    }
    if(this.state.email.length===0 && this.state.password.length===0){
      this.setState({
        isError: true,
        errorMessage: "Please Enter your credentials."
      });
    }
  }
  updateEmail = (value: string) => {
    this.setState({
      email: value
    });
  }

  updatePassword = (value: string) => {
    this.setState({
      password: value
    });
  }

  emailValidater = (setShowError: any, value?: string) => {
    if (value!.length === 0) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }

  passwordValidater = (setShowError: any, value?: string) => {
    if (value!.length === 0) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }


  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={MyTeme}>

        <Grid  container style={{ display: "flex", flexDirection: "row", height: "100%",backgroundColor:"white" }}>
          <Grid item sm={12} md={6} className="headingContainer">
            <img
              src={sideCover}
              alt=""
              className="loginLogo"
              style={{
                width: "100%"
                , height: "100%",
                //  objectFit: "contain"
              }}
            />
          </Grid>
          <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "100%" }}>
            <Snackbar open={this.state.isError} autoHideDuration={6000} onClose={() => this.setState({ isError: false })}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert severity="error">{this.state.errorMessage}</Alert>
            </Snackbar>

            <img data-test-id="imageHome" src={Logo} alt="" style={{ width: "250px", marginBottom: "10px", marginTop: "20px" }} onClick={() => { this.props.navigation.navigate("Home") }} />
            <div className="loginForm">
              <div className="loginformGroup">
                <CoustomTextInput
                  label={"Email"}
                  value={this.state.email}
                  updateText={this.updateEmail}
                  validater={this.emailValidater}
                  errorMssage={
                    "Email is mandatory"
                  }
                  alterMsg={
                    "Email is mandatory"
                  }
                  showError={this.state.emailError}
                />
              </div>
              <div className="loginformGroup">
                <CoustomTextInput
                  password
                  label={"Password"}
                  value={this.state.password}
                  updateText={this.updatePassword}
                  validater={this.passwordValidater}
                  errorMssage={"Password is mandatory"}
                  alterMsg={
                    "Password is mandatory"
                  }
                  showError={this.state.passwordError}
                />
              </div>
              <div className="loginForgetpasswordContainer">
                <p onClick={() => {
                  this.props.navigation.navigate("WebForgetPassword");
                }}>Forgot your password?</p>
              </div>

              <Button
                style={{
                  width: "50%",
                  borderRadius: "30px",
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                  marginBottom: "10px",
                  textTransform: "none"
                }}
                variant="contained"
                color="primary"
                onClick={(e) => { this.checkCredentials();this.doEmailLogIn() }}
                className="buttonlogin"
              >
                Login
              </Button>

              <Button
                variant="contained"
                color="secondary"
                style={{
                  width: "50%",
                  borderRadius: "30px",
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "10px",
                  textTransform: "none"
                }}
                startIcon={<img
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: '0',
                    padding: "0"
                  }}
                  src={googleIcon} alt="" />}
                className="buttonlogin"
                onClick={()=>this.goToSocialLogin()}
              >
                Google Login
              </Button>

              <p className="signupOption">
                Not a member? <span onClick={() => {
                  this.props.navigation.navigate("CreateNewAccount");
                }}>Signup</span>
              </p>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}
