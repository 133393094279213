import React, { useState } from "react";
// Customizable Area Start
import {
  makeStyles,
  createStyles,
  Theme as AugmentedTheme,
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import '../css/faq.css'
import {
  Button,
  Divider,
  Select,
  TextField,
  Typography,
  Paper
} from "@material-ui/core";
import NavigationMenuController, {
  Props,
} from "./NavigationMenuController";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { faqIcon, questionIcon } from "./assets";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
const MyTeme = createTheme({
palette: {
    primary: {
    main: "#006633",
    contrastText: "#FFFFFF"
    },
    secondary: {
    main: "#FFFFFF"
    }
}
});

// Customizable Area End

// Customizable Area Start
const classes: any = {
    header: {
        fontWeight: "bold",
        color: "#336699",
        marginBottom: "10px"
      },
      quesitonContainer: {
        padding: "10px",
        marginTop: "10px",
        marginBottom: "20px",
        width: "90%"
      },
      questionIcon: {
        color: "#006633",
        marginRight: "10px",
        width: "40px",
        height:"40px"
      },
      questionText: {
        color: "black",
        fontWeight: "bold"
      }
};

// Customizable Area End

export default class FaqScreen extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={MyTeme}>
        <div style={classes.quesitonContainer}>
            <Typography
            style={classes.header}
            variant={"h6"}>FAQ's</Typography>

            {
                this.state.questionList.map((item: any, index: number) => {
                    console.log(item?.attributes);
                    return <Accordion 
        
                      style={{borderRadius: "40px"}}>
                      
                      <AccordionSummary
                        expandIcon={<KeyboardArrowRightIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        style={{borderRadius: "40px"}}
                      >
                        <img src={questionIcon}
                        alt=""
                            style={classes.questionIcon}
                            className="questionIconResponsive"
                            />
                        
                        <Typography style={{fontWeight: "bold", paddingTop: "5px"}} variant="body1">{item?.attributes?.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Typography variant="body1" style={{marginLeft:"15px"}}>{item?.attributes?.answer}</Typography>
                      </AccordionDetails>
                      
                    </Accordion>
                  })
            }
         </div>
        </ThemeProvider>
      // Customizable Area End
    );
  }
}
