import React, { useEffect, useRef, useState } from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Button,
    Typography
} from "@material-ui/core";
import '../css/OTPVerify.css'
const MyTeme = createTheme({
    palette: {
        primary: {
            main: "#006633",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#FFFFFF",
        }, 
    },
});

// Customizable Area End

export default function IconLabelButtons(props:any) {

    // Customizable Area Start
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [activeOTPindex, setActiveOTPindex] = useState<Number>();
    const inputRef = useRef<HTMLInputElement>(null);

    const handleOnChange = (e: any, index: number) => {
        const value = e.target.value;
        const newOTP = [...otp];
        newOTP[index] = value.substring(value.length - 1);
        if (!value) setActiveOTPindex(index - 1);
        else setActiveOTPindex(index + 1);
        setOtp(newOTP);
    };

    const handleOnKeyDown = (e: any, index: number) => {
        const key = e.key;
        if (key === "Backspace") {
            setActiveOTPindex(index - 1);
        }
    }

    // Customizable Area End

    useEffect(() => {
        inputRef.current?.focus();
    }, [activeOTPindex]);


    return (
        // Customizable Area Start
        <ThemeProvider theme={MyTeme}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width:"300px",
            }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {otp.map((_, index) => {
                        return (
                            <div
                                style={{
                                    width: "60px",
                                    height: "40px",
                                    marginRight:"15px",
                                }}
                                key={index}
                            >
                                <input
                                    ref={index === activeOTPindex ? inputRef : null}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        border: "none",
                                        backgroundColor:"rgba(0,0,0,0)",
                                        borderBottom: "1px solid lightgrey",
                                        outline: "none",
                                        textAlign: "center",
                                        fontSize: "25px",

                                    }}
                                    data-test-id="customtextOtpInputTest"
                                    value={otp[index]}
                                    type="number"
                                    onChange={(e) => handleOnChange(e, index)}
                                    onKeyDown={(e) => handleOnKeyDown(e, index)}
                                />
                            </div>
                        );
                    })}

                </div>
                <Button
                    style={{
                        width: "100%",
                        height:"80%",
                        borderRadius: "30px",
                        color: "white",
                        backgroundColor:"#006633",
                        fontWeight: "bold",
                        marginTop: "30px",
                        marginBottom: "10px",
                        textTransform: "none",
                        textDecoration: "none"
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        console.log(otp);
                        props.verificationCall(otp)
                    }}
                    data-test-id="verifyOTPBtn"
                    disabled={!Object.keys(otp).every((k:any) => otp[k])}
                    className="verifybtn"
                >
                    Verify
                </Button>
            </div>
        </ThemeProvider>
        // Customizable Area Start
    );
}
