export const userProfile = require("../assets/user-profile.png");

export const Logo = require("../assets/windeverest-icon.jpg");

export const aboutIcon = require("../assets/image_about.png");

export const contactusIcon = require("../assets/image_contact.png");
export const faqIcon = require("../assets/image_faqs.png");
export const notificationIcon = require("../assets/image_notification.png");
export const privacyIcon = require("../assets/image_pivacy.png");
export const termConditionIcon = require("../assets/image_terms.png");
export const chargeRecordingIcon = require("../assets/late_charge_recording.png");
export const profileIcon = require("../assets/image_profile.png");
export const carImg = require("../assets/car_img.png");
export const bellIcon = require("../assets/notification-icon.png");
export const questionIcon = require("../assets/quesiton-icon.png");
export const homeIcon = require("../assets/home_icon.png");
export const statIcon = require("../assets/stats_icon.png");
export const groupIcon = require("../assets/group_icon.png");
