// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid
} from "@material-ui/core";
import "../css/DashboardWeb.css";
import DashboardController from "./DashboardController";
import CustomSlider from "./CustomSlider.web";
import CustomDataTable from "./CustomDataTable.web";
const classes: any = {
  root: {
    paddingRight: "60px",
    marginLeft:"25px"
  },
  paperContainer: {
    width: "90%",
    height: "100%",
    padding: 20,
    displayBlock:"flex",
    borderRadius:"10px"
  },
  heading: {
    fontWeight: "bold",
    color: "#336699",
    marginTop:72,
    fontSize:20,

  },
  title:{
    fontSize:16,
  },
  imgContainer: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    objectFit: "cover",
    display: "block",    
  },
  container: {
    display: "flex",
    justifyContent: "left",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 20,

  },
  leftContainer: {
    flex: 0.5,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  rightContainer: {
    flex: 0.5,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  description: {
    textAlign: "justify",
    color: "black"
  },
  textFieldContainer:{
    width:"100%",
    marginLeft:"-90px",
    marginTop:"32px"
  }
}
// Customizable Area End


export default class Dashboard extends DashboardController {
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
  }
   
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className="rootDashboard">
        <Box
            style={{
            width: "100%",
            marginLeft:"-20px"
          }}
        >          
          <Typography className="weeklyHeading" variant="h5">
            Week Ahead
          </Typography>
          <Grid container className="dashboardBodyContainers">
            <Grid item sm={12} md={12} lg={6} className="gridsm">
              <CustomDataTable weekData={this.state.dashboardData}></CustomDataTable>
            </Grid>
            <Grid item sm={12} md={12} lg={6} className="dashboardContainerRight">
                <Box className="textFieldContainer">
                  
                  <CustomSlider batterySliderStatus={this.state.batterySliderStatus} setBatteryPercentage={this.setBatteryPercentage} batteryValue={this.state.batteryPercentage} fleetStatus={this.state.fleetStatus}></CustomSlider>
                </Box>
              </Grid>
          </Grid>
            
        </Box>
      </Container>
      // Customizable Area End
    );
  }
}
