"use strict";


const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";
// const baseURL = "https://windeverest-225652-ruby.b225652.stage.eastus.az.svc.builder.ai/";
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
