// Core exception code Start
import React, { useState,useEffect } from "react";
// Core exception code End
// Customizable Area Start
import {
  makeStyles,
} from "@material-ui/core/styles";
import { Box,Grid,Typography,Slider,Modal } from "@material-ui/core";
import "../css/CustomSlider.css";
import { batteryIcon,infoIcon,batteryMeter,infoModal,closeModal } from "./assets";
import CustomBarGraph from "./CustomBarGraph.web";
const useStyles = makeStyles({
    batterySlider:{
        color:"white",
        "& .MuiSlider-valueLabel":{
            fontSize: "0.85rem",
            marginTop:"17px",
            height:"20px",
            width:"35px",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            border:"1px solid white",
            fontWeight:"normal",
            backgroundColor:"unset",
            color:"white",
            "&:before":{
                display:"none",
            },
            "& *":{
                backgroundColor:"transparent"
            }
        
        },
        "& .MuiSlider-thumb":{
            display:"block",
            "&:before":{
              display:"|"
            }
        }
    }
})
// Customizable Area End

const CustomSlider = (props: any) => {
  // Customizable Area Start  
  const classes = useStyles();
  const batteryValue = props.batteryValue
  const fleetStatus = props && props.fleetStatus;
  const [open,setOpen] = useState(false);
  console.log(batteryValue);
  const handleInfoOpen = () => setOpen(true);
  const handleInfoClose = () => setOpen(false);
  
  const [value,setValue] = useState<number>(props.batteryValue);
  useEffect(()=>{
    setValue(batteryValue);
    if(batteryValue>=46){
      setBatteryPowerDiv(true);
    }
    else{
      setBatteryPowerDiv(false);
    }
  },[batteryValue])

  const checkTime = () => {
    const currentTime = new Date().toLocaleString("en-US", { timeZone: "America/Chicago" }); 
    const currentHour = new Date(currentTime).getHours();
    const currentMinute = new Date(currentTime).getMinutes();
  
    if (currentHour === 14 && currentMinute === 0) {
      setValue(0);
    }
  };

  console.log("Value", typeof value, "type of", typeof batteryValue)
  const [showbatteryInfoMsg, setBatteryPowerDiv] = useState(false)
  const handleSlider = (event:React.ChangeEvent<{}>, newValue:number|number[])=>{
        if (typeof newValue==='number' && newValue>=46){
      setValue(newValue);
      setBatteryPowerDiv(true);
    }
    else if (typeof newValue==='number'){
      setValue(newValue);
      setBatteryPowerDiv(false);
    }
  }
  const handlePowerChange = (event:React.ChangeEvent<{}>, newValue:number|number[])=>{
    if(typeof newValue==='number' && newValue>=46){
      setValue(newValue);
      props.setBatteryPercentage(newValue);
      setBatteryPowerDiv(true);
    }
    else if(typeof newValue==='number')
    {
      setValue(newValue)
      props.setBatteryPercentage(newValue);
      setBatteryPowerDiv(false);
    }
   }

   useEffect(() => {
    const timerID = setInterval(() => {
      checkTime();
    }, 1000);
  
    return () => clearInterval(timerID);
  }, []);

  // Customizable Area End
  return (
    // Customizable Area Start
       <Box> 
        <Box className={ showbatteryInfoMsg? "batteryDivMessage" : "batteryDiv" }>
           <Grid container>
            <img src={infoIcon} alt="Information" className="infoIconImg" onClick={handleInfoOpen} ></img>
            <Modal open={open} onClose={handleInfoClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box className="infoCSBoxModal">
                <img src={closeModal} id="modal-modal-title" alt="Information" className="closeCSModalIcon" onClick={handleInfoClose} ></img>
                <img src={infoModal} id="modal-modal-title" alt="Information" className="infoCSModalIcon" ></img>
                <Typography id="modal-modal-description" className="modalInfo">
                  Select "0%" to decline charge. Be sure to indicate the percentage added. Unlike many apps(Tesla), we look for the amount of power added to your car, and not the limit of charge that your battery is set to. A typical charged
                  needed after a couple of days hiatus, is often around 25-45%.
                </Typography>
              </Box>

            </Modal>
           </Grid>
           
           <Grid container className="batteryTitleDiv">
                <Grid item xs={2} className="batteryIconImgDiv">
                    <img src={batteryIcon} alt="Power Battery" className="batteryIconImg"></img>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" display="block" className="batteryIconLabel">Percent Battery to Add</Typography>
                </Grid>
           </Grid>
           <Grid container className="batterySliderGrid">
                <>{console.log(batteryValue,"Batteries",value)}</>
                <Slider data-test-id="battery-slider" className={classes.batterySlider} min={0} max={90} value={value} aria-label="Small" valueLabelDisplay="on" onChange={handleSlider} onChangeCommitted={handlePowerChange} ></Slider>
           </Grid>
           <Grid className="batteryMeterImgDiv">
                <img src={batteryMeter} alt="Battery Meter" className="batteryMeterImg"></img>
              <Grid className="batteryMeterLabelValues">
                <p className="batteryMeterLabel">0%</p>
                <p className="batteryMeterLabel1">10</p>
                <p className="batteryMeterLabel1">20</p>
                <p className="batteryMeterLabel1">30</p>
                <p className="batteryMeterLabel1">40</p>
                <p className="batteryMeterLabel1">50</p>
                <p className="batteryMeterLabel1">60</p>
                <p className="batteryMeterLabel1">70</p>
                <p className="batteryMeterLabel1">80</p>
                <p className="batteryMeterLabel1">90</p>
              </Grid>
              { showbatteryInfoMsg && <Grid>
            <Typography variant="subtitle2" className="infoMessage">Use this level if you have 46% or more space in your battery for the amount you intend to add. </Typography>
           </Grid>}
           </Grid>
        </Box>
        <Box>
          <CustomBarGraph showbatteryInfoMsg={showbatteryInfoMsg} fleetStatus={fleetStatus}></CustomBarGraph>
        </Box>
      </Box>        
        
    // Customizable Area End
  );
};

export default CustomSlider;
