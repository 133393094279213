export const share = require("../assets/share.png");
export const blue = require("../assets/bluecircle.png");
export const orange = require("../assets/orangecircle.png");
export const bgcolor = require("../assets/bgshare.png");
export const bar = require("../assets/bar.png");
export const icons = require("../assets/icons.png");
export const toggle = require("../assets/toggle.png");
export const togglegrey = require("../assets/togglegrey.png");
export const togglegreen = require("../assets/togglegreen.png");
export const capitalA = require("../assets/A.png")
export const battery = require("../assets/battery.png")
export const calender = require("../assets/calender.png")
export const camel = require("../assets/camel.png")
export const cap = require("../assets/cap.png")
export const coin = require("../assets/coin.png")
export const flower = require("../assets/flower.png")
export const hand = require("../assets/hand.png")
export const petal = require("../assets/petal.png")
export const plane = require("../assets/plane.png")
export const plug = require("../assets/plug.png")
export const prawn = require("../assets/prawn.png")
export const speaker = require("../assets/speaker.png")
export const tree = require("../assets/tree.png")
export const line = require("../assets/line.png")
export const capitalAc = require("../assets/Ac.png")
export const batteryc = require("../assets/batteryc.png")
export const calenderc = require("../assets/calenderc.png")
export const camelc = require("../assets/camelc.png")
export const capc = require("../assets/capc.png")
export const coinc = require("../assets/medalc.png")
export const flowerc = require("../assets/flowerc.png")
export const handc = require("../assets/handc.png")
export const plantc = require("../assets/plantc.png")
export const planec = require("../assets/Planec.png")
export const plugc = require("../assets/plugc.png")
export const prawnc = require("../assets/prawnc.png")
export const speakerc = require("../assets/spakerc.png")
export const treec = require("../assets/treec.png")
export const cross = require("../assets/cross.png")
export const windeverestIcon = require("../assets/windeverest_icon.png");
export const instaIcon = require("../assets/instagram_icon.png");