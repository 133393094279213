// Code Exception Area Start
import React, { useState } from "react";
// Code Exception Area End
// Customizable Area Start
import { Container, Box,Typography,Grid,Button,Paper} from "@material-ui/core";
import { poster } from "./assets";
import "../css/LandingPage3.css";
export const configJSON = require("./config");
const classes: any = {
    root: {
        borderRadius: 30,
      },
      container: {
        width: "100%",
      },
      heading: {
        color: "rgb(51, 102, 153)",
        fontWeight: "bolder",
        marginTop: "15%",
        marginLeft: "35%",
        marginBottom: "4%",
        fontSize: "2.45rem",
        paddingTop: "5%",
      },
      bodyContainers: {
        display: "flex",
        justifyContent: "start",
        gap: 5,
        marginTop: "40px",
      },
      containerLeft: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        heigth: "80%",
        width: "80%",
        borderRadius: "15px",
        paddingBottom: "20px",
      },
      containerRight: {
        flex: 0.8,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: "85%",
        width:"85%",
        borderRadius: "20px"
      },
      textFieldContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 12,
        width: "100%",
      },
      button: {
        backgroundColor: "#006633",
        color: "white",
        fontWeight: "bold",
        borderRadius: 25,
        marginTop: 30,
        width: "100%",
        textTransform: "none"
      },
      posterImg: {
        width: "78%",
        height: "55%",
      },
      title: {
        marginTop: 10,
        marginBottom: 30,
        padding:"0px 15px"
      },
      input: {
        border: "none",
        width: "100%",
        outline: "none",
        borderRadius: "20px",
      },
      paper: {
        borderRadius: "20px",
        outline: "none",
        border: "none"
      },
      textarea: {
        border: "none",
        width: "100%",
        fontFamily:"inherit",
        fontSize:"12px",
        fontColor:"lightgrey",
        outline: "none",
        borderRadius: "20px",
        height: "160px",
        padding: "10px"
      },
     
  }
  
// Customizable Area End

const SplashscreenLanding3 = (props: any) => {
  // Customizable Area Start
  const [name,setName] = useState<string>("");
  const [email,setEmail] = useState<string>("");
  const [comments,setComments] = useState<string>("");
  const [nameError,setNameError] = useState<boolean>(false);
  const [emailError,setEmailError] = useState<boolean>(false);
  const [commentError,setCommentError] = useState<boolean>(false);
  
  const isStringNullOrBlank=(str : string)=>{
    return str === null || str.length === 0;
  }
  const handleNameChange=(name:string)=>{
    setName(name);
    setNameError(isStringNullOrBlank(name));
  }
  const handleEmailChange=(email:string)=>{
    setEmail(email);
    setEmailError(isStringNullOrBlank(email));
  }
  const handleCommentChange=(comment:string)=>{
    setComments(comment);
    if(comment.length<30 || comment.length>2000){
      setCommentError(true);
    }
    else{
      setCommentError(false);
    }
  
  }
  const sendContactMessage=()=>{
    props.sendContactMessage(name,email,comments);
    setComments("");
    setName("");
    setEmail("");
  }
  // Customizable Area End
  return (
    // Customizable Area Start
    <Container maxWidth="lg" className="landingPage3Container">
        <Box
          style={{
            width: "100%",
            marginLeft: "10%",
            marginTop: "12%",
            marginBottom: "10%",
          }}
        >
          <Typography className="contactUsTitle" variant="h5">
            Contact Us
          </Typography>
          <Grid container className="landingBodyContainers">
            <Grid item sm={12} md={6} className="gridsmLandingContact">
            <Paper className="containerLeft" elevation={3}>
              <Typography 
              className="getInTouch"
               variant="h4">
                Get in touch!
              </Typography>
              <Typography style={classes.title}>
                Our team would love to hear from you!
              </Typography>
              <img
                style={classes.posterImg}
                src={poster}
                alt="Poster Contact"
              />
            </Paper>
            </Grid>
            <Grid item sm={12} md={4} 
            className="containerRightLandingContact"
            >
              <Box style={classes.textFieldContainer}>
              <Typography variant="body2">Username</Typography>
                <Paper style={classes.paper} elevation={2}>
                  <input style={classes.input} 
                  name="input-test-name"
                  data-test-id="input-test-name"
                  placeholder="John Doe"
                  value={name}
                  onChange={(e) => {
                   handleNameChange(e.target.value);
                  }}
                  ></input>
                  
                </Paper>
                {(nameError || props.usernameError) && <p style={{color: 'red', fontSize: "12px"}}>Username is mandatory</p>}    
                <Typography variant="body2">Email</Typography>
                <Paper style={classes.paper} elevation={2}>
                  <input style={classes.input} 
                    name="input-test-email"
                    data-test-id="input-test-email"
                    placeholder="johndoe@gmail.com"
                  value={email}
                  onChange={(e)=>{
                    handleEmailChange(e.target.value);
                  }}
                  ></input>
                </Paper>
                {(emailError || props.emailError) && <p style={{color: 'red', fontSize: "12px"}}>Email is not valid, please enter a valid email.</p>}
                <Typography variant="body2">Message</Typography>
                <Paper style={classes.paper} elevation={2}>
                  <textarea className="commentMessageBox" 
                    name="input-test-comments"
                    data-test-id="input-test-comments"
                  value={comments}
                  onChange={(e)=>{
                    handleCommentChange(e.target.value);
                  }}
                  placeholder="Max 2000 characters"
                  />
                </Paper>
                { (commentError || props.messageError) && <Typography variant="body2" style={{color: "red"}}>Message minimum length is 30 and maximum length is 2000</Typography>}
                  
              </Box>
              <Button
                variant="contained"
                color="primary"
                data-test-id="submit-message-test"
                style={classes.button}
                onClick={sendContactMessage}
                disabled={commentError || props.messageError || emailError || nameError}
              >
                Send message
              </Button>
              { props.successMessageSent && (
            <Typography
              style={{
                color: "#006633",
              }}
              variant="h6"
            >
              Your message sent successfuly!
            </Typography>
          ) }
            </Grid>
          </Grid>
        </Box>
    </Container>
    // Customizable Area End
  );
};

export default SplashscreenLanding3;
