import React from "react";
// Customizable Area Start
import { Box, Grid, Typography, Paper } from "@material-ui/core";
import "../css/CustomBarGraph.css";
import { redBarGraph, greenBarGraph, yellowBarGraph } from "./assets";
// Customizable Area End

const CustomBarGraph = (props: any) => {
  // Customizable Area Start
  const barGraphMsg = props && props.showbatteryInfoMsg;
  const fleetStatus = props && props.fleetStatus;
  let yellowMargin,
    redMargin = 0;
  redMargin = fleetStatus.charged - (7 % fleetStatus.decline);
  yellowMargin = redMargin + fleetStatus.decline - (7 % fleetStatus.undecided);
  // Customizable Area End
  return (
    // Customizable Area Start
    <Paper
      elevation={3}
      className={barGraphMsg ? "barGraphPaperMessage" : "barGraphPaper"}
    >
      <Box className={barGraphMsg ? "barGraphDivMessage" : "barGraphDiv"}>
        <Grid>
          <Typography
            variant="body1"
            className={
              barGraphMsg ? "fleetStatusHeadingMessage" : "fleetStatusHeading"
            }
          >
            Fleet Status
          </Typography>
        </Grid>
        <Grid className="fleetStatusGraphDiv">
          <div
            className="fleetStatusGreenDiv"
            style={{ width: `${fleetStatus.charged}%` }}
          />
          <div
            className="fleetStatusYellowDiv"
            style={{
              width: `${fleetStatus.undecided}%`,
              marginLeft: `${yellowMargin}%`
            }}
          />
          <div
            className="fleetStatusRedDiv"
            style={{
              width: `${fleetStatus.decline}%`,
              marginLeft: `${redMargin}%`
            }}
          />
        </Grid>
        <Grid className="iconChartDiv">
          <Grid container className="iconChartSubDiv">
            <Grid item md={2} sm={2}>
              <img src={greenBarGraph} className="barGraphIcons" />
            </Grid>
            <Grid item md={2} sm={2}>
              <Typography variant="body1" className="iconChartIconLabel">
                Charged
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="iconChartSubDiv">
            <Grid item md={2} sm={2}>
              <img src={redBarGraph} className="barGraphIcons" />
            </Grid>
            <Grid item md={2} sm={2}>
              <Typography variant="body1" className="iconChartIconLabel">
                Declined
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="iconChartSubDiv">
            <Grid item md={2} sm={2}>
              <img src={yellowBarGraph} className="barGraphIcons" />
            </Grid>
            <Grid item md={2} sm={2}>
              <Typography variant="body1" className="iconChartIconLabel">
                Undecided
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>

    // Customizable Area End
  );
};

export default CustomBarGraph;
