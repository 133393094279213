import React, { SetStateAction, useEffect, useState } from "react";
// import CssBaseline from "@material-ui/core/CssBaseline";
// Customizable Area Start
import Box from "@material-ui/core/Box";

import Paper from "@material-ui/core/Paper";

import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles
} from "@material-ui/core/styles";

import {
  Container,
} from "@material-ui/core";
import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";
import { carImg } from "./assets";
import "../css/AboutUs.css"
const classes: any = {
  root: {
    paddingRight: "60px",
    marginLeft:"-15px"
  },
  paperContainer: {
    width: "90%",
    height: "100%",
    marginTop:"40px",
    padding: 20,
    displayBlock:"flex",
    borderRadius:"10px"
  },
  heading: {
    fontWeight: "bold",
    color: "#336699",
    marginBottom: 20,

  },
  imgContainer: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    objectFit: "cover",
    display: "block",
    
  },
  videoContainer:{
    width: "100%",
    height: "100%",
    borderRadius: 10,
    display: "block",
    
  },
  container: {
    display: "flex",
    justifyContent: "left",
  },
  
  leftContainer: {
    flex: 0.5,
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  rightContainer: {
    flex: 0.5,
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  description: {
    textAlign: "justify",
    color: "black"
  },
}
// Customizable Area End


export default class PreviewVideo extends NavigationMenuController {
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getPreviewVideo();
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="xl" style={classes.root}>

      <Box style={classes.container}>
        <Paper style={classes.paperContainer}  elevation={3}>
          <Typography style={classes.heading} variant="h6">
            About the application
          </Typography>
         <Box className="aboutUsBodyContainer">
          <Box style={classes.leftContainer}>
              <img
                style={classes.imgContainer}
                className="imgContainer"
                alt=""
                src={carImg}
              />
           
              <Typography style={classes.description} variant="body2">
              Windeverest accelerates decarbonization by previewing a week for wind energy content. You get a list of nights that show lowest CO2 from the grid, and you pick the  nights you want to charge. Improve your carbon-free content. Compete with your friends. Be the change you want to see in the world. Breezy just bocame easy. Don’t settle for wind content that is average for the grid — roughly 20%. Time your charge when the grid has 40%, 50%, EVEN 80% of its electricity sourced from wind and other low carbon source.<br/><br/>

We all have an interest in moderating our climate’s changes. Whether you  you are part-time carbon efficient, or like dive deep into the sources of 
              </Typography>
            </Box>
            <Box style={classes.rightContainer}>
              <Typography style={classes.description} variant="body2">
                emissions on the grid — you can use Windeverest in  any way to support your goals. Do as much, or as little as you want. Show your progress to your friends. Inspire them to action. Windeverest will help out no matter were you are in your decarbonization journey.<br/><br/>
                At Windeverest, technology and integrity are our cornestones. Our team holds numerous patents, and we abide by the mantra, “invention drives our climate”. We strive to have the highest levels if accuracy in wind megawatt projections and in CO2 historical emissions. Windeverest applies machine learning to anticipate grid disturbance and blow-out nights, to get you into you low-carbon groove.
              </Typography>
             
              {/* <iframe style={classes.imgContainer} width="560" height="315" src={this.state.preview?.data?.attributes?.video_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
              <iframe style={classes.videoContainer} src={this.state.preview?.data?.attributes?.video_url} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </Box>
          </Box>
        </Paper>

      </Box>
      </Container>
      // Customizable Area End
    );
  }
}
