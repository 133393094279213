Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "PUT";
exports.exampleAPiEndPoint = "https://windeverest-225652-ruby.b225652.stage.eastus.az.svc.builder.ai/bx_block_ad_manager/ad_managers";
exports.exampleAPiMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdManager";
exports.labelBodyText = "AdManager Body";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End