import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

import googleController, { GoogleWebDelegate } from "./GoogleWebController";
// Core exception code Start
import firebase from "firebase";
import { fireBaseApp } from "./GoogleFirebase.web";
// Core exception code End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;
  isRegistration: boolean;
  // Core exception code Start
  fcmToken:string;
  // Core exception code End
}

interface SS {}

export default class SocialMediaAccountWebController
  extends BlockComponent<Props, S, SS>
  implements GoogleWebDelegate {
  createAccountAPICallId: any;
  googleUser: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
    ]);

    this.state = {
      loading: false,
      isRegistration: false
      // Core exception code Start
      ,fcmToken:""
      // Core exception code End
    };
  }

  //When facebook sends back the reponse this gets called
  // Core exception code Start
  googleUserStatusChanged(userInfo: any): void {
    if (this.state.isRegistration) {
      this.createAccountFromSocial(userInfo.idToken);
    } else {
      this.logInWithSocial(
        userInfo.idToken
      );
    }
  }
  // Core exception code End
  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  responseFacebook = (response: any) => {
    this.stopLoading();
    if (this.state.isRegistration) {
      this.createAccountFromSocial(response.email);
    }
    runEngine.debugLog(response);
  };

  googleLogIn = (isRegistration: boolean) => {
    const self = this;
    //@ts-ignore
    googleController.googleLogIn(this).then(
      function() {
        console.log("This runs")
        self.stopLoading();
        runEngine.debugLog("User SIGNED IN.");
      },
      function(error: any) {
        console.log("Begin",error)
        self.stopLoading();
        if (error.error === "popup_closed_by_user") {
          //handle window closed event
          console.log("End",error);
        }
      }
    );
  };
  // Core exception code Start
  getFcmToken = async () => 
  {
    const messaging = fireBaseApp.messaging();
    messaging.requestPermission().then(()=>{
      return messaging.getToken();
    }).then(token=>{
      this.setState({fcmToken:token});
    }).catch(errToken=>{
      runEngine.debugLog("Error",errToken);
    })
  }
 doGoogleLogin = (isRegistration:boolean)=>{
  this.getFcmToken();
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  const auth = firebase.auth(fireBaseApp);
  auth.signInWithPopup(provider).then((result:any)=>{
    const credential = result.credential;
    const user = result.user;
    const idToken = credential.idToken;
    this.googleUser = {
      user:user,
      idToken:idToken
    }
    this.googleUserStatusChanged(this.googleUser);
  }).catch((error)=>{
    const errorCode = error.code;
    const errorMessage = error.message;
    runEngine.debugLog("Error while logging in --->", errorCode);
    runEngine.debugLog("Error Message --->", errorMessage);
  })

 }
 // Core exception code End
  async receive(from: string, message: Message) {
    this.stopLoading();

    if (getName(MessageEnum.SessionSaveMessage) === message.id) {
      this.openInfoPage();
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createAccountAPICallId != null &&
      this.createAccountAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        this.saveLoggedInUserData(responseJson);
        localStorage.setItem("windToken", responseJson.meta.token);
              localStorage.setItem("windUser", JSON.stringify(responseJson));
              this.props.navigation.navigate("WebNavigationMenu",{replace:true});
              history.pushState(null,"",location.href);
              window.onpopstate=()=>{
                history.go(1)
              }
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else {
      runEngine.debugLog("GOIT");
    }
  }

  async createAccountFromSocial(
    incomingToken: string
  ) {
    if (
      !incomingToken ||
      incomingToken.length === 0
      ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.googleLoginAccountURL}?token=${incomingToken}`
    );

    const data = {
      "device_token": this.state.fcmToken
    };

    const httpBody = {
      "device_token": this.state.fcmToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async logInWithSocial(
    incomingToken:string
  ) {
    if (
      !incomingToken ||
      incomingToken.length === 0
      ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": "application/json"
    };

    const data = {
      "device_token": this.state.fcmToken
    };

    const httpBody = {
      "device_token": this.state.fcmToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;

    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.googleLoginAccountURL}?token=${incomingToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigate() {
    runEngine.debugLog("this.isRegistration");
    runEngine.debugLog(this.state.isRegistration);
    if (this.state.isRegistration) {
      runEngine.debugLog("Registration");
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToSignup();
    } else {
      runEngine.debugLog("Registration");
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToLogin();
    }
  }

  navigateToSignup() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg = new Message(
      getName(
        this.state.isRegistration
          ? MessageEnum.AccoutResgistrationSuccess
          : MessageEnum.AccoutLoginSuccess
      )
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  //Create response callback.
  responseInfoCallback(error: any, result: any) {
    if (error) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorDescription + error.toString()
      );
    } else {
      runEngine.debugLog("Result Name: " + result.name);
    }
  }

  btnFacebookLogInProps = {
    onPress: () => {
      this.startLoading();
    },
    callback: this.responseFacebook
  };

  btnGoogleLogInProps = {
    onPress: () => {
      console.log("Starting login for google")
      // Core exception code Start
      this.doGoogleLogin(this.state.isRegistration);
      // Core exception code End
      this.startLoading();
      console.log("Exiting google login")
    }
  };

  btnNavigateProps = {
    onPress: () => this.navigate()
  };
}
