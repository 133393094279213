import React from "react";
// Customizable Area Start
import { Container, Box,Typography } from "@material-ui/core";
import { carImg } from "./assets";
import "../css/LandingPage2.css";
const classes: any = {
    imgContainer: {
      width: "100%",
      height: "340px",
      borderRadius: 10,
      objectFit: "cover",
      display: "block",
      
    },
    videoContainer:{
      width: "100%",
      height: "340px",
      borderRadius: 10,
      display: "block",
      
    },
    container: {
      display: "flex",
      justifyContent: "left",
    },
    
    leftContainer: {
      flex: 0.5,
      display: "flex",
      flexDirection: "column",
      gap: 12,
      marginLeft:"9%",
    },
    rightContainer: {
      flex: 0.5,
      display: "flex",
      flexDirection: "column",
      gap: 12,
      marginRight:"8%"
    },
    description: {
      textAlign: "justify",
      color: "black",
      width:"101%",
      fontSize:"1.15rem"
    
    },
  }
  
// Customizable Area End

const SplashscreenLanding2 = (props: any) => {
  // Customizable Area Start
  // Customizable Area End
  return (
    // Customizable Area Start
    <Container maxWidth="lg" className="landingPage2Container">
        <Box>
            <Typography variant="h6" className="aboutUsTitle">About Us</Typography>
            <Box className="aboutUsBodyContainer">
          <Box className="leftContainer">
              <img
                style={classes.imgContainer}
                className="imgContainer"
                alt="Car Image"
                src={carImg}
              />
           
              <Typography className="leftDescription" variant="body2">
              Windeverest accelerates decarbonization by previewing a week for wind energy content. You get a list of nights that show lowest CO2 from the grid, and you pick the  nights you want to charge. Improve your carbon-free content. Compete with your friends. Be the change you want to see in the world. Breezy just bocame easy. Don’t settle for wind content that is average for the grid — roughly 20%. Time your charge when the grid has 40%, 50%, EVEN 80% of its electricity sourced from wind and other low carbon source.<br/><br/>

We all have an interest in moderating our climate’s changes. Whether you  you are part-time carbon efficient, or like dive deep into the sources of 
              </Typography>
            </Box>
            <Box style={classes.rightContainer}>
              <Typography className="rightDescription" variant="body2">
                emissions on the grid — you can use Windeverest in  any way to support your goals. Do as much, or as little as you want. Show your progress to your friends. Inspire them to action. Windeverest will help out no matter were you are in your decarbonization journey.<br/><br/>
                At Windeverest, technology and integrity are our cornestones. Our team holds numerous patents, and we abide by the mantra, “invention drives our climate”. We strive to have the highest levels if accuracy in wind megawatt projections and in CO2 historical emissions. Windeverest applies machine learning to anticipate grid disturbance and blow-out nights, to get you into you low-carbon groove.
              </Typography>
             
              {/* <iframe style={classes.imgContainer} width="560" height="315" src={this.state.preview?.data?.attributes?.video_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
              <iframe className="videoContainer" src={props.videoLink} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </Box>
          </Box>
        </Box>
    </Container>
    // Customizable Area End
  );
};

export default SplashscreenLanding2;
