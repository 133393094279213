import ENV from "./Environment";

const CONSTANTS = {
  URL: {
    LOGIN: `${ENV.API_HOST}login/login/`,
    CONTACT_US: `${ENV.API_HOST}bx_block_contact_us/contacts`,
    SIGN_UP: `${ENV.API_HOST}account/accounts`,
    ABOUT_US: `${ENV.API_HOST}bx_block_contact_us/preview_videos`,
    FAQS: `${ENV.API_HOST}bx_block_help_centre/question_answer`,
    RESET_PASSWORD: `${ENV.API_HOST}bx_block_forgot_password/passwords`,
    OTP: `${ENV.API_HOST}bx_block_forgot_password/otps`,
    OTP_CONFORMATION: `${
      ENV.API_HOST
      }bx_block_forgot_password/otp_confirmations`,
    PRIVACY_POLICY: `${ENV.API_HOST}bx_block_contact_us/privacy_policies`,
    EMAIL_VALIDATION: `${ENV.API_HOST}account_block/accounts/check_email_validation?email`,
    USERNAME_VALIDATION: `${ENV.API_HOST}account_block/accounts/check_user_name_validation?user_name`,
    STATE_DETAILS: `${ENV.API_HOST}bx_block_states_cities/state_cities/states?country_code`,
    CITY_DETAILS: `${ENV.API_HOST}bx_block_states_cities/state_cities/cities?alpha_code`,
    ZIPCODE_DETAILS: `${ENV.API_HOST}bx_block_states_cities/state_cities/zipcode_validation?zipcode`,
    CAR_DETAILS: `${ENV.API_HOST}bx_block_categories/cars/dropdown_details`,
    QUESTION_DETAILS: `${ENV.API_HOST}bx_block_categories/questions/?question_type=Profile`,
    LOGINSHOW_DETAILS: `${ENV.API_HOST}account_block/accounts/`,
    PROFILE_UPDATE: `${ENV.API_HOST}account_block/accounts/`,
    DASHBOARDS_WEEKLYDATA: `${ENV.API_HOST}bx_block_dashboard/dashboards/dashboards`,
    FLEETSTATUS: `${ENV.API_HOST}bx_block_dashboard/dashboards/fleet_status?`,
    PERCENTAGEBATTERY: `${ENV.API_HOST}account_block/accounts/38/add_battery_percentage?`,
    LATECHARGINGSTATUS: `${ENV.API_HOST}account_block/battery_percentages/late_charging_status`,
    GET_POSTS: `${ENV.API_HOST}bx_block_posts/posts`,
    ADD_POSTS: `${ENV.API_HOST}bx_block_posts/posts`,
    TERMSANDCONDITIONS:`${ENV.API_HOST}account_block/accounts/accept_term_and_condition`,
    GET_GROUPS: `${ENV.API_HOST}bx_block_account_groups/groups?search=`,
    ADD_COMMENT: `${ENV.API_HOST}bx_block_posts/posts/`,    
    PRIVACYPOLICY:`${ENV.API_HOST}bx_block_contact_us/privacy_policies/term_and_condition_accepted_or_not`,
    PRIVACYPOLICYACCEPT:`${ENV.API_HOST}account_block/accounts/accept_privacy_policy`,
    PRIVACYPOLICYACCEPTORNOT:`${ENV.API_HOST}bx_block_contact_us/privacy_policies/privacy_policy_accepted_or_not`,
    SINUPTERMS:`${ENV.API_HOST}bx_block_contact_us/privacy_policies/terms_and_condition_list`,
    GAMIFICATION:`${ENV.API_HOST}bx_block_gamification/user_badges`,
    GETREMINDER:`${ENV.API_HOST}bx_block_notifsettings/notification_reminder_settings`,
    GETEASYMODE:`${ENV.API_HOST}bx_block_notifsettings/easy_modes`,
    GETVACATIONMODE:`${ENV.API_HOST}bx_block_notifsettings/vacation_mode_settings`,
    CREATEREMINDER:`${ENV.API_HOST}bx_block_notifsettings/notification_reminder_settings`,
    CREATEVACATION:`${ENV.API_HOST}bx_block_notifsettings/vacation_mode_settings`,
    CREATEEASYMODE:`${ENV.API_HOST}bx_block_notifsettings/easy_modes`,
    DELETE_COMMENT: `${ENV.API_HOST}bx_block_posts/posts/`,
    GET_NOTIFICATIONS: `${ENV.API_HOST}bx_block_notifications/notifications`,
  },
};
export default CONSTANTS;
