export const onBoardingOne = require("../assets/Onboarding_One.png");
export const onBoardingTwo = require("../assets/Onboarding_Two.png");
export const onboardingThree = require("../assets/Onboarding_Three.png");
export const onboardingFour = require("../assets/Onboarding_Four.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
//Onboarding One
export const circleBottomLeft = require("../assets/onboardingOne/bottom_left_icon.png");
export const onboardingOneIcon = require("../assets/onboardingOne/onboarding_one_icon.png");
export const popupIcon = require("../assets/onboardingOne/popup_icon.png");
export const redArrowIcon = require("../assets/onboardingOne/red_arrow_icon.png");
export const tableIcon = require("../assets/onboardingOne/table_icon.png");
export const circleTopLeft = require("../assets/onboardingOne/top_left_icon.png");
export const circleTopRight = require("../assets/onboardingOne/top_right_icon.png");
export const weeklyIcon = require("../assets/onboardingOne/weekly_icon.png");
export const windEverest = require("../assets/onboardingOne/windeverest_icon.png");

//Onboarding Two
export const batteryMeter = require("../assets/onboardingTwo/icon_battery_meter.png");
export const batteryPanel = require("../assets/onboardingTwo/icon_battery_panel.png");
export const batteryIcon = require("../assets/onboardingTwo/icon_battery.png");
export const bottomLeftCircle = require("../assets/onboardingTwo/icon_co_centric_circle.png");
export const greenTopRightCircle = require("../assets/onboardingTwo/icon_green_circle.png");
export const infoIcon = require("../assets/onboardingTwo/icon_info.png");
export const onboardingTwoIcon = require("../assets/onboardingTwo/icon_onBoardingTwo.png");

//Onboarding Three
export const weeksIcon = require("../assets/onboardingThree/13weeks_icon.png");
export const benchmarkIcon = require("../assets/onboardingThree/benchmark_icon.png");
export const circleHalfRight = require("../assets/onboardingThree/circle_half_right_icon.png");
export const circleIcon = require("../assets/onboardingThree/circle_icon.png");
export const daysIcon = require("../assets/onboardingThree/days_icon.png");
export const gpaIcon = require("../assets/onboardingThree/gpa_icon.png");
export const graphIcon = require("../assets/onboardingThree/graph_icon.png");
export const shareIcon = require("../assets/onboardingThree/icon_share.png");
export const onboardingThreeIcon = require("../assets/onboardingThree/onboardingThree_icon.png");
export const popupIconBoard = require("../assets/onboardingThree/popup_va_icon.png");
export const shareButtonIcon = require("../assets/onboardingThree/share_button.png");

//Onboarding Four
export const badgesIcon = require("../assets/onboardingFour/badges_icon.png");
export const barIcon = require("../assets/onboardingFour/bar_icon.png");
export const greenCircleIcon = require("../assets/onboardingFour/green_circle_icon.png");
export const onboardingFourIcon = require("../assets/onboardingFour/onboardingFour_icon.png");
export const popupBadgeIcon = require("../assets/onboardingFour/popup_badge_icon.png");
export const topRightCircle = require("../assets/onboardingFour/top_right_cocentric_circle_icon.png");

//Onboarding Five
export const topRightHalfIcon = require("../assets/onboardingFive/top_right_half_icon.png");
export const lateChargeBarIcon = require("../assets/onboardingFive/late_charge_bar.png");
export const lateChargeIcon = require("../assets/onboardingFive/late_charge_icon.png");
export const onboardingFiveIcon = require("../assets/onboardingFive/onboardingFive_icon.png");
