import React, { useState } from "react";
// Customizable Area Start
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import '../css/coustomselect.css'
import { iIcon } from "./assets";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 30,
    },
    inputContainer: {
      border: "1px solid black",
      borderRadius: 50,
      height: 30,
      justifyContent: "center",
      alignItems: "center",
      width: 300,
    },
    errorIcon: {
      color: "red",
      width: "20px"
    },
    errorMsg: {
      position: "relative",
      top: -5,
      zIndex: 100,
      backgroundColor: "yello",
      padding: 2,
    },
    tooltip: {
      backgroundColor: "white",
      color: "black",
      fontWeight: "bold",
    },
    heading: {
      marginBottom: "5px",
      color: "black"
    }
  })
);

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "red",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  tooltipPlacementBottom: {
    marginTop: "-10px ",
  },
}))(Tooltip);

// Customizable Area End

const CoustomTextInput = (props: any) => {
  // Customizable Area Start
  const classes = useStyles();
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordClick = () => {
    setShowPassword((code) => !code);
  }

  const eye = {
    marginRight: "10px",
    color: "grey",
    cursor: "pointer",
    transform: "rotate(0deg)"
  }

  const StyledTooltip = withStyles({
    
  })(Tooltip);
  const endAdornmentMethod=()=>{
      if(props.password){
        if(showPassword){
          return (<Visibility data-testid="visibleIcon" style={eye} onClick={() => {setShowPassword(!showPassword)}}/>);
        }
        else{
          return (<VisibilityOff data-testid="visibleOffIcon" style={eye} onClick={() => {setShowPassword(!showPassword)}}/>);
        }
      }
     if(props?.showIcon ){
      let iconToShow;
      if(!props.password){
        iconToShow = <img src={iIcon} alt="" className={classes.errorIcon} />;
      }
      // else if(showPassword){
      //  iconToShow = <Visibility /> ;
      // }
      // else{
      // iconToShow = <VisibilityOff />;
      // }
      return (
        <InputAdornment data-test-id="InputAdornmentId" position="end">
          <LightTooltip title={props?.errorMssage} placement="bottom-end" >
              <IconButton data-test-id="IconBtnId"onClick={handlePasswordClick}>
              { iconToShow }
            </IconButton>
          </LightTooltip>
        </InputAdornment>
      )
     }
    return null;
  }
  // Customizable Area End
  return (
    // Customizable Area Start
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        cursor: "pointer"
      }}
    >
      <InputLabel className={classes.heading} style={{marginTop:"10px", marginLeft:"20px"}}>{props.label}</InputLabel>
      
      <OutlinedInput
        type={ !props.password ? props?.number ? 'number': 'text' : showPassword ? 'text' : 'password'}
        id="outlined-adornment-weight"
        endAdornment={ endAdornmentMethod() }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "weight",
          sx: {height: "5px"},
          maxlength: 30
        }}
        labelWidth={0}
        style={{ borderRadius: 20, padding: "0px", marginTop: 0, marginBottom: 0 , color: "grey"}}
        value={props.value}
        onChange={(e) => {
          props.updateText(e.target.value)        
          props?.validater(setShowErrorMsg, e.target.value);
        }}
        data-test-id="customtextInputTest"
        autoComplete={ props.password ? "new-password" : "off" }
        disabled={props.disabled ? true : false}
      />
      { props?.showError && <p data-test-id="alertMessagePara" style={{fontSize: "12px", color: "red"}}>{props?.alertMsg}</p>}
    </div>
    // Customizable Area End
  );
};

export default CoustomTextInput;
