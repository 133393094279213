import React from "react";
// Customizable Area Start
import {	
  Modal,	
  Container,	
  Box,	
  Button,	
  Input,	
  TextField,	
  Typography,	
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import {	Paper	} from "@material-ui/core";

import ContactusController, { Props, configJSON } from "./ContactusController";
import CoustomTextInput from "../../email-account-registration/src/CoustomTextInput.web";
import { poster } from "./assets";
import "./contactUs.css";

const MyTeme = createTheme({
  palette: {
    primary: {
      contrastText: "#000000",
      main: "#006633",
    },
  },
});
// Customizable Area End
const theme = createTheme({	
  palette: {	
    primary: {	
      main: "#0000ff",	
      contrastText: "#fff",	
    },	
  },	
});
// Customizable Area Start
const classes: any = {
  root: {
    borderRadius: 30,
  },
  container: {
    width: "100%",
  },
  heading: {
    color: "#336699",
    fontWeight: "bold",
    marginTop: "40px",
    marginLeft:"20px",
    marginBottom:"70px"
  },
  bodyContainers: {
    display: "flex",
    justifyContent: "start",
    gap: 5,
    marginTop: "40px",
  },
  containerLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    heigth: "80%",
    width: "80%",
    borderRadius: "15px",
    paddingBottom: "20px",
  },
  containerRight: {
    flex: 0.8,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "85%",
    width:"85%",
    borderRadius: "20px"
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    width: "100%",
  },
  button: {
    // backgroundColor: "#006633",
    color: "white",
    fontWeight: "bold",
    borderRadius: 25,
    marginTop: 30,
    width: "100%",
    textTransform: "none"
  },
  posterImg: {
    width: "78%",
    height: "55%",
  },
  title: {
    marginTop: 10,
    marginBottom: 30,
    padding:"0px 15px"
  },
  input: {
    border: "none",
    width: "100%",
    outline: "none",
    borderRadius: "20px",
  },
  paper: {
    borderRadius: "20px",
    outline: "none",
    border: "none"
  },
  textarea: {
    border: "none",
    width: "100%",
    fontFamily:"inherit",
    fontSize:"12px",
    fontColor:"lightgrey",
    outline: "none",
    borderRadius: "20px",
    height: "160px",
    padding: "10px"
  },
 
};
// Customizable Area End
export default class WebAddContactUs extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  updateName = (value: any) => {
    this.setState({
      name: value,
    });
  };

  updateEmail = (value: any) => {
    this.setState({
      email: value,
    });
  };

  updateComment = (value: any) => {
    this.setState({
      comments: value,
    });
  };
  messageValidator=(value:string)=>{
    const time = setTimeout(()=>{
      if(value.length<30 || value.length>2000){
        this.setState({
          messageError:true
        })
      }
      else{
        this.setState({
          messageError:false
        })
      }
      clearTimeout(time);
    },1000)
  }
  emailValidater = (value?: any) => {
    const pattren = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    this.setEmail(value);

    const time = setTimeout(() => {
      if(!pattren.test(value)){
        this.setState({
          emailError: true
        });
        this.setState({
          emailErrorMsg: "Email is not valid, please enter correct email pattren example@domain.com"
        });
      }
      else{
        this.setState({
          emailError: false,
          emailErrorMsg:""
        });
      }
      clearTimeout(time);
    }, 1000);
  }
// Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={MyTeme}>
        <Box
          style={{
            width: "100%",
            marginLeft:"-20px"
          }}
        >
          <Typography style={classes.heading} variant="h5">
            Contact Us
          </Typography>
          <Grid container className="bodyContainers">
            <Grid item sm={12} md={6} className="gridsm">
            <Paper className="containerLeft" elevation={3}>
              <Typography 
              // style={classes.heading}
              // style={classes.getInTouch}
              className="getInTouch"
               variant="h4">
                Get in touch!
              </Typography>
              <Typography style={classes.title}>
                Our team would love to hear from you!
              </Typography>
              <img
                style={classes.posterImg}
                src={poster}
                alt=""
              />
            </Paper>
            </Grid>
            <Grid item sm={12} md={4} 
            // style={classes.containerRight}
            className="containerRight"
            >
              <Box style={classes.textFieldContainer}>
              <Typography variant="body2">Username</Typography>
                <Paper style={classes.paper} elevation={2}>
                  <input style={classes.input} value={this.state.name}
                  onChange={(e) => {
                    this.setName(e.target.value);
                    this.setState({
                      usernameError: false
                    });
                  }}
                  ></input>
                </Paper>
                {this.state.usernameError && <p style={{color: 'red', fontSize: "12px"}}>Username is mandatory</p>}
                <Typography variant="body2">Email</Typography>
                <Paper style={classes.paper} elevation={2}>
                  <input style={classes.input} value={this.state.email}
                  onChange={(e) => {
                    this.emailValidater(e.target.value)
                    // this.setEmail(e.target.value);
                    // this.setState({
                      
                    //   emailError: false
                    // });
                  }}
                  ></input>
                </Paper>
                {this.state.emailError && <p style={{color: 'red', fontSize: "12px"}}>{this.state.emailErrorMsg}</p>}
                <Typography variant="body2">Message</Typography>
                <Paper style={classes.paper} elevation={2}>
                  <textarea style={classes.textarea} value={this.state.comments}
                  onChange={(e) => {
                    this.setComments(e.target.value);
                    this.messageValidator(e.target.value);
                  }}
                  placeholder="Max 2000 characters"
                  />
                </Paper>
                { this.state.messageError && <Typography variant="body2" style={{color: "red"}}>Message minimum length is 30 and maximum length is 2000</Typography>}
              </Box>
              <Button
                variant="contained"
                color="primary"
                style={classes.button}
                onClick={this.addQueryApi}
                disabled={this.state.emailError || this.state.email === ""||this.state.messageError||this.state.usernameError}
              >
                Send message
              </Button>
              {this.state.contactSuccessShow && (
            <Typography
              style={{
                color: "#006633",
              }}
              variant="h6"
            >
              Your message sent successfuly!
            </Typography>
          )}
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
