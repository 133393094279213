import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: "#ffffff",
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: "10px"
    },
    type: {
        color: "red",
        fontWeight: "bold"
    },
    message: {
        color: "black",
        fontWeight: "bold",
        marginBottom: "20px"
    },
    button: {
        textTransform: "none",
        backgroundColor: "#006633",
        color: "white"
    }
  }),
);

export default function TransitionsModal(props:any) {
  const classes = useStyles();

  const handleOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <button type="button" onClick={handleOpen}>
        react-transition-group
      </button>
      <Modal
        data-test-id="modalId"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <Typography className={classes.type} variant='h6'>{props.type}</Typography>
            <Typography className={classes.message} variant='body2'>{props.message}</Typography>
            <Button data-test-id="transitionModalCloseId" className={classes.button} onClick={() => {
                props.setOpen(false);
                props.navigation.navigate("EmailAccountLoginBlock")
            }}>Ok</Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
