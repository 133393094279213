Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions2";
exports.labelBodyText = "RolesPermissions2 Body";
exports.fleetStatusMethodType = "GET";
exports.rpGetFleetStatusUrl = "bx_block_dashboard/dashboards/fleet_status";
exports.rpGetLateChargeStatusUrl = "account_block/battery_percentages/late_charging_status";
exports.putLateChargeValueUrl = "account_block/accounts";
exports.fleetStatusValue = "Changed on basis of new";
exports.rpPostUrl = "bx_block_dashboard/dashboards/fleet_status";
exports.rpPostLateChargeStatusUrl = "account_block/battery_percentages/late_charging_status";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End