import React from "react";
import "../css/accountBlock.css";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Alert from '@material-ui/lab/Alert';


const configJSON = require("./config");

// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { accountBlockPoster, googleIcon, Logo } from "./assets";
import CoustomTextInput from "../../email-account-registration/src/CoustomTextInput.web";
import SimpleBottomNavigation from "../../dashboard/src/Footer/index.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#006633",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

export default class AccountBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  updateEmail = (value: string) => {
    this.setState({
      emailValue: value
    });
    console.log(this.state.emailValue);
  }

  emailValidater = (setShowError: any, value?: string) => {      
    let emailError = false;
    let emailErrorMsg = "";
  
    if (!value || value.trim() === "") {
      emailError = true;
      emailErrorMsg = configJSON.mandatoryEmail;
    }  else {
      emailError = false;
    }
  
    const timeoutId = setTimeout(() => {
      setShowError({
        emailError,
        emailErrorMsg
      });
      clearTimeout(timeoutId);
    }, 1000);
  };
  
 
  // Customizable Area End
  render() {
    return (
      // Required for all blocks
      // Customizable Area Start
      <ThemeProvider theme={theme}>

      <div className="container" style={{backgroundColor:"white"}}>
      <div className="header">
        <img data-test-id="imagelogoHome" src={Logo} alt="" className="logoImg" onClick={() => {this.props.navigation.navigate("Home")}}/>
      </div>
      {/* <Snackbar data-test-id="ErrorOtpSnackBar" open={this.state.emailError} autoHideDuration={6000} onClose={() => this.setState({ emailError: false })}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
            <Alert severity="error">{this.state.errorMessage}</Alert>
      </Snackbar> */}
      <div className="accountBlockBody">
        <img src={accountBlockPoster} alt="" />
        <h2 className="verificationText">Account Blocked</h2>
        <p className="alertMsg">
          Your account was blocked after too many
          failed login attempts. We sent you an email
          with a link to unlock it.
        </p>
        
        <hr />
          
        {(JSON.parse(localStorage.getItem("windUser")!)?.message ||  JSON.parse(localStorage.getItem("windUser")!) == null ) && <div style={{ width: "300px", marginBottom: "10px", marginTop: "10px" }}>
              <CoustomTextInput
                label={"Email*"}
                value={this.state.emailValue}
                updateText={this.updateEmail}
                validater={this.emailValidater}
                errorMssage={"email is not valid, please enter correct email pattren example@domain.com"}
                showError={this.state.emailError}
                alterMsg={this.state.emailErrorMsg}
             />
            </div>
        }
        
        <Button 
          data-test-id="forgotPasswordBtn"
        // className="butnforgetpassword"
         onClick={() => this.goToOtpAfterEmailValidation({ accountType: "", email: this.state.emailValue })}
          style={{textTransform: "none",fontWeight:"bolder", fontSize:"large",backgroundColor:"white" ,color: "green", borderRadius: "20px"}} variant="contained" color="primary"
          //  disabled={ this.state.emailError && this.state.emailValue === ""}
        
        ><div>Forgot Password <hr style={{width:"147px", padding:"0px",margin:"0px",marginTop:"-5px",color:"green"}}/></div></Button>
        
        </div>
    </div>
    <SimpleBottomNavigation/>

    </ThemeProvider>
    // Customizable Area End
    );
  }
}
