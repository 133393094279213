import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import "./AdManagerWeb.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AdManagerController, {
  Props,
  configJSON,
} from "./AdManagerController.web";
import { setTimeout } from "timers";

export default class AdManager extends AdManagerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  traverseAds=(idCount:string,link:string)=>{
    this.clickCount(idCount);
    setTimeout(()=>{window.location.href=link},1000)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"}>
          <Box sx={webStyle.mainWrapper}>
          <>
         { this.state.arrayHolder.length > 0 ? 
          
           <Grid container spacing={2} className="adManagerViewAd" data-test-id="adManager-click" onClick={() =>
              this.traverseAds(this.state.arrayHolder[this.state.currentImage]?.idCount,
              this.state.arrayHolder[this.state.currentImage]?.attributes?.link)
              }>
                
            
                <Grid item md={2} className="adManagerMessageView">
                <img src={this.state.arrayHolder[this.state.currentImage]?.attributes?.image} className="adManagerShow" />
                </Grid>
                <Grid item md={5} className="adManagerTextSize">
                <Typography className="adManagerMessage" variant="h6">{this.state.arrayHolder[this.state.currentImage]?.attributes?.message}</Typography>
                </Grid>
                <Grid item md={3}>
                <Button className="adManagerButton">
                  <Typography className="adManagerButtonText">Sign Up Now</Typography>
                </Button>
                </Grid>

            
          </Grid>
          
        :<Box></Box>}</>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
