// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import AdminConsole2 from '../../blocks/AdminConsole2/src/AdminConsole2';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Videos from '../../blocks/videos/src/Videos';
import Gamification from '../../blocks/Gamification/src/Gamification';
import HelpCentre from '../../blocks/helpcentre/src/HelpCentre';
import HelpCentreQA from '../../blocks/helpcentre/src/HelpCentreQA';
import HelpCentreSub from '../../blocks/helpcentre/src/HelpCentreSub';
import Customform from '../../blocks/customform/src/Customform';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Notifications from '../../blocks/notifications/src/Notifications';
import Analytics from '../../blocks/analytics/src/Analytics';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import UserGroups2 from '../../blocks/UserGroups2/src/UserGroups2';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Groups from '../../blocks/Groups/src/Groups';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Share from '../../blocks/share/src/Share';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Location from '../../blocks/location/src/Location';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import BroadcastMessage from '../../blocks/BroadcastMessage/src/BroadcastMessage';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import AdManager from '../../blocks/AdManager/src/AdManager';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import Search from '../../blocks/search/src/Search';
import CommunityForum from '../../blocks/CommunityForum/src/CommunityForum';
import CreateComment from '../../blocks/comments/src/CreateComment';
import InappPurchasing from '../../blocks/InappPurchasing/src/InappPurchasing';
import CreateNewPassword from '../../blocks/forgot-password/src/CreateNewPassword.web';
import WebForgetPassword from '../../blocks/forgot-password/src/WebForgetPassword.web';
import AccountBlock from '../../blocks/forgot-password/src/AccountBlock.web';
import CreateNewAccount from '../../blocks/email-account-registration/src/CreateNewAccount.web';
import VerifyOTP from '../../blocks/forgot-password/src/VerifyOTP.web';
import WebNavigationMenu from '../../blocks/navigationmenu/src/WebNavigationMenu.web';
import WebAddContactUs from '../../blocks/contactus/src/WebAddContactUs.web';
import WNavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu.web';
import WebEmailAccountLoginBlock from '../../blocks/email-account-login/src/WebEmailAccountLoginBlock.web'
import LoginScreen from '../../blocks/email-account-login/src/LoginScreen.web'
import VerificationSuccess from '../../blocks/email-account-registration/src/VerificationSuccess.web'
import SimpleBottomNavigation from '../../blocks/dashboard/src/Footer/index.web'
import Profile from '../../blocks/user-profile-basic/src/Profile.web'

const routeMap = {
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: '/AdminConsole2'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  Gamification: {
    component: Gamification,
    path: '/Gamification'
  },
  HelpCentre: {
    component: HelpCentre,
    path: '/HelpCentre'
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: '/HelpCentreQA'
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: '/HelpCentreSub'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  UserGroups2: {
    component: UserGroups2,
    path: '/UserGroups2'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  Groups: {
    component: Groups,
    path: '/Groups'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  BroadcastMessage: {
    component: BroadcastMessage,
    path: '/BroadcastMessage'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  AdManager: {
    component: AdManager,
    path: '/AdManager'
  },
  EmailAccountLoginBlock: {
    component: LoginScreen,
    path: '/EmailAccountLoginBlock'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  CommunityForum: {
    component: CommunityForum,
    path: '/CommunityForum'
  },
  Comment: {
    component: Comment,
    path: '/Comment'
  },
  CreateComment: {
    component: CreateComment,
    path: '/CreateComment'
  },
  InappPurchasing: {
    component: InappPurchasing,
    path: '/InappPurchasing'
  },

  Home: {
    component: Splashscreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
    exact: true
  },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: '*/AlertWeb',
  //   modal: true
  // },
  CreateNewPassword: {
    component: CreateNewPassword,
    path: '/CreateNewPassword'
  },
  WebForgetPassword: {
    component: WebForgetPassword,
    path: '/WebForgetPassword'
  },
  AccountBlock: {
    component: AccountBlock,
    path: '/AccountBlock'
  },
  CreateNewAccount: {
    component: CreateNewAccount,
    path: '/CreateNewAccount'
  },
  VerifyOTP: {
    component: VerifyOTP,
    path: '/VerifyOTP'
  },

  WebNavigationMenu: {
    component: WebNavigationMenu,
    path: '/WebNavigationMenu'
  },


  WNavigationMenu: {
    component: WNavigationMenu,
    path: '/WNavigationMenu'
  },
  WebAddContactUs: {
    component: WebAddContactUs,
    path: '/WebAddContactus'
  },
  VerificationSuccess: {
    component: VerificationSuccess,
    path: '/VerificationSuccess'
  }
};
// Core exception code Start
// Core exception code End
class App extends Component {
  render() {
  

    return (
      <View style={{ height: '100vh', width: '100vw',backgroundColor:"#f8f8f8" }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
