import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { TABS } from "../../../components/src/Constants";
import CONSTANTS from "../../../components/src/Constant";
import { Alert } from "react-native";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab: number;
  activeTab2: { name: string, id: number ,analytics:string },
  groups: { id: number, type: string, attributes: { name: string, groups: { members: number, name: string }[] } }[];
  groups_chats: { id: number, type: string, attributes: { name: string, groups: { members: number, name: string, id: number }[] } }[];
  filterModal1: boolean;
  filterModal2: boolean;
  expanded: boolean[];
  posts: { attributes: { body: string, comment_count: number, comments: [], created_at: string, id: number, name: string }, id: string, type: string }[];
  expand: boolean[];
  radiobuttonvalue: null[] | number[];
  checkboxvalue: boolean[][];
  checkindex: number[];
  comment: string[];
  token: string;
  loading: boolean;
  filter1_text: number,
  filter_gpa:number[],
  filter2_text: { id: number, name: string | null },
  createPost: boolean,
  my_post: string,
  user_id: string,
  unreadCount: number,
  GPA:any
  GPA2:any
  labelsgpa1:any
  datagpa1:any
  labelsgpa2:any
  datagpa2:any
  noPost:boolean
  showChatWindow:boolean
  openedComment:boolean
  expandedGroupComment:{ id: number, type: string, attributes: { name: string, groups: { members: number, name: string, id: number }[] }}
  expandedGroupIndex:number,
  loadingData:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class GroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiFetchPostsCallId: string = '';
  apiFetchGroups1CallId: string = '';
  apiFetchGroups2CallId: string = '';
  apiAddCommentCallId: string = '';
  apiAddPostsCallId: string = '';
  apiDeleteCommentCallId: string = '';
  apiFetchNotificationsCallId: string = '';
  focusListener: any;
  apiVisualAnalyticsGPACallId : any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      loading: false,
      activeTab: 0,
      radiobuttonvalue: [],
      checkboxvalue: [],
      checkindex: [],
      activeTab2: TABS[0],
      comment: [],
      token: '',
      posts: [],
      expand: [],
      groups: [],
      groups_chats: [],
      filterModal1: false,
      filterModal2: false,
      expanded: [],
      filter1_text: 0,
      filter_gpa:[],
      filter2_text: { id: 15, name: 'Custom' },
      createPost: false,
      my_post: '',
      user_id: '',
      unreadCount:0,
      GPA:[],
      GPA2:[],
      labelsgpa1:[],
      datagpa1:[],
      labelsgpa2:[],
      datagpa2:[],
      noPost:false,
      showChatWindow:false,
      openedComment:false,
      expandedGroupComment:{id:0,type:'',attributes:{name:'',groups:[]}},
      expandedGroupIndex:0,
      loadingData:true
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {


    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
     

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(apiRequestCallId && responseJson && !responseJson.errors && responseJson !== undefined){ 
      switch(apiRequestCallId){
        case this.apiFetchPostsCallId:
          this.apiFetchPostsMethod(responseJson);    
        break;
        case this.apiFetchNotificationsCallId:
            this.setState({unreadCount:responseJson?.meta?.unread_count})
            localStorage.setItem("unreadCount",JSON.stringify(responseJson?.meta?.unread_count));
        break;
        case this.apiDeleteCommentCallId:
            this.applyFilter2()
        break;
        case this.apiAddPostsCallId:
            this.setState({ my_post:'' })
            this.applyFilter2()
        break;
          case this.apiFetchGroups1CallId:
            this.apiFetchGroups1Method(responseJson);
        break;
        case this.apiFetchGroups2CallId:
          this.apiFetchGroups2Method(responseJson);
        break;
        case this.apiAddCommentCallId:
          this.apiAddCommentMethod(responseJson);
        break;
        case this.apiVisualAnalyticsGPACallId:
          this.apiVisualAnalyticsGPAMethod(responseJson)
        break;          
      }
    } else if(responseJson.errors && responseJson.errors[0].token !== undefined){
      localStorage.removeItem("windUser");
      localStorage.removeItem("windToken");
      this.props.navigation.navigate("EmailAccountLoginBlock"); 
    }  
  }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
      await this.getVisualAnalyticsGPA()
      await this.fetchNotification()
      const user_id = await JSON.parse(localStorage.getItem("windUser")!)?.data?.id;
      this.setState({ user_id })
      this.fetchGroups1();
  }
  
  apiFetchPostsMethod=(responseJsonData:any)=>{
    if (!responseJsonData.errors) {
      {(responseJsonData?.data?.message) ? this.setState({noPost:true,posts:[]}): this.setState({ showChatWindow:true, posts: responseJsonData?.data })}
      
      if (this.state.filter2_text?.id !== 0)
        this.setState({ createPost: true })
      else
        this.setState({ createPost: false })
    }
  }
  apiFetchGroups1Method=(responseJsonData:any)=>{
    if (!responseJsonData.errors) {
      let groups = responseJsonData?.data
      const { checkboxvalue } = this.state;
      for (let i = 0; i < groups.length; i++) {
        groups[i]?.attributes?.groups.push({ members: 0, name: 'All' });
        checkboxvalue[i] = [];
        for (let j = 0; j < groups[i]?.attributes?.groups?.length; j++)
          checkboxvalue[i][j] = false;
      }
      this.setState({ groups, checkboxvalue, filter1_text: 0 });
    }
  }
  apiFetchGroups2Method=(responseJsonData:any)=>{
    if (!responseJsonData.errors) {
      let groups_chats = responseJsonData?.data;
      const { radiobuttonvalue, } = this.state;
      for (let i = 0; i < groups_chats.length; i++) {
        for (let j = 0; j < groups_chats[i]?.attributes?.groups?.length; j++) {
          groups_chats[i].attributes.groups[j].value = j;
          groups_chats[i].attributes.groups[j].label = groups_chats[i].attributes.groups[j].name;
        }
        radiobuttonvalue[i] = null;
      }
      this.setState({ groups_chats, radiobuttonvalue, filter2_text: { id: 15, name: 'Custom' } });
     }
  }
  apiAddCommentMethod=(responseJsonData:any)=>{
    if (!responseJsonData.errors) {
      this.setState({ comment: [] })
      this.applyFilter2()
    }
    else {
      alert(responseJsonData.errors[0].message)
      this.setState({ loading: false })
    }
  }
  apiVisualAnalyticsGPAMethod=(responseJsonData:any)=>{
    this.setState({GPA:responseJsonData?.demo_gpa,GPA2:responseJsonData?.user_gpa})
        let labels :any=[]
        let data45 :any = []
        responseJsonData?.demo_gpa.forEach(function(val:any) {
            Object.entries(val).forEach(function([key, value]) {
              labels.push(key)
              data45.push(value)
            }) 
          })
          this.setState ({labelsgpa1: labels,datagpa1: data45 }) 
        let labels2 :any=[]
        let data452 :any = []
        responseJsonData?.user_gpa.forEach(function(val:any) {
            Object.entries(val).forEach(function([key, value]) {
              labels2.push(key)
              data452.push(value)
            }) 
          })
          this.setState ({labelsgpa2: labels2,datagpa2: data452 ,loadingData:false })
  }

  groupStats = () => {
    this.fetchGroups1()
    this.setState({ activeTab: 0,checkindex:[] })
    this.getVisualAnalyticsGPA();
  }

  groupChats = () => {
    this.fetchGroups1()
    this.fetchGroups2()
    this.fetchPosts('?group_ids[]=8&group_ids[]=12&group_ids[]=14&group_ids[]=10&group_ids[]=15&group_ids[]=17&group_ids[]=19&group_ids[]=21')
    this.setState({ activeTab: 1 })
  }

  filterButton = () => {
    const { filterModal1, filterModal2, activeTab } = this.state;
    if (!activeTab)
      this.setState({ filterModal1: !filterModal1 })
    else
      this.setState({ filterModal2: !filterModal2 })
  }

  closeModal1 = () => {
    const { expanded } = this.state;
    for (let i = 0; i < expanded.length; i++)
      expanded[i] = false
    this.setState({ filterModal1: false, expanded })
  }

  closeModal2 = () => {
    const { expanded } = this.state;
    for (let i = 0; i < expanded.length; i++)
      expanded[i] = false
    this.setState({ filterModal2: false, expanded })
  }

  filterAction = (index: number) => {
    const { expanded } = this.state;
    expanded[index] = !expanded[index]
    this.setState({ expanded })
  }

  radioButtonAction = (value: number, name: string) => {
    let { radiobuttonvalue, filter2_text } = this.state;
    radiobuttonvalue[0] = value;
    this.setState({ radiobuttonvalue }
    )
    filter2_text.id = value;
    filter2_text.name = name;
    this.setState({ filter2_text })
   }

  getFilterText = () => {
    const { filter1_text, filter2_text, activeTab } = this.state
    if (activeTab) {
      if (filter2_text.id !== 0)
        return (
          "(" + filter2_text.name + ")"
        )
    }
    else {
      if (filter1_text)
        return (
          "(" + filter1_text + ")"
        )
    }
  }
  applyFilter1 = () => {
    const { filter_gpa } = this.state
    this.closeModal1()
    this.setState({loadingData:false})
    let filterPost = '';
      this.setState({
        labelsgpa1:[],
        labelsgpa2:[],
        datagpa1:[],
        datagpa2:[],
        loadingData:true
      })
      this.getVisualAnalyticsGPA();
  }
  applyFilter2 = () => {
    const { filter2_text } = this.state
    this.closeModal2()
    let filterPost = '';
    if (filter2_text.id == 15){
      filterPost = '?group_ids[]=8&group_ids[]=12&group_ids[]=14&group_ids[]=10&group_ids[]=15&group_ids[]=17&group_ids[]=19&group_ids[]=21';
      this.fetchPosts(filterPost)
      this.setState({
        showChatWindow:true,
        noPost:false
      })
    }
    else {
      filterPost = filterPost + '?group_ids[]=' + filter2_text.id
      this.fetchPosts(filterPost)
      this.setState({
        showChatWindow:true,
        noPost:false
      })
    }
  }

  checkBoxAction = (value:boolean, index:any, subindex:any, item:any) => {
    let { checkboxvalue, checkindex, filter1_text, filter_gpa } = this.state;
    
    if (item.id && item?.attributes?.groups.length == subindex + 1) {
      item.attributes.groups.forEach((group:any, i:number) => {
        checkboxvalue[index][i] = value;
        if (value && group.name !== "All" && !checkindex.includes(group.id)) {
          checkindex.push(group.id);
          filter1_text++;
          filter_gpa.push(index);
        } else if (!value && group.name !== "All" && checkindex.includes(group.id)) {
          checkindex.splice(checkindex.indexOf(group.id), 1);
          filter1_text--;
        }
      });
      
    } else {
      checkboxvalue[index][subindex] = value;
      const group = item.attributes.groups[subindex];
      if (value && !checkindex.includes(group.id)) {
        checkindex.push(group.id);
        filter1_text++;
        filter_gpa.push(index);
      } else if (!value && checkindex.includes(group.id)) {
        checkindex.splice(checkindex.indexOf(group.id), 1);
        filter1_text--;
      }
    }
    const allGroupsChecked = item.attributes.groups.every((group:any) => checkindex.includes(group.id));
    checkboxvalue[index][item.attributes.groups.length - 1] = allGroupsChecked && !value;

    
    this.setState({ checkboxvalue, checkindex, filter1_text, filter_gpa });
  };

  onChangeComment = (new_comment: string, index: number) => {
    const { comment } = this.state;
    comment[index] = new_comment;
    this.setState({ comment })
  }

  onChatClick = (index: number) => {
    let { expand } = this.state;
    expand[index] = !expand[index];
    this.setState({ expand })
    this.setState({openedComment:!this.state.openedComment})
  }
  onChangePost = (my_post: string) => {
    this.setState({ my_post })
  }
  onCommemtClick = (post_userid: string, comment_id: string) => {
    const { user_id } = this.state
    if (parseInt(post_userid) === parseInt(user_id))
    {
      Alert.alert('Delete', 'Do you want to delete this comment?', [
        {
          text: 'No',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {text: 'Yes', onPress: () => this.deleteComment(comment_id, post_userid)},
      ]);
    }
  }
  deleteComment = async (comment_id: string, post_userid: string) => {
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteCommentCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (`${configJSON.groupDeleteCommentAPIEndPoint}${post_userid}/delete_comment?comment_id=` + comment_id).trim()
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteCommentApiMethodType
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  addPost = async () => {
    const { my_post, filter2_text } = this.state
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const attrs = {
      post:
      {
        name: '',
        body: my_post,
        group_id: filter2_text.id
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddPostsCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (`${configJSON.addPostAPIEndPoint}`)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addPostApiMethodType
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)

  }

  onTab2Click = (item: { name: string, id: number ,analytics:string },) => {
    this.setState({ activeTab2: item },()=>{ this.setState({
      labelsgpa1:[],
      labelsgpa2:[],
      datagpa1:[],
      datagpa2:[],
      loadingData:true
    }) 
    this.getVisualAnalyticsGPA()});
  }

  fetchPosts = async (filterPost: string) => {
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFetchPostsCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (`${configJSON.getPostsAPIEndPoint}` + filterPost).trim()
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostApiMethodType
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)


  }

  fetchGroups1 = async () => {
    // Customizable Area Start
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFetchGroups1CallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupChatAPIEndPoint}&for_chat=false`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getGrousp1ApiMethodType
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    // Customizable Area End
  }

  fetchGroups2 = async () => {
    const token = localStorage.getItem("windToken");

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFetchGroups2CallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupChatAPIEndPoint}&for_chat=true`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getGrousp2ApiMethodType
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)


  }

  fetchNotification = async () => {
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFetchNotificationsCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointNotifications
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNotificationApiMethodType
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)


  }

  addComment = async (post_id: number, index: number) => {
    const { comment } = this.state
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };

    const attrs = {
      comment:
      {
        comment: comment[index]
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddCommentCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupAddCommentAPIEndPoint}${post_id}/add_comment`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addCommetApiMethodType
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)


  }

  
  getVisualAnalyticsGPA = async () => {
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token
    };
    const analyticsGroupId = this.state.checkindex.length > 0 ? `&analytics[group_ids][]=${this.state.checkindex}` : '';
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiVisualAnalyticsGPACallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.visualGPAStatsAPIEndPoint}?analytics[interval]=${this.state.activeTab2.analytics}${analyticsGroupId}` 
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNotificationApiMethodType
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // Customizable Area End
}
