Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

const urlConfig = require("../../../framework/src/config")
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";

exports.faqEndPoint = "bx_block_help_centre/question_answer"
exports.previewVideoEndPoint = "bx_block_contact_us/preview_videos"
exports.postLogoutEndPoint = "bx_block_logout/logouts"
exports.postLogoutMethodType = "POST";

exports.baseUrl = urlConfig.baseURL
// Customizable Area End