import React from "react";
// Customizable Area Start
import {
  makeStyles,
} from "@material-ui/core/styles";
import { Box,Grid,Paper,Typography,TableBody,Table,TableCell,TableRow } from "@material-ui/core";
import "../css/CustomDataTable.css";
const useStyles = makeStyles({
    
})
// Customizable Area End

const CustomDataTable = (props: any) => {
  // Customizable Area Start  
  
 
  const week = props?.weekData?.week;
  // Customizable Area End
  return (
    // Customizable Area Start
    //@ts-ignore
        <Box sx={{width:"100%"}}>
          <Grid container>
          
            <Grid item sm={4} md={4} className="dataTableGrid">
              <Typography className="weeklyDataHead">W/L</Typography>
              { 
                week?.map((item:any,index:number)=>{
                  return(
                    
                    <Grid container className="weeklyDataGridContainer">
                      <Grid item xs={3} md={2} className="weeklyDataGridItem">
                        {item?.color_band?.map((elem:any, iIndex:number)=>{
                          console.log("This is index",index)
                            if(index==0 && iIndex==0)
                                return <div className="weeklyDataGridTopDiv" style={{backgroundColor:elem}}></div>
                            else if(index==6 && iIndex==0)
                                return <div className="weeklyDataGridBottomDiv" style={{backgroundColor:elem}}></div>
                            else
                                return <div className="weeklyDataGridDiv" style={{backgroundColor:elem}}></div>
                              
                          })
                        }
                      </Grid>
                    </Grid>
                  )
                })
              }
              </Grid>
              <Grid item sm={8} md={6} >
                <Typography className="dowHeader">DOW</Typography>
                <Typography className="gradeHeader">Grade</Typography>
                <Paper elevation={3} className="dataWeeklyTablePaper" >
              <Table className="dataWeeklyTable" >
                  <TableBody>
                {
                week?.map((item:any,index:number)=>{
                  return(      
                      <TableRow>
                          <TableCell style={{ fontSize:"1.2rem" }}>{item?.dow}</TableCell>
                          <TableCell style={{ fontSize:"1.2rem" }}>{item?.grade}</TableCell>
                      </TableRow>
                  )
                })
              }
                </TableBody>
              </Table>
              </Paper>
              </Grid>
              </Grid>     
        </Box>
        
    // Customizable Area End
  );
};

export default CustomDataTable;
