import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timeout: any;
  videoLink:string;
  fetchLandingComponent:number;
  termsAndConditions:string;
  termsStartDate:string;
  termsEndDate:string;
  policyStartDate:string;
  policyEndDate:string;
  privacyPolicy:string;
  name:string;
  email:string;
  comments:string;
  emailError:boolean;
  usernameError:boolean;
  messageError:boolean;
  emailErrorMsg:string;
  successMessageSent:boolean;
  faqList:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SplashscreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Core exception code Start
  previewVideoCallId: string="";
  getTermsAndConditionsCallId: string="";
  getPrivacyPolicyCallId:string="";
  addContactApiCallId:string="";
  getFaqsListCallId:string="";
  // Core exception code End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      timeout: 2000,
      // Core exception code Start
      videoLink:"",
      fetchLandingComponent:1,
      termsAndConditions:"",
      termsEndDate:"",
      termsStartDate:"",
      privacyPolicy:"",
      policyStartDate:"",
      policyEndDate:"",
      name: "",
      email: "",
      comments: "",
      usernameError: false,
      emailError: false,
      messageError: false,
      emailErrorMsg:"",
      successMessageSent:false,
      faqList:{}
      // Core exception code End
    }
    
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage), 
    ];
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    runEngine.debugLog("Message Recived", message);
     if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.previewVideoCallId !== "" && this.previewVideoCallId === apiRequestCallId)
      {
        this.previewVideoCallMethod(responseJson);
      }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTermsAndConditionsCallId !== "" &&
      this.getTermsAndConditionsCallId ===
      apiRequestCallId
    ) {

      this.getTermsAndConditionsCallMethod(responseJson);
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPrivacyPolicyCallId !== "" &&
      this.getPrivacyPolicyCallId ===
      apiRequestCallId) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        this.getPrivacyPolicyCallMethod(responseJson);
        
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFaqsListCallId !== "" &&
      this.getFaqsListCallId ===
      apiRequestCallId) {
     
        this.getFaqsListCallMethod(responseJson);
       
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addContactApiCallId !== "" &&
      this.addContactApiCallId ===
      apiRequestCallId) {
     
        this.addContactApiCallMethod(responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getVideoLink();
    this.getTermsAndConditions();
    this.getPrivacyPolicy();
    this.getFaqsList();
    this.fetchNavigationFooter();
  }
  fetchNavigationFooter=()=>{
      if(localStorage.getItem("windUserFooterLink")==="ContactUs"){
        this.setState({fetchLandingComponent:3})
        localStorage.removeItem("windUserFooterLink");
      }
      else if(localStorage.getItem("windUserFooterLink")==="Policy"){
        this.setState({fetchLandingComponent:5})
        localStorage.removeItem("windUserFooterLink");
      }
      else if(localStorage.getItem("windUserFooterLink")==="TermsConditions"){
        this.setState({fetchLandingComponent:4})
        localStorage.removeItem("windUserFooterLink");
      }
  }
  goToLogin=()=>{
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }
  previewVideoCallMethod=(responseJsonData:any)=>{
    

      if(responseJsonData !== undefined && responseJsonData.errors === undefined){
        console.log("preview video api ============> ", responseJsonData);
        this.setState({
          videoLink : responseJsonData?.data?.attributes?.video_url
        });
      }
  }
  getTermsAndConditionsCallMethod=(responseJsonData:any)=>{
    if(responseJsonData !== undefined && responseJsonData.errors === undefined){
      console.log("preview terms api ============> ", responseJsonData);
      this.setState({
        termsAndConditions : responseJsonData?.data[0]?.attributes?.description,
        termsStartDate: responseJsonData?.data[0]?.attributes?.start_date,
        termsEndDate: responseJsonData?.data[0]?.attributes?.end_date
      });
    }
  }
  getPrivacyPolicyCallMethod=(responseJsonData:any)=>{
    if(responseJsonData !== undefined && responseJsonData.errors === undefined){
      console.log("preview policy api ============> ", responseJsonData);
      this.setState({
        privacyPolicy : responseJsonData?.data[0]?.attributes?.description,
        policyEndDate: responseJsonData?.data[0]?.attributes?.end_date,
        policyStartDate: responseJsonData?.data[0]?.attributes?.start_date
      });
    }
  }
  addContactApiCallMethod=(responseJsonData:any)=>{
    if(responseJsonData !== undefined && responseJsonData.errors === undefined){
      console.log("preview contact api ============> ", responseJsonData);
      this.setState({
        successMessageSent:true
      });
      const timeout = setTimeout(() => {
        this.setState({
          successMessageSent: false
        });
        clearTimeout(timeout);
      }, 1500);
      this.setState({
        comments:""
      })
    }
  }
  getFaqsListCallMethod=(responseJsonData:any)=>{
    if(responseJsonData !== undefined && responseJsonData.errors === undefined){
      console.log("preview faq api ============> ", responseJsonData);
      this.setState({
        faqList : responseJsonData?.data
      });
    }
  }
  getVideoLink=():boolean=>{
    const header = {
      "Content-Type": configJSON.landingPageApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.previewVideoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.videoLinkAPIEndPoint
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getTermsAndConditions=():boolean=>{
    const header = {
      "Content-Type": configJSON.landingPageApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsAndConditionsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndConditionEndPoint
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getPrivacyPolicy=():boolean=>{
    const header = {
      "Content-Type": configJSON.landingPageApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivacyPolicyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyEndPoint
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getFaqsList=():boolean=>{
    const header = {
      "Content-Type": configJSON.landingPageApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFaqsListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqEndPoint
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };
  addQueryApi = (name:string,email:string,comments:string) => {
    if(this.isStringNullOrBlank(email) ){
      this.setState({ emailError:true});
      return false
    }
    if( this.isStringNullOrBlank(name) ){
      this.setState({usernameError:true})
      return false
    }
    if(this.isStringNullOrBlank(comments) || comments.length < 30 || comments.length > 2000 ){
      this.setState({messageError:true})
      return false
    }
    this.setState({
      usernameError:false,
      emailError:false,
      messageError:false
    })
      let data = {
        data: {
          name: name.trim(),
          email: email.trim(),
          description: comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.landingPageApiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactUSEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  };
  // Customizable Area End
}
