import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  Paper, List, Checkbox,
  Grid, Tab,
  Divider, TextField,FormControlLabel, RadioGroup, Radio,Collapse
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { filter, filter_green, blue, orange,commentIcon } from './assets';
import { KeyboardArrowLeft } from "@material-ui/icons";

import "../css/GroupsWeb.css";
import { TABS } from "../../../components/src/Constants";
import  Chart  from "react-google-charts";
import Loader from "../../../components/src/Loader.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import GroupsController, {
  Props,
} from "./GroupsController.web";

export default class Groups extends GroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCheckBox = (subitem: any, subindex: any, item: any, index: any) => {
    return (
      <FormControlLabel
        key={index}
        label={subitem?.name}
        data-test-id="test-filter"
        className="statCheckItem"
        control={
          <Checkbox
          className="root"
          color="default"
          icon={<span className="icon"/>}
          checkedIcon={<span className="checkedIcon" />}
          checked={this.state.checkboxvalue[index][subindex]}
          inputProps={{'aria-label':'checkbox'}}
          onChange={(event:any) => this.checkBoxAction(event.target.checked,index, subindex, item)}
          size="small"
          />
        }
      />
      );
  }
  renderStats = (allStats: any) => {
    let allStat = allStats.map((item: any, index: any) => {
      return (
        <Box key={index}>
          <Typography variant="body1" className="filterItemHeading" onClick={() => this.filterAction(index)}>{item?.attributes?.name}</Typography>
          <Collapse in={this.state.expanded[index]} className="boxStatFilterItems">
                {item?.attributes?.groups.map((subitem: any,subindex:any) =>
                 this.renderCheckBox(subitem, subindex, item, index) 
                )}
           </Collapse>
          <hr className="hrFilterLine"/>
        </Box>
      )
    });
    return allStat;
  }
  renderPosts = (allPost: any) => {
    let allPosts = allPost.map((item: any, index: any) => {
      return (
        <Box
          className="postBoxes"
        >
          <Box className="firstPostBox" >
            <Box >
              <Typography className="usernameField">{item.attributes.user_name}</Typography>
              <Typography className="groupnameField">{item.attributes.account_group.name}</Typography>
            </Box>
            <Typography className="creationUserField">{item.attributes.created_at}</Typography>
            <Typography className="userPostField">{item.attributes.body}</Typography>
            <Box onClick={() => {this.onChatClick(index); this.setState({expandedGroupComment:item,expandedGroupIndex:index})}}>
            <img src={commentIcon} className="commentIconChatLength"/>
             <Typography className="commentCountField">{item?.attributes?.comments.length} Comments</Typography>
            </Box>
            <Divider className="hrDivider" />
          </Box>
          <Box>
            <TextField
              data-test-id="addPostField"
              placeholder="Write a comment..."
              className="addCommentField"
              value={this.state.comment[index]}
              onChange={(event: any) => this.onChangeComment(event.target.value, index)}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.addComment(item.id, index);
                  event.target.value="";
                }
              }}
            />
            {this.state.expand[index] ? (
              <Box>
              </Box>
            ) : null}
            {index && (parseInt(index) + 1) % 3 === 0 && !this.state.expand[index] ? (
              <Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      )
    })
    return allPosts;
  };
  renderComments = (subitem: any, index: any) => {
    return (
      <div onClick={() => this.onCommemtClick(subitem.account_id, subitem.id)}>
        <Typography className="topCommentsUserName">{subitem.user_name}</Typography>
        <Typography className="topUserComment">{subitem.comment}</Typography>
        <Typography className="topUserCommentCreated">{subitem.created_at}</Typography>
        <Divider className="hrDivider" />
      </div>
    )
  }
  renderExpandedComments=(item:any,index:any)=>{
    return(
      <>
        <KeyboardArrowLeft className="keyboardIconComments" onClick={()=> {this.onChatClick(index);}}/>
        <Box className="expandedPostBox" >
            <Box>
              <Typography className="usernameField">{item.attributes.user_name}</Typography>
              <Typography className="groupnameField">{item.attributes.account_group?.name}</Typography>
            </Box>
            <Typography className="creationUserField">{item.attributes.created_at}</Typography>
            <Typography className="userPostField">{item.attributes.body}</Typography>
            <Box onClick={() => {this.onChatClick(index);}}>
              <Box></Box>
              <img src={commentIcon} className="commentIconChatLength"/>
              <Typography className="commentCountField">{item?.attributes?.comments?.length} Comments</Typography>
            </Box>
            <Box />
            <Divider className="hrDivider" />
          </Box>
      <Box>
              {item?.attributes?.comments?.length ? (
                <Box >
                  <Typography className="topCommentHeading">Top Comments</Typography>
                  <List className="topCommentsList">
                    {item?.attributes?.comments.map((subitem: any) => (
                      <React.Fragment key={subitem.id}>
                        {this.renderComments(subitem, index)}
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
              ) : null}
          </Box>
          <Box >
            <TextField
              data-test-id="addPostField"
              placeholder="Write a comment..."
              className="addCommentFieldOpenChat"
              value={this.state.comment[index]}
              onChange={(event: any) => this.onChangeComment(event.target.value, index)}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.addComment(item.id, index);
                  this.onChatClick(index);
                }
              }}
            />
            {this.state.expand[index] ? (
              <Box>
              </Box>
            ) : null}
            {index && (parseInt(index) + 1) % 3 === 0 && !this.state.expand[index] ? (
              <Box>
              </Box>
            ) : null}
          </Box>
      </>
    )
  }
  
  renderRadioButton = (subitem: any, subindex: any, item: any, index: any) => {
    return (
      <FormControlLabel
        key={index}
        label={item?.attributes?.groups[subindex]?.name}
        control={
          <Radio
          data-test-id="filter-radio"
          className="chatFilterItem"  
          checked={this.state.radiobuttonvalue[0] === item?.attributes?.groups[subindex]?.id}
            onChange={() => {
              this.radioButtonAction(item?.attributes?.groups[subindex]?.id, item?.attributes?.groups[subindex]?.name)
            }}
            color="primary"
            size="small"
          />
        }
        onClick={() => {
          this.radioButtonAction(item?.attributes?.groups[subindex]?.id, item?.attributes?.groups[subindex]?.name)
        }}
      />

    );
  }

  renderChats = (allChat: any) => {
    let allChats = allChat.map((item: any, index: any) => {
      return (
        <Box key={index} data-test-id="filter-chat-box">
          <Typography variant="body1" className="filterItemHeading" onClick={() => this.filterAction(index)}>{item?.attributes?.name}</Typography>
          <Collapse in={this.state.expanded[index]} className="boxChatFilterItems">
                  <RadioGroup data-test-id="filter-radio-group">
                  {item.attributes.groups.map((subitem: any, subindex: any) => (
                      this.renderRadioButton(subitem, subindex, item, index)
                    ))}
                  </RadioGroup>
          </Collapse>
          <hr className="hrFilterLine"/>
        </Box>
          
      )
    })
    return allChats;
  }
  tabDays = ( item:any ) =>{
    const tabList = item.map((tab:any)=>{
      return (
        <Tab
        label={tab?.name} 
        onChange={() => this.onTab2Click(tab)} 
        className={(this.state.activeTab2.name==tab.name)?"activeVATab":"inactiveVATab"}
        />
      )
    })
    return tabList;
  }
  renderColorLine2 = () => "rgb(255,0,0)"
  renderColorBackground = () => `rgba(216, 216, 216, 1)`
  renderColorLabel = (opacity = 1) => `rgba(0, 0, 0, ${opacity})`
  _render30daysTab = () => {
    return (
      <>
      <Box display="flex" flexDirection="column" alignItems="center" className="gpaChartBox">
        <Typography variant="h6" className="gpaBarValue">GPA</Typography>
        <Box className="gpaChartContainer">
        { this.state.loadingData ? (<Loader loading={true}></Loader>): (
          <Chart
          chartType="LineChart"
          data={[
            ["x","Your GPA","Benchmark"],
            ...this.state.labelsgpa2.map((label:any,i:any)=>[
              label,
              parseFloat(this.state.datagpa2[i]),
              parseFloat(this.state.datagpa1[i]),
          ])
          ]}
          options={{
            backgroundColor: "#f8f8f8",
            series:{
              0: {type:'line',color:'rgb(32, 93, 134)',pointSize:10,pointShape:"circle"},
              1: {type:'line',color:'rgb(255,0,0)',pointSize:3,pointShape:"circle",lineDashStyle:[6,2]}
            },
            lineWidth: 2,
            legend: {
              position: "none",
            },
            hAxis: {
              fontSize:'5px',
              showTextEvery:3,
              slantedTextFalse:false,
            },
            vAxis: {
              fontSize:'5px',
              maxValue:4,
            }
          }}
        />
        )}
        
        </Box>
        <Divider style={{ margin: "20px 0", width: "100%" }} />
        <Box display="flex" alignItems="center" className="labelsforGroups">
          <Box mr={1}>
            <img src={blue} alt="blue" className="blueLabelGroups"/>
          </Box>
          <Typography className="yourGPALabelGroups">Your GPA</Typography>
          <Box ml={4}>
            <img src={orange} alt="orange" className="orangeLabelGroups"/>
          </Box>
          <Typography className="benchmarkLabelGroups">Benchmark</Typography>
        </Box>
      </Box>

    </>
    );
  }
  groupStatClassName =()=> !this.state.activeTab ? "activeGroupStatTab":"inactiveGroupStatTab";
  groupChatClassName =()=>this.state.activeTab ? "activeGroupChatTab":"inactiveGroupChatTab";
  getFilterImage=()=>(this.state.filterModal1 || this.state.filterModal2 ? filter_green : filter);
  getGroupsTabs =()=>{
    let content :any = "";
  if (!this.state.activeTab && this.state.filterModal1) {
  content = (
    <Box>
      {this.renderStats(this.state.groups)}
      <Button className="applyFilterButton" onClick={this.applyFilter1}>
        Apply Filter
      </Button> 
    </Box>
  );
} else if (this.state.activeTab && this.state.filterModal2) {
  content = (
    <Box>
      {this.renderChats(this.state.groups_chats)}
      <Button className="applyFilterButton" 
       onClick={this.applyFilter2}>
        Apply Filter
      </Button>
    </Box>
  );
}

return content;
}
checkRenderPost=()=>{
  let renderPost = null;
    if(this.state.noPost) {
      renderPost = (
        <>
       <Input
          placeholder="Share with group..."
          value={this.state.my_post}
          className="shareInputField"
          onChange={(event: any) => this.onChangePost(event.target.value)}
          onKeyDown={(event: any) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              this.addPost();
            }
          }}
          data-test-id="inputTestingField"
        />
        <Divider className="hrShareDivider" />
        <Typography variant="body1" className="noPostTypography">No Posts available !!</Typography>
         {this.renderPosts(this.state.posts)}
         </>
      )
    } 
    else {
      renderPost = 
      (<>
       <Input
          placeholder="Share with group..."
          value={this.state.my_post}
          className="shareInputField"
          onChange={(event: any) => this.onChangePost(event.target.value)}
          onKeyDown={(event: any) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              this.addPost();  
            }
          }}
        />
        <Divider className="hrShareDivider" />
         {this.renderPosts(this.state.posts)}
         </>)
        }
    return renderPost;
}  
  renderRightContainer=()=>{
    let rightSideContainer:any = null;
      if (this.state.loading) {
        rightSideContainer = (
          <Box>
            <Loader loading={this.state.loading} />
          </Box>
        );
      } else if (!this.state.activeTab) {
        rightSideContainer = (
          <Grid item sm={12} md={12} lg={6} className="rightStatContainer">
            <Paper className="rightStatContainerPaper">
              <Box className="tabListBox">
                <List className="tabVABox">{this.tabDays(TABS)}</List>
                <Box>
                  {this.state.activeTab2.id === 0 && this._render30daysTab()}
                  {this.state.activeTab2.id === 1 && this._render30daysTab()}
                  {this.state.activeTab2.id === 2 && this._render30daysTab()}
                </Box>
              </Box>
            </Paper>
          </Grid>
        );
      } else if(this.state.activeTab && (this.state.showChatWindow && !this.state.openedComment)) {
          rightSideContainer = (
            <Grid item sm={12} md={12} lg={6} className="rightChatContainer">
              <Paper elevation={3} className="groupChatsPaper">
                {this.checkRenderPost()}
              </Paper>
            </Grid>
          )
       } else if(this.state.activeTab && (this.state.showChatWindow && this.state.openedComment)) {
           rightSideContainer=( 
           <Grid item sm={12} md={12} lg={6} className="rightChatContainer">
              <Paper elevation={3} className="openedGroupChats">
                  { this.renderExpandedComments(this.state.expandedGroupComment,this.state.expandedGroupIndex) } 
                  </Paper>
            </Grid>
           )
       }
      else {
        rightSideContainer = (
          <Grid className="emptyRightSide"></Grid>
        );
      }
      return rightSideContainer;
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className="rootGroups">
        <Grid container className="mainContainerGrid">
          <Grid item sm={12} md={12} lg={6} className="leftFilterContainer">
          <Paper className="leftFilterClosedContainer">
          <Box className="tabGridBox">
            <Tab label="Group Stats" data-test-id="groupStatTab" onClick={this.groupStats} className={this.groupStatClassName()} />
            <Tab label="Group Chats" data-test-id="groupChatTab" onClick={this.groupChats} className={this.groupChatClassName()}  />
          </Box>
          <Box onClick={this.filterButton} data-test-id="filter-test" className="filterFieldBox">
              <img src={this.getFilterImage()} className="filterImage" />
              <Typography className="filterTextBox">Filter {this.getFilterText()}</Typography>
          </Box>
            {this.getGroupsTabs()}
          </Paper>
          </Grid>
               { this.renderRightContainer()}
        </Grid>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
