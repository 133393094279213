export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");


export const googleIcon = require("../assets/google-icon.png");
export const Logo = require("../assets/windeverest-icon.jpg");


export const CloseEye = require("../assets/close-eye.png");
export const OpenEye = require("../assets/open-eye.png");
export const sideCover = require("../assets/image__wind_.png");
export const forgetposter = require("../assets/forget_poster.png");
export const accountBlockPoster = require("../assets/account_block_poster.png");
export const verifyCodePoster = require("../assets/verify_code_poster.png");