import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  
  // Customizable Area Start
  // Customizable Area End
}
interface AdManagerData{
  idCount:string;
  type:string;
  attributes:{
    start_date:string;
    end_date:string;
    status:boolean;
    link:string;
    message:string;
    view_count:number;
    click_count:number;
    image:string;
  }
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentImage:number;
  status:boolean;
  viewAd:boolean;
  number:string;
  arrayHolder:AdManagerData[];
  // Customizable Area End
}

interface SS {

  // Customizable Area Start
  // Customizable Area End
}

export default class AdManagerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId:string="";
  getCountApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
       // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      arrayHolder:[],
      currentImage:0,
      status:true,
      viewAd:false,
      number:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
 
  async componentDidMount() {
    this.getListRequest()
      setInterval(() => {
      this.intervalShow();
      this.getListRequest(); 
    },30000)  
}
  

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.getProductApiCallId) {
          
          if (!responseJson.errors) {
            const covertId = responseJson.data.map((item:{attributes:string,id:string})=>{
              return { idCount:item.id, attributes:item.attributes };
            })
            let filteredData = covertId.filter( function (item:{attributes:{ start_date:string, end_date:string }}){
            const dateCurrent= moment(new Date).format('MM/DD/YYYY');
            const dateStart= moment (item.attributes.start_date).format('MM/DD/YYYY');
            const dateEnd=moment (item.attributes.end_date).format('MM/DD/YYYY')
            
          let  fDate = Date.parse(dateStart);
          let lDate = Date.parse(dateEnd);
          let  cDate = Date.parse(dateCurrent);
            return (cDate <= lDate && cDate >= fDate)   ;
        });
       
       this.setState({arrayHolder:filteredData});                 
         }
        }
      }
    }

   // Customizable Area End
  }

  // Customizable Area Start
  getListRequest = async () => {
    const token = await StorageProvider?.get('token')

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
       token:token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.exampleAPiEndPoint
     
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.exampleAPiMethod
     
      
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  };
  
  intervalShow=()=>
  {
 
    if(this.state.currentImage>=this.state.arrayHolder.length-1){
    this.setState({currentImage:0})
  }else{
this.setState({currentImage:this.state.currentImage+1})

}
  }

  clickCount=(number:string)=>{
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
     
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://windeverest-225652-ruby.b225652.stage.eastus.az.svc.builder.ai/bx_block_ad_manager/ad_managers/${number}/update_click_count` 
     
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true

  }
  
  // Customizable Area End
}

