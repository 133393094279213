import React from "react";
import {
    makeStyles,
} from "@material-ui/core/styles";
import { Container, Grid, Typography,Link } from "@material-ui/core";
import { circleIcon,circleHalfRight,graphIcon,benchmarkIcon,shareIcon,shareButtonIcon,gpaIcon,weeksIcon, daysIcon,onboardingThreeIcon, popupIconBoard, redArrowIcon, circleTopLeft, windEverest } from "./assets";
const useStyles = makeStyles({

})
export const data = [
    ["date","GPA","benchmark"],
    ["7/3"],
    ["7/17"],
    ["7/31"],
    ["8/14"],
    ["8/28"],
    ["9/11"],
    ["9/25"]
]
import "../css/OnboardingThreeWeb.css"
const navigateToPages=(navItem:string)=>{
    if(navItem.includes("termsandconditions")){
     localStorage.setItem("windUserNotificationLink","TermsConditions");
    }
    else if(navItem.includes("privacypolicy")){
      localStorage.setItem("windUserNotificationLink","PrivacyPolicy");
    }
    else if(navItem.includes("contactus")){
        localStorage.setItem("windUserNotificationLink","ContactUs");
    }
  }
const OnboardingThree = () => {
    return (
        <Container className="OnboardingThreeContainer">
            <Grid container className="topRowGrid">
                <Grid item md={3} className="logoGridThree">
                    <img src={circleTopLeft} className="circleTopLeftImgThree" />
                    <img src={windEverest} className="windEverestLogoThree" />
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item md={3} className="rightIconGridThree">
                    <img src={circleHalfRight} className="rightCircleIconThree" />
                </Grid>
            </Grid>
            
            <Grid container>
                <Grid item md={3}>
                    <img src={circleIcon} className="circleBottomLeftThree"></img>
                </Grid>
                <Grid item md={6} className="laptopGridThree">
                    <img src={onboardingThreeIcon} className="laptopImageThree"></img>
                </Grid>
                <Grid item md={3} className="popupVAGridThree">
                    <img src={popupIconBoard} className="popupVAPanelGridThree"/>
                   <img src={daysIcon} className="daysIcon"></img>
                   <Typography variant="body1" className="daysInfo">30 Days</Typography>
                   <img src={weeksIcon} className="weeklyIcon"></img>                   
                   <Typography variant="body1" className="weeksInfo">13 Weeks</Typography>

                   <img src={daysIcon} className="yearlyIcon"></img>
                   <Typography variant="body1" className="yearsInfo">1 Year</Typography>

                    <img src={graphIcon} className="graphIcon"></img>
                    <Grid container className="gpaListGrid">
                        <Grid item md={3} className="gpaHeadGrid">GPA</Grid>
                        <Grid item md={3} className="gpaItemGrid">4.</Grid>
                        <Grid item md={3} className="gpaItemGrid">3.</Grid>
                        <Grid item md={3} className="gpaItemGrid">3.</Grid>
                        <Grid item md={3} className="gpaItemGrid">2.</Grid>
                    </Grid>
                    <Grid container className="dateGrid">
                        <Grid item md={1} className="dateItem">7/3</Grid>
                        <Grid item md={1} className="dateItem">7/17</Grid>
                        <Grid item md={1} className="dateItem">7/31</Grid>
                        <Grid item md={1} className="dateItem">8/14</Grid>
                        <Grid item md={1} className="dateItem">8/28</Grid>
                        <Grid item md={1} className="dateItem">9/11</Grid>
                        <Grid item md={1} className="dateItem">9/25</Grid>
                    </Grid>
                    <img src={gpaIcon} className="gpaIcon"></img>
                    <Typography variant="body1" className="gpaInfoHeading">Your GPA</Typography>
                    <img src={benchmarkIcon} className="benchmarkIcon"></img>
                    <Typography variant="body1" className="benchmarkHeading">Benchmark</Typography>
                    <img src={shareIcon} className="shareIcon"></img>
                    <img src={shareButtonIcon} className="shareButtonIcon"></img>
                    <Typography variant="h6" className="shareButtonText">Share</Typography>
                </Grid>
                 <Grid container className="onBoardingThreeLowerInfoGrid">
                       <Grid item md={1} className="onBoardingThreeLowerInfo">
                            <img src={redArrowIcon} className="onBThreeArrowLowerIcon"/>
                        </Grid>
                        <Grid item md={11}>
                            <Typography className="onBThreeArrowSecondInfo">Track how well you score windy nights with a GPA. See weekly progress and if you are doing well versus the fleet.</Typography>
                        </Grid>
                </Grid>

               
            </Grid>
        <Grid container className="footerOnBoardingOddThree">    
        <Grid item md={4} className="typographyTACGrid">
                <Link variant="body1" className="typographyTAC" href="/WebNavigationMenu" onClick={()=>navigateToPages("termsandconditions")}>Terms and Conditions</Link>
            </Grid>
            <Grid item md={4} className="typographyPolicyGrid">
                <Link variant="body1" className="typographyPolicy" href="/WebNavigationMenu" onClick={()=>navigateToPages("privacypolicy")}>Policy</Link>
            </Grid>
            <Grid item md={4} className="typographyContactGrid">
                <Link variant="body1" className="typographyContact" href="/WebNavigationMenu" onClick={()=>navigateToPages("contactus")}>Contact us</Link>
            </Grid>
        </Grid>
        </Container>
    )
}
export default OnboardingThree;