export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const camel = require("../assets/camel.png");
export const discriminating = require("../assets/discriminating.png");
export const evangelist = require("../assets/evangelist.png");
export const fullyCharged = require("../assets/fully_charged.png");
export const hardCharger = require("../assets/hard_charger.png");
export const horticulturalist = require("../assets/horticulturalist.png");
export const madeYourOwnLuck = require("../assets/made_your_own_luck.png");
export const overachiever = require("../assets/overachiever.png");
export const redemeed = require("../assets/redemeed.png");
export const roll = require("../assets/roll.png");
export const starter = require("../assets/starter.png");
export const straightAs = require("../assets/straight_as.png");
export const upgraded = require("../assets/upgraded.png");
export const wingman = require("../assets/wingman.png");
export const closeGreen = require("../assets/close_green_icon.png");