import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles({
  root: {
    width: '100%',
    color:"red",
    '&$selected': {
        color: "white"
      },
  },
  actionItemStyles: {
    "&$selected": {
      color: "white",
      textDecoration:"underline",
      fontSize:"24px"
    }
  },
  // This is required for the '&$selected' selector to work
  selected: {},
 

});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
      style={{backgroundColor:"#336699",minHeight:"65px"}}
    >
      <BottomNavigationAction label="Terms and Conditions"  style={{
        // margin:"10px 0px",
        color:"white",
        fontSize:"22px"

      }}
      classes={{
        root: classes.actionItemStyles,
        selected: classes.selected
      }}
      onClick={()=>{localStorage.setItem("windUserFooterLink","TermsConditions");
      window.open("/Splashscreen","_blank");}}
      />
      <BottomNavigationAction
      style={{
        color:"white",
        fontSize:"22px",

      }}
      classes={{
        root: classes.actionItemStyles,
        selected: classes.selected
      }}
      onClick={()=>{
        localStorage.setItem("windUserFooterLink","Policy");
        window.open("/Splashscreen","_blank")
      }}
      label="Policy" />
      <BottomNavigationAction label="Contact us" 
      style={{
        color:"white",
        fontSize:"22px"


      }}
       classes={{
        root: classes.actionItemStyles,
        selected: classes.selected
      }}
      onClick={()=>{localStorage.setItem("windUserFooterLink","ContactUs"); window.open("/Splashscreen","_blank");}}
      />
    </BottomNavigation>
  );
}