Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.createAccountURL = "account/accounts";
exports.loginAccountURL = "login/login";
exports.urlHeaderTypeJSON = "application/json";
exports.postHttpRequest = "POST";

// Customizable Area Start
const firebaseConfig = {
  apiKey: "AIzaSyAwNQNnNBpK_HckXeql2IJh4x6dpAdRy0s",
  authDomain: "testandroid-6a719.firebaseapp.com",
  projectId: "testandroid-6a719",
  storageBucket: "testandroid-6a719.appspot.com",
  messagingSenderId: "705266628902",
  appId: "1:705266628902:web:07add8895f22224294a9e7",
  measurementId: "G-764JSDJ41N"
};
exports.fireBaseConfig = firebaseConfig;
exports.clientID =
  "705266628902-1puqj8ipqmoi9kvntom865q48re9oui8.apps.googleusercontent.com";
exports.facebookButtonText = "Sign up With Facebook";
exports.googleButtonText = "Sign up With Google";
exports.signUpButtonText = "Sign up with email and password";
exports.googleLoginAccountURL = "/bx_block_login/get_authorization"
exports.loginFacebookButtonText = "Log in With Facebook";
exports.loginGoogleButtonText = "Log in With Google";
exports.loginButtonText = "Log in with email and password";

exports.errorTitle = "Error";
exports.errorDescription = "#Error fetching data: ";

exports.signUpTitleText = "Sign up";
exports.signUpBodyText = "Value proposition: why users should sign up.";

exports.logInTitleText = "Log In";
exports.logInBodyText = "The reason why we require login.";
// Customizable Area End