// Core exception code Start
import React, { useState,useEffect } from "react";
// Core exception code End
// Customizable Area Start
import {
  makeStyles,
} from "@material-ui/core/styles";
import { Box,Grid,Typography,Slider,Modal,Button } from "@material-ui/core";
import "../css/RolesPermissionsSlider.css";
import { batteryIcon,batteryMeter,clockIcon,loadingIcon, closeModal } from "./assets";
const useStyles = makeStyles({
  modalContainer: {
    position: 'relative',
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(5px)', 
    zIndex: 1300,
  },
    batterySlider:{
        color:"white",
        "& .MuiSlider-valueLabel":{
            fontSize: "0.85rem",
            marginTop:"17px",
            height:"20px",
            width:"35px",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            border:"1px solid white",
            fontWeight:"normal",
            backgroundColor:"unset",
            color:"white",
            "&:before":{
                display:"none",
            },
            "& *":{
                backgroundColor:"transparent"
            }
        
        },
        "& .MuiSlider-thumb":{
            display:"block",
            "&:before":{
              display:"|"
            }
        }
    },
    sliderBtnCharge: {
        borderRadius: '1rem !important',
        color: "#336699 !important",
        backgroundColor:"white !important",
        fontWeight: "bold",
        textTransform: "none"
      },
      btnSubmit: {
        color: "rgba(0, 0, 0, 0.26)",
        border: "2px solid rgba(0, 0, 0, 0.26)",
        fontWeight: "bold",
        textTransform: "none",
        borderRadius: '1rem',
    },
    disabledBtnSubmit: {
        borderRadius: '1rem',
        color: "rgba(0, 0, 0, 0.26)",
        fontWeight: "bold",
        textTransform: "none",
      },


})
// Customizable Area End

const RolesPermissionsSlider = (props: any) => {
  // Customizable Area Start  
  const classes = useStyles();
  const batteryValue = props && props.lateChargeData.last_battery_percentage;
  const lateChargeData = props && props.lateChargeData;
  const [open,setOpen] = useState(true);
  const handleInfoClose = () => {
    setOpen(false)
    localStorage.setItem("windUserNotificationLink","Home")
  };
  const [enableSubmit,setEnabledSubmit] = useState(false);
  const [value,setValue] = useState<number>(0);
  useEffect(()=>{
    setValue(batteryValue);
  },[batteryValue])
  const handleSlider = (event:React.ChangeEvent<{}>, newValue:number|number[])=>{
    if (typeof newValue==='number' && newValue>=0){
      setValue(newValue);
      setEnabledSubmit(false);
    }
  }
  const submitPercentage=()=>{
    props.setLateChargePercentage(value);
   }
   const didNotCharge=()=>{
    setValue(0);
    setEnabledSubmit(true);
   }
  

  // Customizable Area End
  return (
    // Customizable Area Start
       <Box> 
        <Box className="rpBatteryDiv">
          {
           lateChargeData.late_charging_status === false && <Box className={classes.modalContainer}>
            <Modal open={open} onClose={handleInfoClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box className="infoRPBoxModal">
              <img src={closeModal} id="modal-modal-title" alt="Information" className="closeRPModalIcon" onClick={handleInfoClose} ></img>
                <img src={clockIcon} id="modal-modal-title" alt="Information" className="clockModalIcon"></img>
                
                <Typography id="modal-modal-description" className="clockModalInfo">
                  No Late Charge Recording is available.
                </Typography>
              </Box>

            </Modal>
            {open && <div className={classes.backdrop} />}
           </Box>
          } 
           {
           lateChargeData.late_night_charged && <Grid item xs={12} md={12}>
            <Modal open={open} onClose={handleInfoClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box className="infoRPBoxModal">
              <img src={closeModal} id="modal-modal-title" alt="Information" className="closeRPModalIcon" onClick={handleInfoClose} ></img>
                <img src={loadingIcon} id="modal-modal-title" alt="Information" className="loadingModalIcon"/>
                
                <Typography id="modal-modal-description" className="loadingInfo">
                  You made a commitment already, <b>and no change can be made.</b>
                </Typography>
              </Box>

            </Modal>
           </Grid>
          } 
           <Grid container className="rpNoonTitleDiv">
                <Grid item>
                    <Typography className="rpBatteryNoonTitle">Noon deadline to indicate charge</Typography>
                </Grid>
            </Grid>
           <Grid container className="rpBatteryTitleDiv">
                <Grid item xs={2} className="rpBatteryIconImgDiv">
                    <img src={batteryIcon} alt="Power Battery" className="rpBatteryIconImg"></img>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h5" display="block" className="rpBatteryIconLabel">Percentage Battery to add</Typography>
                </Grid>
           </Grid>
           <Grid container className="rpBatterySliderGrid">
                <>{console.log(batteryValue,"Batteries",value)}</>
                <Slider id="battery-slider" className={classes.batterySlider} min={0} max={90} value={value?value:0} aria-label="Small" valueLabelDisplay="on" onChange={handleSlider} ></Slider>
           </Grid>
           <Grid className="rpBatteryMeterImgDiv">
                <img src={batteryMeter} alt="Battery Meter" className="rpBatteryMeterImg"></img>
              <Grid className="rpBatteryMeterLabelValues">
                <p className="rpBatteryMeterLabel">0%</p>
                <p className="rpBatteryMeterLabel1">10</p>
                <p className="rpBatteryMeterLabel1">20</p>
                <p className="rpBatteryMeterLabel1">30</p>
                <p className="rpBatteryMeterLabel1">40</p>
                <p className="rpBatteryMeterLabel1">50</p>
                <p className="rpBatteryMeterLabel1">60</p>
                <p className="rpBatteryMeterLabel1">70</p>
                <p className="rpBatteryMeterLabel1">80</p>
                <p className="rpBatteryMeterLabel1">90</p>
              </Grid>
           </Grid>
           <Grid container className="rpBatteryButtonGrids">
           <Grid item xs={6} md={6} style={{maxWidth:"43%", marginLeft:"10px",marginRight:"10px"}}>
              <Button fullWidth className="sliderBtnChargeRP" onClick={didNotCharge}>I did not Charge</Button>
            </Grid>
            <Grid item xs={6} md={6} style={{maxWidth:"43%", marginLeft:"10px",marginRight:"10px"}}>
              <Button data-test-id="submit-battery" fullWidth className={ enableSubmit ? "disabledBtnSubmitRP" : "btnSubmitRP"} disabled={enableSubmit} onClick={submitPercentage}>Submit</Button>
            </Grid> 
            </Grid>
        </Box>
      </Box>        
        
    // Customizable Area End
  );
};

export default RolesPermissionsSlider;
