import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react'
import { userProfile } from "./assets";
import { Linking } from "react-native";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { HomeOutlined } from "@material-ui/icons";
import GroupIcon from '@material-ui/icons/Group';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ContactsIcon from '@material-ui/icons/Contacts';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import GavelIcon from '@material-ui/icons/Gavel';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  secondItemList:any;
  firstItemList: any;
  thirdItemList: any;
  mobileOpen: any;
  questionList: any;
  displayError: any;
  preview: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  faqApiCallId: string="";
  previewVideoCallId: string="";
  postLogoutId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      secondItemList: [
        { icon: <AccountBoxIcon />, label: "Profile", isSelected: true, componentName: "profile"},
        { icon: <SettingsApplicationsIcon />, label: "Notification Settings", isSelected: false, componentName: "notification" },
        { icon: <LiveHelpIcon />, label: "FQA's", isSelected: false, componentName: "faq" },
        { icon: <ContactsIcon />, label: "About us", isSelected: false, componentName: "aboutus" },
        { icon: <ContactPhoneIcon />, label: "Contact us", isSelected: false, componentName: "contactus"}
      ],
      firstItemList: [
        { icon: <HomeOutlined />, label: "Home", isSelected: false, disabled: true, },
        { icon: <NotificationsIcon />, label: "Stats", isSelected: false , disabled: true},
        { icon: <GroupIcon />, label: "Group", isSelected: false, disabled: true }
      ],
      thirdItemList: [
        { icon: <GavelIcon />, label: "Terms and Conditions", isSelected: false, disabled: true },
        { icon: <AccountBalanceIcon />, label: "Privacy Policy", isSelected: false , disabled: true},
      ],
      mobileOpen: false,
      questionList: [
        {
          label: "first",
          answer: "first answer"
        },
        {
          label: "second",
          answer: "second answer"
        },
        {
          label: "third",
          answer: "third answer"
        }
      ],
      displayError: {
        error: false,
        message: "Please try after some time."
      },
      preview: {},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
        this.getMenuItems();
      });
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetDataCallId !== "" &&
      this.apiGetDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiGetDataCallMethod(responseJson);
    
    }else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.faqApiCallId !== "" &&
      this.faqApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
     this.faqApiCallMethod(responseJson);
        
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.previewVideoCallId !== "" &&
      this.previewVideoCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.previewVideoCallMethod(responseJson);
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postLogoutId !== "" &&
      this.postLogoutId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.postLogoutMethod(responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };
  postLogoutMethod=(responseJsonData:any)=>{
    if(responseJsonData !== undefined && responseJsonData.errors === undefined){
      console.log("logout api ============> ", responseJsonData);
      localStorage.removeItem("windToken");
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }else{
      console.log("logout api error =====>", responseJsonData)
    }
  }
  previewVideoCallMethod=(responseJsonData:any)=>{
    if(responseJsonData !== undefined && responseJsonData.errors === undefined){
      console.log("preview video api ============> ", responseJsonData);
      this.setState({
        preview : responseJsonData
      });
    }else{
      this.setState({
        displayError: "Please try later"
      });
    }
  }
  faqApiCallMethod=(responseJsonData:any)=>{
    if(responseJsonData !== undefined && responseJsonData.errors === undefined){
      console.log("faq api ============> ", responseJsonData);
      this.setState({
        questionList : responseJsonData.data
      });
    }else{
      this.setState({
        displayError: "Please try later"
      });
    }
  }
  apiGetDataCallMethod=(responseJsonData:any)=>{
    if (responseJsonData) {
      this.setState({ drawerItems: responseJsonData });
    } else {
      this.parseApiErrorResponse(responseJsonData);
    }
  }
  getPreviewVideo() {
    const token = localStorage.getItem("windToken");

    const header = {
      "Content-Type": configJSON.carsApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.previewVideoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.previewVideoEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postLogout=()=>{
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.carsApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postLogoutId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/${configJSON.postLogoutEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  
  }

  getFaqs() {
    const token = localStorage.getItem("windToken");

    const header = {
      "Content-Type": configJSON.carsApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.faqApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    // this.getToken();
    // if (this.isPlatformWeb() === false) {
    //   this.props.navigation.addListener("willFocus", () => {
    //     this.getToken();
    //   });
    // }
    this.getFaqs();
    this.getPreviewVideo();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = localStorage.getItem("windToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  // Customizable Area End
}
