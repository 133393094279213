import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import OnboardingOne from "./OnboardingOne.web";
import OnboardingTwo from "./OnboardingTwo.web";
import OnboardingThree from "./OnboardingThree.web";
import OnboardingFour from "./OnboardingFour.web";
import OnboardingFive from "./OnboardingFive.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentComponent:number;
  components:any[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OnboardingguideController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      currentComponent:0,
      components:[OnboardingOne,OnboardingTwo,OnboardingThree,OnboardingFour,OnboardingFive],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
  }

  // Customizabele Area Start
  skipOnboarding() {
    const msg: Message = new Message(getName(MessageEnum.OnboardingGuideControllerSkipOnboarding));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  exitOnboarding() {
    const msg: Message = new Message(getName(MessageEnum.OnboardingGuideControllerDoneOnboarding));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    console.log("Setted value true");
    localStorage.setItem("windOnboardingDone",JSON.stringify(true));
    console.log(localStorage.getItem("windOnboardingDone"));
    this.props.navigation.navigate("WebNavigationMenu");
  }
  // Customizable Area End
}
