import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Table, TableBody,TableCell,TableRow, Paper,
  Modal
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import "../css/GamificationWeb.css";
import { closeGreen,camel,discriminating,evangelist,fullyCharged,hardCharger,horticulturalist,madeYourOwnLuck,overachiever,redemeed,roll,starter,straightAs,upgraded,wingman } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import GamificationController, {
  Props,
  configJSON,
} from "./GamificationController";

export default class Gamification extends GamificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   badges:any[] = [
    camel,discriminating,evangelist,fullyCharged,hardCharger,horticulturalist,madeYourOwnLuck,overachiever,redemeed,roll,starter,straightAs,upgraded,wingman
  ]
  handleMessageClose = ()=>{
    this.setState({
      openMessage:false
    })
  }
  showBadgeInfo=(badge:any)=>{
    console.log("This is badge Info", badge.attributes);
    console.log("badge",badge,badge.attributes.unlocked);
    this.setState({
      badgeInfo:badge.attributes.unlocked,
      oneBadge:badge,
      openMessage:true
    })
  }
  renderBadgeRowOne(){
    console.log("These are badges",this.state.badgeList)
    const badgeList = this.state.badgeList["data"]?.slice().splice(0,7).map((badge:any)=>{
      console.log(badge);  
      return (   
          <>
                <TableCell className="tableCells">
                  <img src={badge.attributes.image} className="tableBadgeImage" onClick={()=>this.showBadgeInfo(badge)} />
                </TableCell>        
          </>
        )
    })    
    return badgeList;
  }
  renderBadgeRowTwo(){
    console.log("These are badges in 2",this.state.badgeList)
    const badgeList = this.state.badgeList["data"]?.slice().splice(-7).map((badge:any)=>{
      return (
                <TableCell className="tableCells">
                  <img src={badge.attributes.image} className="tableBadgeImage" onClick={()=>this.showBadgeInfo(badge)}/>
                </TableCell> 
      )
    })    
    return badgeList;
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Paper
      elevation={3}
      className={ this.props.cO2Tab ? "gamificationCO2Paper" : "gamificationPaper" }
    >
        <Container maxWidth={"sm"} className={ this.props.cO2Tab ? "gamificationCO2Container" : "gamificationWebContainer" }>
            { 
             this.state.badgeInfo && this.state.openMessage ? (
             <Grid item xs={12} md={12}>
              <Modal open={this.state.openMessage} onClose={this.handleMessageClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="badgeUnlockedMessage">
                <img src={closeGreen} id="modal-modal-title" alt="Information" className="closeBadgeMessageIcon" onClick={this.handleMessageClose} ></img>
                  <img src={this.state.oneBadge?.attributes?.image} id="modal-modal-title" alt="Information" className="badgeUnlockedIcon"></img>  
                  <Typography id="modal-modal-title" className="badgeUnlockedTitleInfo" variant="h6" >
                    {this.state.oneBadge?.attributes?.title}
                  </Typography>
                    <hr className="titleUnlockedHR"/>
                  <Typography id="modal-modal-description" className="badgeUnlockedInfo" variant="body2">
                    {this.state.oneBadge?.attributes?.description}
                  </Typography>
                </Box>
              </Modal>
             </Grid>
            )
             : 
             (
             <Grid item xs={12} md={12}>
              <Modal open={this.state.openMessage} onClose={this.handleMessageClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="badgeLockedMessage">
                <img src={closeGreen} id="modal-modal-title" alt="Information" className="closeBadgeMessageIcon" onClick={this.handleMessageClose} ></img>
                   <img src={this.state.oneBadge?.attributes?.image} id="modal-modal-title" alt="Information" className="badgeLockedIcon"></img>  
                  <Typography id="modal-modal-description" className="badgeLockedTitle" variant="h6">
                    {this.state.oneBadge?.attributes?.title}
                  </Typography>
                </Box>
              </Modal>
             </Grid>
             )
          }
            {
              this.props.cO2Tab ?
              (
                <Grid container>
              <Grid item xs={6} md={6} className="cO2TabDetailsGrid">
              <Typography variant="h6" className="badgeGamificationHeading">Badges</Typography>
              <hr className="badgeCO2Ruler"></hr>
              <Typography variant="body1" className="badgeCO2Description"> As you acheive trickier charging milestones, watch your badges rack up. </Typography>
              </Grid>
              <Grid item xs={6} md={6} className="cO2TableGrid">
              <Table className={ (this.props.cO2Tab )? "badgeTable":""}>
                <TableBody>
                  <TableRow className="tableRow1">
                    { this.renderBadgeRowOne() }
                  </TableRow>
                  <TableRow  className="tableRow2">
                    { this.renderBadgeRowTwo() }
                  </TableRow>
                </TableBody>
              </Table>
              </Grid>
            </Grid>
              ) :
              (<Grid>
                <Typography variant="h6" className="badgeGamificationHeading">Badges</Typography>
                <hr className="badgeRuler"></hr>
                <Typography variant="body1" className="badgeDescription"> As you acheive trickier charging milestones, watch your badges rack up. </Typography>
                <Table className="simpleBadgeTable">
                  <TableBody >
                    <TableRow className="tableRow1">
                      { this.renderBadgeRowOne() }
                    </TableRow>
                    <TableRow  className="tableRow2">
                      { this.renderBadgeRowTwo() }
                    </TableRow>
                  </TableBody>
                </Table>
                </Grid>)
            }
        </Container>
        </Paper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
