export const TABS = [
    {name: '30 Days',  id: 0 , analytics: '30 days'},
    {name: '13 Weeks',  id: 1, analytics: '13 weeks'},
    {name: '1 Year',  id: 2 , analytics: '1 year'},
   
  ]

export const COTABS = [
    {name: '30 Days',  id: 0, params: '30 days'},
    {name: '13 Weeks',  id: 1, params: '13 weeks'},
    {name: '1 Year',  id: 2, params: '1 year'},
   
  ]