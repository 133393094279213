import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import RolesPermissionsFleetStatus from "./RolesPermissionsFleetStatus.web";
import RolesPermissionsSlider from "./RolesPermissionsSlider.web";
import "../css/RolesPermissions2.css";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import RolesPermissions2Controller, {
  Props,
  configJSON,
} from "./RolesPermissions2Controller";

export default class RolesPermissions2 extends RolesPermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className="rolesPermissionDashboard">
        
        <Box sx={{ flexGrow:1 }} className="rolePermissionMainBox"> 
          <Typography variant="h5" className="rolesPermissionHeading">
            Late Charge Recording
          </Typography>
          <Grid container spacing={2} className="rolesPermissionsGrid">
            <Grid item md={6} sm={12} xs={12}>
              <RolesPermissionsFleetStatus fleetStatus={ this.state.fleetStatus }/>
            </Grid>
            <Grid item md={6} sm={12} xs={12} style={{marginTop:"20px"}}>
              <RolesPermissionsSlider lateChargeData={this.state.lateChargeData} setLateChargePercentage={this.setLateChargePercentage}/>
            </Grid>
          </Grid>
        </Box>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
