Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.videoLinkAPIEndPoint = "bx_block_contact_us/preview_videos/preview_video_list";
exports.termsAndConditionEndPoint = "bx_block_contact_us/privacy_policies/terms_and_condition_list_page";
exports.privacyPolicyEndPoint = "bx_block_contact_us/privacy_policies/privacy_policies_list_page";
exports.contactUSEndPoint = "bx_block_contact_us/contacts/contact_use_landing";
exports.faqEndPoint = "bx_block_help_centre/question_answer/list_faqs_landing";
exports.httpPostMethod = "POST";
exports.faqGetMethod = "GET";
exports.termsAndConditionMethod = "GET";
exports.privacyPolicyMethod = "GET";
exports.videoLinkAPIMethod = "GET";
exports.landingPageApiContentType = "application/json";
// Customizable Area End