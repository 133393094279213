import React from "react";
import {
    makeStyles,
  } from "@material-ui/core/styles";
  import { Container,Grid,Typography,Link } from "@material-ui/core";
import { onboardingFiveIcon,batteryIcon,lateChargeIcon,redArrowIcon,lateChargeBarIcon,circleTopLeft,topRightHalfIcon,windEverest} from "./assets";
  const useStyles = makeStyles({
      
  })
import "../css/OnboardingFiveWeb.css"
const navigateToPages=(navItem:string)=>{
    if(navItem.includes("termsandconditions")){
     localStorage.setItem("windUserNotificationLink","TermsConditions");
    }
    else if(navItem.includes("privacypolicy")){
      localStorage.setItem("windUserNotificationLink","PrivacyPolicy");
    }
    else if(navItem.includes("contactus")){
        localStorage.setItem("windUserNotificationLink","ContactUs");
    }
  }
// Customizable Area Start
const OnboardingFive = ()=>{
    return (
        <Container className="OnboardingFiveContainer">
            <Grid container className="topRowGrid">
                <Grid item md={3} className="logoGridFive">
                    <img src={circleTopLeft} className="circleTopLeftImgFive"/>
                    <img src={windEverest} className="windEverestLogoFive"/>
                </Grid>
                <Grid item md={6} className="infoGrid">
                    <Grid container>
                       <Grid item md={1} className="infoGridIconFive">
                            <img src={redArrowIcon} className="arrowIconsFive"/>
                        </Grid>
                        <Grid item md={11}>
                            <Typography className="arrowOneInfoFive">Like the normal "Commit", you are selecting the percentage you added to your battery (not complete state of charge). If you did not charge, select that option. It will help you complete a badge or two.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} className="rightIconGrid">
                    <img src={topRightHalfIcon} className="rightCircleIconFive" />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={6}>
                    <img src={onboardingFiveIcon} className="laptopImageFive"></img>
                </Grid>
                <Grid item md={3} className="popupBatteryGridFive">
                    <img src={lateChargeIcon} className="popupBatteryPanelGridFive"></img>
                    <img src={batteryIcon} className="batteryIcon"/>
                    <Typography variant="h6" className="batteryIconHeading">Percent Battery to add</Typography>
                    <img src={lateChargeBarIcon} className="batteryMeterIcon"></img>
                    <Grid container className="batteryMeter">
                    <p className="batteryMeterIconLabel">0%</p>
                    <p className="batteryMeterIconLabel1">10</p>
                    <p className="batteryMeterIconLabel1">20</p>
                    <p className="batteryMeterIconLabel1">30</p>
                    <p className="batteryMeterIconLabel1">40</p>
                    <p className="batteryMeterIconLabel1">50</p>
                    <p className="batteryMeterIconLabel1">60</p>
                    <p className="batteryMeterIconLabel1">70</p>
                    <p className="batteryMeterIconLabel1">80</p>
                    <p className="batteryMeterIconLabel1">90</p>
                    </Grid>
                </Grid>
                <Grid container className="onBoardingFiveLowerInfoGrid">
                       <Grid item md={1} className="onBoardingFiveLowerInfo">
                            <img src={redArrowIcon} className="onBFiveArrowLowerIcon"/>
                        </Grid>
                        <Grid item md={11}>
                            <Typography className="onBFiveArrowSecondInfo">Missed commiting to a charge? You still have time. Update what you did though the 'Late Charge Recording' menu.</Typography>
                        </Grid>
                </Grid>
            </Grid>
            <Grid container className="footerOnBoardingOddFive">    
            <Grid item md={4} className="typographyTACGrid">
                <Link variant="body1" className="typographyTAC" href="/WebNavigationMenu" onClick={()=>navigateToPages("termsandconditions")}>Terms and Conditions</Link>
            </Grid>
            <Grid item md={4} className="typographyPolicyGrid">
                <Link variant="body1" className="typographyPolicy" href="/WebNavigationMenu" onClick={()=>navigateToPages("privacypolicy")}>Policy</Link>
            </Grid>
            <Grid item md={4} className="typographyContactGrid">
                <Link variant="body1" className="typographyContact" href="/WebNavigationMenu" onClick={()=>navigateToPages("contactus")}>Contact us</Link>
            </Grid>
        </Grid>            
        </Container>
    )
}
// Customizable Area End
export default OnboardingFive;