import React from "react";
// Customizable Area Start
import { Container, Box,Typography,Divider} from "@material-ui/core";
import "../css/LandingPage4.css";
const classes: any = {
    root: {
        borderRadius: 30,
      },
      container: {
        width: "100%",
      },
      box4:{
        width: "100%",
        marginLeft: "10%",
        marginTop: "12%",
        marginBottom: "10%", 
      },
      heading: {
        color: "rgb(51, 102, 153)",
        fontWeight: "bolder",
        marginTop: "15%",
        marginLeft: "27%",
        marginBottom: "4%",
        fontSize: "2.45rem",
      },
      divider: {
        marginTop: 10,
        marginBottom: 60,
        backgroundColor: "#336699",
        width: "80%",
        height:"2px",
      },
      description: {
        display:"inline-block",
        width:"80%",
        textAlign: "justify",
        marginTop: 10,
        marginBottom: 10,
        marginRight: 25
      },
      headingDates:{
        textAlign: "center",
        marginTop: "-4%",
        marginBottom: "4%",
        color: "#369",
        marginLeft: "-18%",
      }
  }
  
// Customizable Area End

const SplashscreenLanding4 = (props: any) => {
  // Customizable Area Start
  const renderTermsList=()=>{
    const termsListItems = props.termsAndConditions && props.termsAndConditions.map((item:any)=>{
     return (
       <Typography style={classes.description}>{item}</Typography>
     )
   })
   return termsListItems;  
 }
  // Customizable Area End
  return (
    // Customizable Area Start
    <Container maxWidth="lg" className="landingPage4Container">
          <Box style={classes.box4}>
          <Typography className="tcHeading" variant="h5">
            Terms and Conditions
          </Typography>
          <Typography variant="body2" style={classes.headingDates}>{props.termsStartDate} / {props.termsEndDate}</Typography>
          <Divider light={false}  style={classes.divider} variant="fullWidth"/>
          {renderTermsList()}
          </Box>
    </Container>
    // Customizable Area End
  );
};

export default SplashscreenLanding4;
