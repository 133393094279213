import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  policyList: any;
  policyStartDate:string;
  policyEndDate:string;
  termsStartDate:string;
  termsEndDate:string;
  termsList:any;
  isPolicyAccepted:boolean;
  isTermsAccepted:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  privacyPolicyApiCallId: string;
  // Customizable Area Start
  termsAndConditionApiCallId:string;
  acceptPolicyCallId:string;
  acceptTermsCallId:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
      this.privacyPolicyApiCallId = "" ;
      this.termsAndConditionApiCallId = "";
      this.acceptPolicyCallId = "";
      this.acceptTermsCallId = "";
      this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      policyList: [],
      policyStartDate:"",
      policyEndDate:"",
      termsStartDate:"",
      termsEndDate:"",
      termsList:[],
      isTermsAccepted:false,
      isPolicyAccepted:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson && apiRequestCallId && responseJson !== undefined && responseJson.errors === undefined){
        if(apiRequestCallId === this.privacyPolicyApiCallId){
            let policyListArray:any = responseJson?.data?.map((item:any)=>{
               return  item?.attributes?.description  
            })
            console.log("policy response 1 ------------------> ", policyListArray.map((elem:string)=>{console.log(elem)}));
            this.setState({
              policyList: policyListArray,
              policyStartDate: responseJson?.data?.map((item:any)=>{ return  item?.attributes?.start_date}),
              policyEndDate: responseJson?.data?.map((item:any)=>{ return  item?.attributes?.end_date}),
            });
            this.setState({
              isPolicyAccepted:responseJson?.is_accepted
            })
            console.log("policy response ------------------> ", responseJson);
        }
        else if(apiRequestCallId === this.termsAndConditionApiCallId){
            let termsListArray:any = responseJson?.data?.map((item:any)=>{
               return  item?.attributes?.description  
            })
            console.log("policy response 1 ------------------> ", termsListArray);
            this.setState({
              termsList: termsListArray,
              termsStartDate: responseJson?.data?.map((item:any)=>{ return  item?.attributes?.start_date}),
              termsEndDate: responseJson?.data?.map((item:any)=>{ return  item?.attributes?.end_date}),
            });
            this.setState({
              isTermsAccepted:responseJson?.is_accepted
            })
            console.log("policy response ------------------> ", responseJson);
        }
        else if(apiRequestCallId === this.acceptPolicyCallId){
              this.setState({
                isPolicyAccepted:true
              })
              this.props.navigation.navigate("WebNavigationMenu")
        
          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if(apiRequestCallId === this.acceptTermsCallId){
              this.setState({
                isTermsAccepted:true
              })
              this.props.navigation.navigate("WebNavigationMenu")
        
              this.parseApiCatchErrorResponse(errorReponse);
        }
       else {
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    super.componentDidMount();
    await this.privacyPolicyApiCall();
    await this.termsAndConditionApiCall();
  }

  privacyPolicyApiCall() {
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPolicyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_contact_us/privacy_policies/privacy_policy_accepted_or_not`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  termsAndConditionApiCall() {
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsAndConditionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/bx_block_contact_us/privacy_policies/term_and_condition_accepted_or_not`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  declinePolicy=()=>{
    this.props.navigation.navigate("WebNavigationMenu")
  }
  acceptPolicy=()=> {
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptPolicyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/${configJSON.acceptPolicyURL}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  declineTerms=()=>{
    this.props.navigation.navigate("WebNavigationMenu")
  }
  acceptTerms=()=> {
    const token = localStorage.getItem("windToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptTermsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/${configJSON.acceptTermsURL}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  // Customizable Area End
}
