import React from "react";
import "../css/forgetPassword.css";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
const MyTeme = createTheme({
  palette: {
    primary: {
      main: "#006633",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { forgetposter, googleIcon, Logo, sideCover } from "./assets";
import CoustomTextInput from "../../email-account-registration/src/CoustomTextInput.web";
import TransitionsModal from "./Alter.web";
import SimpleBottomNavigation from "../../dashboard/src/Footer/index.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class WebForgetPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  componenetName = "forgetPassword";

  async componentDidMount() {
    super.componentDidMount();
    console.log(localStorage.getItem("windUser"), " ------------------------> ");
    if (this.componenetName === "forgetPassword") {
      
      if(localStorage.getItem("windUser") !== undefined && localStorage.getItem("windUser") !== null){
        this.getHiddenEmail();
        const timeout = setTimeout(() => {
          this.goToOtpAfterEmailValidation({ accountType: "", email: "" });
          clearTimeout(timeout);
        }, 5000);
      }
    }
  }

  setOpen = (modalState: boolean) => {
    this.setState({
      open: modalState
    });
  }
  // Customizable Area End
  // Customizable Area Start

  getHiddenEmail = () => {
    const hiddenEmail = JSON.parse(localStorage.getItem("windUser")!).data.attributes.email;
    let index = hiddenEmail.indexOf("@");
    let newemail = hiddenEmail.substring(0, 2);
    for (let i = 1; i <= index - 2; i++) {
      newemail += "*";
    }
    newemail += hiddenEmail.substring(index);
    this.setState({
      hiddenEmail: newemail
    });
  }

  updateEmail = (value: string) => {
    this.setState({
      emailValue: value
    });
  }
  emailValidater = (setShowError: any, value?: string) => {
    const pattren = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if(!pattren.test(this.state.emailValue) && !pattren.test(value!)){
        this.setState({
          emailError: true
        });
        this.setState({
          emailErrorMsg: "Email is not valid, please enter correct email pattren example@domain.com"
        });
      }
      else if(value && value.trim()===""){
        this.setState({
          emailError:true,
          emailErrorMsg: "Email field cannot be blank, invalid format"
        })
      }
      else{
        this.setState({
          emailError: false,
          emailErrorMsg:""
        });
      }
  }

  // Customizable Area End

  render() {
console.log(this.state.emailError, this.state.emailErrorMsg,"check")

    return (
      // Required for all blocks
      // Customizable Area Start
      <ThemeProvider theme={MyTeme}>

        <div className="container" style={{backgroundColor:"white"}}>
          <div className="header">
            <img data-test-id="imagelogo" src={Logo} alt="" className="logoImg" onClick={() => {this.props.navigation.navigate("Home")}}/>
          </div>
          <div className="bodyContainer">
            <img data-test-id="forgetPosterLogo" src={forgetposter} style={{marginBottom:"-15px"}}alt="" />
            <Typography variant="h6" style={{ fontWeight: "bold", color: "#336699", marginBottom:"10px" }}>Forgot your Password?</Typography>
            <Typography variant="body2" style={{ marginBottom: 0 }} >Dont worry!</Typography>
            <Typography variant="body2" style={{ marginBottom: "10px" }}>We will help you recover your password</Typography>
            <hr style={{marginBottom: "10px"}}/>
            {localStorage.getItem("windUser") !== null && <><Typography data-test-id="checkEmailId" variant="h6" style={{ fontWeight: "bold", color: "#336699" }}>Check your email</Typography>

            <Typography variant="body2" style={{ marginBottom: 0 }}>We will send a new password to your email</Typography>
            <Typography variant="body2" style={{ marginBottom: 0 }}>{this.state.hiddenEmail}</Typography></>}
            {(JSON.parse(localStorage.getItem("windUser")!)?.message ||  JSON.parse(localStorage.getItem("windUser")!) == null )&& <><div style={{ width: "300px", marginBottom: "10px", marginTop: "10px" }}>
           
              <CoustomTextInput
                label={"Email*"}
                value={this.state.emailValue}
                updateText={this.updateEmail}
                validater={this.emailValidater}
                errorMssage={"email is not valid, please enter correct email pattren example@domain.com"}
                showError={this.state.emailError}
                alterMsg={this.state.emailErrorMsg}
              />
            </div>
            <Button 
            data-test-id="resetpasswordbtnid"
            style={{ color: "white!important", borderRadius: 20, textTransform: "none" }} variant="contained" color="primary"
              onClick={() => this.goToOtpAfterEmailValidation({ accountType: "", email: this.state.emailValue })}
              disabled={this.state.emailValue === ""}
              >
              Reset Password
            </Button></>}
          </div>
        </div>
        <TransitionsModal data-test-id="transitionModalId" open={this.state.open} setOpen={this.setOpen} navigation={this.props.navigation}  message={this.state.errorResponse}  type={"Error"}/>
        <SimpleBottomNavigation/>
      
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
