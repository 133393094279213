import React from "react";
// Customizable Area Start
import { Container, Box,Typography,Grid } from "@material-ui/core";
import { dashboardMobile,extraFeatureMobile,groupsMessagingLine,groupsMessagingMobile,personalStatsMobile } from "./assets";
import "../css/LandingPage1.css";
// Customizable Area End

const SplashscreenLanding1 = (props: any) => {
  // Customizable Area Start
  // Customizable Area End
  return (
    // Customizable Area Start
    <Container maxWidth="lg" className="landingPage1Container">
        <Box>
            <iframe className="videoCard" src={props?.videoLink} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            <Typography variant="h6" className="pageTitleWorks" id="howItWorks">How it Works</Typography>
            <Grid container className="dashboardDetails">
                <Grid item xs={6} md={6} className="dashboardDetailsImage">
                    <img src={dashboardMobile} className="dashboardMobileImage"></img>
                </Grid>
                <Grid item xs={6} md={6} className="dashboardDetailsInfo">
                    <Typography variant="h6" className="windAtGlanceHeading">Wind at a glance</Typography>
                    <Typography variant="body1" className="windAtGlanceInfo1">
                        The wind is fickle. Plus, the wind turbines that deliver your power could be in a windy area 500 miles from you.
                    </Typography>
                    <Typography variant="body2" className="windAtGlanceInfo2">
                        WindEverest boils this down to a nightly list of wind generation on a simple A,B,C grading system. No more questions!
                    </Typography>
                    <Typography variant="body2" className="windAtGlanceInfo3">
                        See how your peers are signing up to a charge. Or just skip the fossil burners, Don't be in the dark
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className="personalStatDetails">
                <Grid item xs={6} md={6} className="personalStatDetailsInfo">
                    <Typography variant="h6" className="personalStatsHeading">Personal Stats</Typography>
                    <Typography variant="body1" className="personalStatsInfo1">
                        Track how much you did last week, last month, and last year.
                    </Typography>
                    <Typography variant="body2" className="personalStatsInfo2">
                        WindEverest lets you track your effort and track your carbon efficiency. Plus, you can see how you do against common benchmarks. Are you good? Are you bad ? Are you getting better? Find out here. 
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6} className="personalStatDetailsImage">
                    <img src={personalStatsMobile} className="personalStatMobileImage"></img>
                </Grid>
            </Grid>
            <Grid container className="groupsMessageDetails">
                <Grid item xs={6} md={6} className="groupsMessageDetailsImage">
                    <img src={groupsMessagingMobile} className="groupsMessageImage"></img>
                </Grid>
                <Grid item xs={6} md={6} className="groupsMessageDetailsInfo">
                    <Typography variant="h6" className="groupsMessageHeading">Groups and messaging</Typography>
                    <Typography variant="body1" className="groupsMessageInfo1">
                        One person acting alone is futile. Vast groups working in unison is progress.
                    </Typography>
                    <Typography variant="body2" className="groupsMessageInfo2">
                        See how your peers are performing and figure out if your group has bragging rights over another. Do Tesla owners charge better than Ford owners? Find out here.
                    </Typography>
                    <img src={groupsMessagingLine} className="groupMessageLine"></img>
                </Grid>
            </Grid>
            <Grid container className="extraFeatureDetails">
                <Grid item xs={6} md={6} className="extraFeatureDetailsInfo">
                    <Typography variant="h6" className="extraFeatureHeading">Extra features</Typography>
                    <Typography variant="body1" className="extraFeatureInfo1">
                        Use WindEverest how you like.
                    </Typography>
                    <Typography variant="body2" className="extraFeatureInfo2">
                        Either jump in quick to commit to a charge or dive deep to earn badges and share knowledge WindEverest gives you the power to charge. to improve to deliver to our kids a livable planet.
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6} className="extraFeatureDetailsImage">
                    <img src={extraFeatureMobile} className="extraFeatureMobileImage"></img>
                </Grid>
            </Grid>
        </Box>
    </Container>
    // Customizable Area End
  );
};

export default SplashscreenLanding1;
