import React from "react";
import {
    makeStyles,
} from "@material-ui/core/styles";
import { Container, Grid, Typography,Link } from "@material-ui/core";
import { bottomLeftCircle,infoIcon,batteryIcon,batteryMeter, onboardingTwoIcon, batteryPanel, redArrowIcon, circleTopLeft, greenTopRightCircle, windEverest } from "./assets";
const useStyles = makeStyles({

})
import "../css/OnboardingTwoWeb.css";
const navigateToPages=(navItem:string)=>{
    if(navItem.includes("termsandconditions")){
     localStorage.setItem("windUserNotificationLink","TermsConditions");
    }
    else if(navItem.includes("privacypolicy")){
      localStorage.setItem("windUserNotificationLink","PrivacyPolicy");
    }
    else if(navItem.includes("contactus")){
        localStorage.setItem("windUserNotificationLink","ContactUs");
    }
  }
const OnboardingTwo = () => {
    return (
        <Container className="OnboardingTwoContainer">
            <Grid container className="topRowGrid">
                <Grid item md={3} className="logoGridTwo">
                    <img src={circleTopLeft} className="circleTopLeftImgTwo" />
                    <img src={windEverest} className="windEverestLogoTwo" />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={3}>
                  <Grid container>
                       <Grid item md={1} className="onBoardingTwoInfoTwo">
                            <img src={redArrowIcon} className="onBTwoArrowIconTwo"/>
                        </Grid>
                        <Grid item md={11}>
                            <Typography className="onBTwoArrowInfo">Best guess of the percent battery you expect to add. If battery starts at 70% and completes at 90%, then slide to 20%.</Typography>
                        </Grid>
                    </Grid>
                    <img src={bottomLeftCircle} className="circleBottomLeftTwo"></img>
                </Grid>
                
                <Grid item md={6} className="laptopGridTwo">
                    <img src={onboardingTwoIcon} className="laptopImageTwo"></img>
                    <img src={greenTopRightCircle} className="greenTopRightCircleTwo"></img>
                </Grid>
            
                <Grid item md={3} className="popupBatteryGridTwo">
                    <img src={batteryPanel} className="popupBatteryPanelGridTwo"></img>
                    <img src={infoIcon} className="batteryInfoIconTwo"></img>
                    <img src={batteryIcon} className="batteryIconTwo"/>
                    <Typography variant="h6" className="batteryIconHeadingTwo">Percent Battery to add</Typography>
                    <img src={batteryMeter} className="batteryMeterIconTwo"></img>
                    <Grid container className="batteryMeterTwo">
                    <p className="batteryMeterIconLabel">0%</p>
                    <p className="batteryMeterIconLabel1">10</p>
                    <p className="batteryMeterIconLabel1">20</p>
                    <p className="batteryMeterIconLabel1">30</p>
                    <p className="batteryMeterIconLabel1">40</p>
                    <p className="batteryMeterIconLabel1">50</p>
                    <p className="batteryMeterIconLabel1">60</p>
                    <p className="batteryMeterIconLabel1">70</p>
                    <p className="batteryMeterIconLabel1">80</p>
                    <p className="batteryMeterIconLabel1">90</p>
                    </Grid>
                </Grid>
                <Grid container className="onBoardingTwoLowerInfoGridTwo">
                       <Grid item md={1} className="onBoardingTwoLowerInfo">
                            <img src={redArrowIcon} className="onBTwoArrowLowerIcon"/>
                        </Grid>
                        <Grid item md={11}>
                            <Typography className="onBTwoArrowSecondInfoTwo">The community choices, summed up. You can revise your charge status, if you forgot last night. Use the "More" button to navigate to "Late Charge Recording".</Typography>
                        </Grid>
                </Grid>
            </Grid>
        <Grid container className="footerOnBoardingEvenTwo">    
        <Grid item md={4} className="typographyTACGrid">
                <Link variant="body1" className="typographyTACEven" href="/WebNavigationMenu" onClick={()=>navigateToPages("termsandconditions")}>Terms and Conditions</Link>
            </Grid>
            <Grid item md={4} className="typographyPolicyGrid">
                <Link variant="body1" className="typographyPolicyEven" href="/WebNavigationMenu" onClick={()=>navigateToPages("privacypolicy")}>Policy</Link>
            </Grid>
            <Grid item md={4} className="typographyContactGrid">
                <Link variant="body1" className="typographyContactEven" href="/WebNavigationMenu" onClick={()=>navigateToPages("contactus")}>Contact us</Link>
            </Grid>
        </Grid>
        </Container>
    )
}
export default OnboardingTwo;