import React, { SetStateAction, useEffect, useState } from "react";
// Customizable Area Start
// import CssBaseline from "@material-ui/core/CssBaseline";
import axios from 'axios';
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Badge from '@material-ui/core/Badge';
import Groups from "../../Groups/src/Groups.web";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { HomeOutlined } from "@material-ui/icons";
import GroupIcon from '@material-ui/icons/Group';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ContactsIcon from '@material-ui/icons/Contacts';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import GavelIcon from '@material-ui/icons/Gavel';
import Avatar from '@material-ui/core/Avatar';
import { aboutIcon, bellIcon, chargeRecordingIcon, contactusIcon, faqIcon, groupIcon, homeIcon, Logo, notificationIcon, privacyIcon, profileIcon, statIcon, termConditionIcon } from "./assets";
import Profile from "../../user-profile-basic/src/Profile.web";
import WebAddContactUs from "../../contactus/src/WebAddContactUs.web";
import { ListItem } from "react-native-elements";
import NavigationMenuController, {
    Props,
    configJSON,
  } from "./NavigationMenuController";
import TermsAndConditions from "../../TermsAndConditions/src/TermsAndConditions.web";
import PreviewVideo from "./PreviewVideo.web"
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import Faq from './Faq.web'
import FaqScreen from "./FaqScreen.web";
import PrivacyPolicy from "../../TermsAndConditions/src/PrivacyPolicy.web";
import Dashboard from "../../dashboard/src/Dashboard";
import VisualAnalytics from "../../visualanalytics/src/VisualAnalytics.web";
import RolesPermissions2 from "../../RolesPermissions2/src/RolesPermissions2.web";
import NotificationSettings from "../../notificationsettings/src/Notificationsettings.web";
import Notifications from "../../notifications/src/Notifications.web";
const drawerWidth = 300;



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      
      overflowX: "hidden"
    },
    drawer: {
      //[theme.breakpoints.up("sm")]
      sx: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      backgroundColor: "#f8f8f8",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]:{
      // sx: {
        display: "none"
      // }
    }
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      }
    },
    bellButton: {
      marginLeft: "auto",
      marginRight: "30px",
      cursor:"pointer",
      transform: "rotate(0deg)",
    },
    logoButton: {
      margin: 20,
      widht: "100",
    },
    divider: {
      marginTop: 20,
      marginBottom: 20
    },
    silbarContainer: {
      overflowX: "hidden"
    },
    customizeToolbar: {
      minHeight: 24
    }
  })
);

// Customizable Area End



const routeMap = {
  Profile: {
        component: Profile,
        path: '/Profile'
    }
}

export default function ResponsiveDrawer(props: Props) {
  // Customizable Area Start
  const  window  = document.body;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const unreadCount = JSON.parse(localStorage.getItem("unreadCount")!);
  const [firstItemList, setFirstItemList] = React.useState([
    { icon: homeIcon, label: "Home", isSelected: true, disabled: false, id: 1 },
    { icon: statIcon, label: "Stats", isSelected: false , disabled: false, id: 2},
    { icon: groupIcon, label: "Groups", isSelected: false, disabled: false, id: 3}
  ]);

  const [secondItemList, setSecondItemList] = React.useState([
    { icon: profileIcon, label: "Profile", isSelected: false, id: 4 },
    { icon: notificationIcon, label: "Notification Settings", isSelected: false, id: 5 },
    { icon: chargeRecordingIcon, label: "Late Charge Recording", isSelected: false, id: 6 },
    { icon: faqIcon, label: "FAQ's", isSelected: false,  id: 7 },
    { icon: aboutIcon, label: "About us", isSelected: false, id: 8 },
    { icon: contactusIcon, label: "Contact us", isSelected: false, id:9 }
  ]);

  const [thirdItemList, setThirdItemList] = React.useState([
    { icon: termConditionIcon, label: "Terms and Conditions", isSelected: false, disabled: true, id: 10 },
    { icon: privacyIcon, label: "Privacy Policy", isSelected: false , disabled: true, id: 11},
  ]);
  const handleDrawerToggle = () => {
    if(window.offsetWidth<600){
      setMobileOpen(!mobileOpen);
    }
      
  };

  const [fetchComponent, setFetchComponent] = useState<SetStateAction<any>>(1);

  const defaultProps = {
    color: 'secondary' as 'secondary',
    children: <img style={{transform: "rotate(0deg)", width: "20px"}} src={bellIcon} alt=""/>,
  };

  useEffect(() => {
    if(localStorage.getItem('windUser') === null || localStorage.getItem("windToken") === null){
      props.navigation.navigate("EmailAccountLoginBlock");
    }
    else{
      props.navigation.navigate("WebNavigationMenu");
                 
    }
  }, []);
  const setNavigationFirstItem=(index:any)=>{
    const updateList = [...firstItemList];
    setFirstItemList(updateList.map((listItem, id) => {
      return {
        ...listItem,
        isSelected: index === id ? true: false,
      }
    }));
    const secondupdatelist = [...secondItemList];
    setSecondItemList(secondupdatelist.map((listItem, id) => {
      return {
        ...listItem,
        isSelected: false
      }
    }));
    const thirdItemListupdate = [...thirdItemList];
    setThirdItemList(thirdItemListupdate.map((listItem, id) => {
      return {
        ...listItem,
        isSelected: false
      }
    }));
    handleDrawerToggle();
  }
  const setNavigationSecondItem=(index:any)=>{
    const updateList = [...secondItemList];
              setSecondItemList(updateList.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: index === id ? true: false,
                }
              }));
              const firstItemUpdateList = [...firstItemList];
              setFirstItemList(firstItemUpdateList.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: false,
                }
              }));
              const thirdItemListupdate = [...thirdItemList];
              setThirdItemList(thirdItemListupdate.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: false
                }
              }));
              handleDrawerToggle();
  }
  const setNavigationThirdItem=(index:any)=>{
    const updateList = [...thirdItemList];
              setThirdItemList(updateList.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: index === id ? true: false,
                }
              }));
              const firstItemUpdateList = [...firstItemList];
              setFirstItemList(firstItemUpdateList.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: false,
                }
              }));
              const secondupdatelist = [...secondItemList];
              setSecondItemList(secondupdatelist.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: false
                }
              }));
              handleDrawerToggle();
  }
  useEffect(()=>{
    const interval = setInterval(()=>{
      if(localStorage.getItem("windUserNotificationLink")==="Home"){
      setFetchComponent(1);
      setNavigationFirstItem(0);
      localStorage.removeItem("windUserNotificationLink");
    }
    else if(localStorage.getItem("windUserNotificationLink")==="Stats"){
      setFetchComponent(2);
      setNavigationFirstItem(1);
      localStorage.removeItem("windUserNotificationLink");
    }
    else if(localStorage.getItem("windUserNotificationLink")==="TermsConditions"){
      setFetchComponent(10);
      setNavigationThirdItem(0);
      localStorage.removeItem("windUserNotificationLink");
    }
    else if(localStorage.getItem("windUserNotificationLink")==="PrivacyPolicy"){
      setFetchComponent(11);
      setNavigationThirdItem(1);
      localStorage.removeItem("windUserNotificationLink");
    }
    else if(localStorage.getItem("windUserNotificationLink")==="ContactUs"){
      setFetchComponent(9);
      setNavigationSecondItem(5);
      localStorage.removeItem("windUserNotificationLink");
    }
  },1000);
  return ()=>{
    clearInterval(interval);
  };
  },[]);

  
  const renderPages= (fetchComponent:any)=>{
    switch(fetchComponent){
      case 1:
        return <Dashboard navigation={props.navigation} id={""} />
      case 2:
        return <VisualAnalytics navigation={props.navigation} id={""}/>
      case 3:
        return <Groups navigation={props.navigation} id={""}></Groups>
      case 4:
        return <Profile navigation={props.navigation} id={""} history={undefined}/>   
      case 5:
        return <NotificationSettings navigation={props.navigation} id={""}></NotificationSettings>
      case 6:
        return <RolesPermissions2 navigation={props.navigation} id={""}/>
      case 7:
        return <FaqScreen  navigation={props.navigation} id={""}  />
      case 8:
        return <PreviewVideo navigation={props.navigation} id={""} />
      case 9:
        return <WebAddContactUs navigation={props.navigation} id={""} />
      case 10:
        return <TermsAndConditions navigation={props.navigation} id={""} />
      case 11:
        return <PrivacyPolicy navigation={props.navigation} id={""} />
      case 12:
        return <Notifications navigation={props.navigation} id={""} />
      default:
        return <p>Not available</p>
    }

  }
  const drawer = (
    <div className={classes.silbarContainer}>
      {/* <div className={classes.toolbar} /> */}
      <Box
        color="inherit"
        aria-label="open drawer"
        className={classes.logoButton}
        style={{
          display:"flex",
          gap:"40%"
        }}
      >
        <Avatar
          style={{
            width: "170px",
            height: "180px",
            marginTop:"-30px",
            marginLeft:"-30px"
          }}
         variant="square" alt="Logo" src={Logo} onClick={() => {props.navigation.navigate("WebNavigationMenu")}}/>
        { (window.offsetWidth < 600) ? <IconButton
            color="inherit"
            aria-label="open drawer"
            style={{
              marginBottom:"auto"
            }}
            // edge="start"
            onClick={handleDrawerToggle}
            // className={classes.menuButton}
          >
            <CloseIcon />
          </IconButton> : <></>}
      </Box>
      
      {firstItemList.map((item, index) => {
        return (
          <Button
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              textTransform: "none",
              color:item.isSelected ? "#fff9f9" : "#797777",
              backgroundColor: item.isSelected ? "#369" : "",
              fontWeight:"bolder"
            }}
            onClick={() => {
              const updateList = [...firstItemList];
              setFirstItemList(updateList.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: index === id ? true: false,
                }
              }));
              setFetchComponent(item.id);
              const secondupdatelist = [...secondItemList];
              setSecondItemList(secondupdatelist.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: false
                }
              }));
              const thirdItemListupdate = [...thirdItemList];
              setThirdItemList(thirdItemListupdate.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: false
                }
              }));
              handleDrawerToggle();
            }}
            disabled={item.disabled}
            key={index + item.label}
          >
            
            <Box
              style={{
                display: "flex",
                gap: 10,
                flex: 1,
                justifyContent: "flex-start",
                marginLeft: item.icon==groupIcon?25:30,
                alignItems: "center"
              }}
            >
              <IconButton
                style={{
                  color: item.disabled? "#7d7a7a": "#3f50b5",
                  padding: 0,
                  transform: "rotate(0deg)"
                }}
              >
                <img
                style={{
                  width: item.icon==groupIcon?"26px": "20px",
                  height: item.icon==groupIcon?"18px":"20px",
                  
                }}
                 src={item.icon} alt=""/>
              </IconButton>
              <Typography>{item.label}</Typography>
            </Box>
            {/* <ArrowForwardIosIcon
              style={{
                width: 15,
                color: "grey"
              }}
            /> */}
          </Button>
        );
      })}

      <Divider className={classes.divider} />
      {secondItemList.map((item, index) => {
        return (
          <Button
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              textTransform: "none",
              backgroundColor: item.isSelected ? "#f4f4f4" : "",
              borderLeft: item.isSelected ?"6px solid rgb(51, 102, 153)":"",
              borderRadius: "0px"
            }}
            onClick={() => {
              const updateList = [...secondItemList];
              setSecondItemList(updateList.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: index === id ? true: false,
                }
              }));
              setFetchComponent(item.id);
              const firstItemUpdateList = [...firstItemList];
              setFirstItemList(firstItemUpdateList.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: false,
                }
              }));
              const thirdItemListupdate = [...thirdItemList];
              setThirdItemList(thirdItemListupdate.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: false
                }
              }));
            //   props.navigation.navigate("Profile");
              handleDrawerToggle();
          }}
            
            key={index + item.label}
          >
            {/* <Divider
              style={{
                width: "5px",
                height: "20px",
                backgroundColor: item.isSelected ? "#3f50b5" : "white"
              }}
            /> */}

            <Box
              style={{
                display: "flex",
                gap: 10,
                flex: 1,
                justifyContent: "flex-start",
                marginLeft: 30,
                alignItems: "center"
              }}
            >
              <IconButton
                style={{
                  color: "#3f50b5",
                  padding: 0
                }}
              >
                <img
                style={{
                  width: "20px",
                  height:"20px"
                }}
                 src={item.icon} alt=""/>
              </IconButton>
              <Typography>{item.label}</Typography>
            </Box>
            <ArrowForwardIosIcon
              style={{
                width: 10,
                color: "grey",
                transform: "rotate(0deg)"
              }}
            />
          </Button>
        );
      })}
      <Divider className={classes.divider} />
      {thirdItemList.map((item, index) => {
        return (
          <Button
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              textTransform: "none",
              backgroundColor: item.isSelected ? "#f4f4f4" : "",
              borderLeft: item.isSelected ?"6px solid rgb(0, 102, 51)":"",
              borderRadius: "0px"
            }}
            onClick={() => {
              const updateList = [...thirdItemList];
              setThirdItemList(updateList.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: index === id ? true: false,
                }
              }));
              setFetchComponent(item.id);
              const firstItemUpdateList = [...firstItemList];
              setFirstItemList(firstItemUpdateList.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: false,
                }
              }));
              const secondupdatelist = [...secondItemList];
              setSecondItemList(secondupdatelist.map((listItem, id) => {
                return {
                  ...listItem,
                  isSelected: false
                }
              }));
              handleDrawerToggle();
            }}
            
            key={index + item.label}
          >
           

            <Box
              style={{
                display: "flex",
                gap: 10,
                flex: 1,
                justifyContent: "flex-start",
                marginLeft: 30,
                alignItems: "center"
              }}
            >
              <IconButton
                style={{
                  color: "#357a38",
                  padding: 0
                }}
              >
                <img
                style={{
                  width: "20px",
                  height:"20px"
                }}
                src={item.icon} alt=""/>
              </IconButton>
              <Typography>{item.label}</Typography>
            </Box>
            <ArrowForwardIosIcon
              style={{
                width: 15,
                color: "grey",
                transform: "rotate(0deg)"
              }}
            />
          </Button>
        );
      })}
      <Button
        style={{
          width: "70%",
          display: "block",
          marginTop: 40,
          marginBottom: 20,
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#006633",
          color: "white",
          fontWeight: "bold",
          borderRadius: 19,
          textTransform: "none"
        }}
        onClick={() => {
          const logout = new NavigationMenuController(props);
          logout.postLogout();
          
        }}
      >
        
          Logout
        
      </Button>
    </div>
  );

  const container =
    window !== undefined ? () => document.body : undefined;

  console.log(props);
  // Customizable Area End

  return (
    // Customizable Area Start
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <Box position="fixed" className={classes.appBar}>
        <Toolbar 
        // className={classes.customizeToolbar}
        style={{ minHeight:"45px"}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap>
            Responsive drawer
          </Typography> */}
          <Badge
             badgeContent={unreadCount}
            className={classes.bellButton} 
            {...defaultProps}
            showZero={(unreadCount>0) ? true : false}
          onClick={()=>setFetchComponent(12)}
          >
          </Badge>
        </Toolbar>
      </Box>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} style={{ minHeight:"30px"}}/>
            {   
                renderPages(fetchComponent)
            }
      </main>
    </div>
    // Customizable Area Start
  );

}
