import React from "react";
// Customizable Area Start
import { Container, } from "@material-ui/core";
import "../css/OnboardingMainWeb.css";
// Customizable Area End

import OnboardingguideController, {
  Props,
  configJSON
} from "./OnboardingguideController.web";

export default class Onboardingguide extends OnboardingguideController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  pageRef:any = React.createRef<HTMLDivElement>();
  componentDidMount():any{
    document.addEventListener("click",this.handleClick);
  }
  componentWillUnmount():any{
    document.removeEventListener("click",this.handleClick);
  }
  checkFinalComponent(){
    if( this.state.currentComponent === 5 ){
      console.log("this.state.curren",this.state.components.length);
        this.exitOnboarding(); 
    }
  }
  handleClick=(event:MouseEvent)=>{
    const { clientX } = event;
    const { current } = this.pageRef;

    if(clientX < (current.offsetWidth/2)){
      this.setState((prevState)=>({
        currentComponent: prevState.currentComponent > 0 ? prevState.currentComponent - 1 : prevState.currentComponent,
        }));
    }
    else{
      this.setState((prevState)=>{
        if(prevState.currentComponent >= 4){
          this.exitOnboarding();
          return null;
        }
        else{
         return {currentComponent:prevState.currentComponent <= this.state.components.length ? prevState.currentComponent + 1 : prevState.currentComponent,}
        }
        })
      console.log(this.state.currentComponent, this.state.components.length)
    }
    
  }
  currComponent=()=>{
    console.log("It's on this component",this.state.components[this.state.currentComponent])
  }
  // Customizable Area End
  //Code exception Area Start
  render() {
    let currComponent = this.state.components[this.state.currentComponent];
    return (
      //Merge Engine DefaultContainer
        <>
        <Container className="OnboardingMainContainer" ref={this.pageRef}>
          {currComponent()}
        </Container>
          
        </>


      //Merge Engine End DefaultContainer
    );
    //Code exception Area End
  }
}

// Customizable Area Start

// Customizable Area End
