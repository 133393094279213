const ENV = {
  STAGING: {
    TYPE: 1,
    API_HOST:
      'https://windeverest-225652-ruby.b225652.stage.eastus.az.svc.builder.ai/',
    IS_LIVE:true,
  },
  DEV: {
    TYPE: 2,
    API_HOST:
      'https://windeverest-225652-ruby.b225652.dev.eastus.az.svc.builder.cafe/',
    IS_LIVE:false,
  },
};

export default ENV.STAGING;
