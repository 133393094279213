Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "bx_block_dashboard/dashboards/dashboards";
exports.dashboardGetFleetStatusUrl = "bx_block_dashboard/dashboards/fleet_status";
exports.dashboardPutBatteryValueUrl = "account_block/accounts/";
exports.dashboardGetBatteryValueUrl = "account_block/battery_percentages/late_charging_status";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.endPointNotifications = "bx_block_notifications/notifications";
exports.getDataMethod = "GET";
exports.labelTitleText = "dashboard";
exports.exampleMessage = "Weekly data cannot be displayed";
exports.exampleFleetStatusLoadingError = "Fleet Status is showing an error";
exports.LoadingStatus = "Loading...";
exports.labelBodyText = "dashboard Body";
// Customizable Area End