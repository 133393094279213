Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.ApiContentType = "application/json";
exports.getPostApiMethodType = "GET";
exports.getNotificationApiMethodType = "GET";
exports.getGrousp1ApiMethodType = "GET";
exports.getGrousp2ApiMethodType = "GET";
exports.addCommetApiMethodType = "POST";
exports.addPostApiMethodType = "POST";
exports.deleteCommentApiMethodType = "DELETE";
exports.exampleMessage = "Graph cannot be displayed";
exports.exampleGraphLoadingError = "Graph is showing an error";
exports.LoadingGraph = "Loading...";
exports.visualGPAStatsAPIEndPoint = "bx_block_analytics9/visual_analytics/gpa_stats";
exports.notificationAPIEndPoint = "bx_block_notifications/notifications";
exports.groupChatAPIEndPoint = "bx_block_account_groups/groups?search=";
exports.groupAddCommentAPIEndPoint = "bx_block_posts/posts/";
exports.groupDeleteCommentAPIEndPoint = "bx_block_posts/posts/";
exports.addPostAPIEndPoint = "bx_block_posts/posts";
exports.getPostsAPIEndPoint = "bx_block_posts/posts";
// Customizable Area End