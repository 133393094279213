import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Paper
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { bellIcon } from "./assets";
import "../css/NotificationsWeb.css";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTodaysData=()=>{
    const todaysList = this.state.todaysList && this.state.todaysList.map((item:any)=>{
      let todayDate = item.attributes.created_at;
      let todayDateObj = new Date(todayDate);
      let today = todayDateObj.getDate();
      let monthDate = todayDateObj.toLocaleString('default',{month:"long"});
      let timeToday = todayDateObj.toLocaleTimeString('en-US',{hour:'numeric',minute:'numeric',hour12:true})
      return (
        <Paper elevation={2} className={ !item.attributes.is_read ? "todayUnreadPaper":"todayPaper"} onClick={()=>{this.markAsRead(item.attributes.id); this.navigateToPage(item.attributes.notification_type)}}>
          {  }
          <Typography variant="body1" className="todayContent">{item.attributes.contents}</Typography>
          <Typography variant="body1" className="todayTimer">{today+" "+monthDate+' | '}<b style={{fontWeight:"bold",color:"#3c3a3a",marginBottom:"10px"}}>{timeToday}</b></Typography>
        </Paper>
      ) 
    })
    return todaysList;
  }
  renderYesterdayData=()=>{
    
    const yesterdayList = this.state.yesterdayList && this.state.yesterdayList.map((item:any)=>{
      let yesterdayDate = item.attributes.created_at;
      let yesterdayDateObj = new Date(yesterdayDate);
      let yesterday = yesterdayDateObj.getDate();
      let monthDate = yesterdayDateObj.toLocaleString('default',{month:"long"});
      let timeToday = yesterdayDateObj.toLocaleTimeString('en-US',{hour:'numeric',minute:'numeric',hour12:true}) 
      return (
        <Paper elevation={2} className={ !item.attributes.is_read ? "yesterdayUnreadPaper":"yesterdayPaper"} onClick={()=>{this.markAsRead(item.attributes.id); this.navigateToPage(item.attributes.notification_type)}}>
          <Typography variant="body1" className="yesterdayContent">{item.attributes.contents}</Typography>
          <Typography variant="body1" className="yesterdayTimer">{yesterday+" "+monthDate+' | '}<b style={{fontWeight:"bolder",color:"#3c3a3a",marginBottom:"10px"}}>{timeToday}</b></Typography>
       
        </Paper>
      ) 
    })
    return yesterdayList;
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"lg"}>
          { this.state.data ?
          <Box>
            <Box>
            <Typography variant="h6" className="notificationsTitle">Notifications</Typography>
             
              <Grid container spacing={2}>
                <Grid item md={5} className="todaysGrid">
                  <Typography variant="body1" className="todayHeading">TODAY</Typography>
                  {
                    this.renderTodaysData()
                  }
                </Grid>
                <Grid item md={5} className="yesterdaysGrid">
                  <Typography variant="body1" className="yesterdayHeading">YESTERDAY</Typography>
                  { 
                  this.renderYesterdayData() 
                  }
                  <Paper elevation={2}>

                  </Paper>

                </Grid>
              </Grid>
            </Box>
          </Box>
          :
          <Box sx={webStyle.mainWrapper}>
            <Box>
            <div className="notificationContainer">
            <div className="notificationBodyContainer" >
            <img src={bellIcon} alt="" className="bellIcon" />
            <Typography variant="h6" className="notificationTitle" >No Notifications Yet!</Typography>
            
            <Typography variant="body1" className="notificationInfo" >When you get notifications, they'll show up here.</Typography>
            
            <Button className="buttonRefresh"
              onClick={() => {this.getNotifications()}}>
              Refresh
            </Button>
          </div>
        </div>
        </Box>  
      </Box> }

      </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};
// Customizable Area End
