import React from "react";
import "../css/createNewPassword.css";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Snackbar,

} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import {  googleIcon, Logo, OpenEye, CloseEye, sideCover } from "./assets";
import CoustomTextInput from "../../email-account-registration/src/CoustomTextInput.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#006633",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

export default class CreateNewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  updateEmail = (value:string) => {
    this.setState({
      email: value,
      isError: false
    });
  }
  
  emailValidater = (setShowError: any, value?: string) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const storedEmail = localStorage.getItem("windUserEmail");
  
    let emailError = false;
    let emailErrorMsg = "";
  
    if (value?.trim() === "") {
      emailError = true;
      emailErrorMsg = "Email ID is mandatory";
    } else if (!pattern.test(value!) || !pattern.test(storedEmail!)) {
      emailError = true;
      emailErrorMsg = "Email is not valid, please enter a correct email pattern (example@domain.com)";
    }  
  
    const timeout = setTimeout(() => {
      this.setState({
        emailError,
        emailErrorMsg
      });
      clearTimeout(timeout);
    }, 1000);
  };
  


  passwordValidater = (setShowError:any, value?:string) => {
    //To check a password between 7 to 16 characters which contain only characters, numeric digits, underscore and first character must be a letter
    const pattren = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?!.*\s).*$/
    if(pattren.test(value!)){
      setShowError(false);
      this.setState({
        passwordError: false,
        passwordErrorMsg:""
      });
    }else{
      setShowError(true);
      this.setState({
        passwordError: true,
        passwordErrorMsg: "Password should contains at least 1 uppercase, 1 lowercase, 1 specail character and length should be at least 8 characters"

      });
    }

  }

  rePasswordValidation = (setShowError:any, value?:string) => {
    if(this.state.password !== value!){
      setShowError(true);
      this.setState({
        repasswordError: true,
        repasswordErrorMsg: "Confirm password does not match with password"

      });
    }else{
      setShowError(false);
      this.setState({
        repasswordError: false,
        repasswordErrorMsg:""
      });
    }
  }


  updatePassword = (value: string) => {
    this.setState({
      password: value,
      isError: false
    });
  }

  updateRepassword = (value: string) => {
    this.setState({
      rePassword: value,
      isError: false
    });
  }

  handleResetPassword = (e:any) => {
    e.preventDefault();
    this.createNewPasswordCall();
  }

  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>

      <div data-test-id="createNewPasswordDivId"className="createNewPasswordContainer">
        
        <div style={{backgroundColor:"white"}} className="leftBlock">
          <img
            src={sideCover}
            alt=""
            className="leftBlockImg"
          />
        </div>
        <div className="rightBlock">
          <img data-test-id="imageLogo" src={Logo} alt="" onClick={() => {this.props.navigation.navigate("Home")}}/>
          <form className="loginForm">
            <h2 className="heading">Create New Password</h2>
            <Snackbar data-test-id="errorMsgSnackBar" open={this.state.isError} autoHideDuration={6000} onClose={() => this.setState({ isError: false })}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert severity="error">{this.state.errorMessage}</Alert>
            </Snackbar>
            {/* {this.state.isError && <Typography style={{color: "red", marginBottom: "10px"}} variant="body2">{this.state.errorMessage}</Typography>} */}
            <div className="newpasswordformGroup">
                  <CoustomTextInput
                    label={"Email"}
                    value={this.state.email}
                    updateText={this.updateEmail}
                    validater={this.emailValidater}
                    errorMssage={"Email is not valid, please enter correct email pattren example@domain.com"}
                    showError={this.state.emailError}
                alertMsg={this.state.emailErrorMsg}
                  />
            </div>
            <div className="newpasswordformGroup">
                  <CoustomTextInput
                    password
                    label={"Password"}
                    value={this.state.password}
                    updateText={this.updatePassword}
                    validater={this.passwordValidater}
                    errorMssage={"Password should contains at least 1 uppercase, 1 lowercase, 1 specail character and length should be at least 8 characters"}
                    showError={this.state.passwordError}
                alertMsg={this.state.passwordErrorMsg}
                  />
            </div>
            <div className="newpasswordformGroup">
                  <CoustomTextInput
                    password
                    label={"Confirm Password"}
                    value={this.state.rePassword}
                    updateText={this.updateRepassword}
                    validater={this.rePasswordValidation}
                    errorMssage={"Confirm password is not match with password"}
                    showError={this.state.repasswordError}
                    alertMsg={this.state.repasswordErrorMsg}
                  />
            </div>

            <Button data-test-id="resetBtnId" variant="contained" color="primary" type="submit" className="createNewbtn" onClick={this.handleResetPassword}
           disabled={ this.state.emailError || this.state.email === ""|| this.state.passwordError || this.state.password === ""|| this.state.repasswordError || this.state.rePassword === ""}>
              Reset Password
            </Button>
          </form>
        </div>
      </div>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
