import React from "react";
// Customizable Area Start
import { Container, } from "@material-ui/core";
import SplashscreenNav from "./SplashScreenNav.web";
import SplashscreenFooter from "./SplashScreenFooter.web";
import SplashscreenLanding1 from "./SplashscreenLanding1.web";
import SplashscreenLanding2 from "./SplashScreenLanding2.web";
import SplashscreenLanding3 from "./SplashscreenLanding3.web";
import SplashscreenLanding4 from "./SplashscreenLanding4.web";
import SplashscreenLanding5 from "./SplashscreenLanding5.web";
import SplashscreenLanding6 from "./SplashscreenLanding6.web";
import "../css/LandingPageWeb.css"
// Customizable Area End

import SplashscreenController, { Props } from "./SplashscreenController";


export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleNavigation=(fetchLandingComponent:number)=>{
    this.setState({
      fetchLandingComponent:fetchLandingComponent
    }) 
    window.scrollTo({top:400,left:0,behavior:'smooth'});
  }
  handleMainNavigation=(fetchLandingComponent:number)=>{
    this.setState({
      fetchLandingComponent:fetchLandingComponent
    }) 
    window.scrollTo({top:750,left:0,behavior:'smooth'});
  }

  renderLandingPage=(fetchLandingComponent:number)=>{
    switch(fetchLandingComponent){
      case 1:
        return (<SplashscreenLanding1 videoLink={this.state.videoLink}/>);
      case 2:
        return <SplashscreenLanding2 videoLink={this.state.videoLink}/>
      case 3:
        return <SplashscreenLanding3 usernameError={this.state.usernameError} messageError={this.state.messageError} emailError={this.state.emailError} successMessageSent={this.state.successMessageSent} sendContactMessage={this.addQueryApi}/>
      case 4:
        return <SplashscreenLanding4 termsAndConditions={this.state.termsAndConditions} termsEndDate={this.state.termsEndDate} termsStartDate={this.state.termsStartDate} />
      case 5:
        return <SplashscreenLanding5 privacyPolicy={this.state.privacyPolicy} policyEndDate={this.state.policyEndDate} policyStartDate={this.state.policyStartDate} />
      case 6:
        return <SplashscreenLanding6 faqList={this.state.faqList} />
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container  data-test-id="spalceScreen" className="landingPageWebContainer">
        <SplashscreenNav  handleMainNavigation={this.handleMainNavigation} handleNavigation={this.handleNavigation} goToLogin={this.goToLogin}></SplashscreenNav>
        { this.renderLandingPage(this.state.fetchLandingComponent) }
        <SplashscreenFooter handleNavigation={this.handleNavigation}></SplashscreenFooter>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
