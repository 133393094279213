export const imgSplash = require("../assets/logo.png");
export const dashboardMobile = require("../assets/LandingPage1/dashboardMobile.png");
export const extraFeatureMobile = require("../assets/LandingPage1/extraFeatureMobile.png");
export const groupsMessagingLine = require("../assets/LandingPage1/groupsMessagingLine.png");
export const groupsMessagingMobile = require("../assets/LandingPage1/groupsMessagingMobile.png");
export const personalStatsMobile = require("../assets/LandingPage1/personalStatsMobile.png");
export const appleStoreIcon = require("../assets/LandingPageFooter/appleStoreIcon.png");
export const googlePlayIcon = require("../assets/LandingPageFooter/googlePlayIcon.png");
export const landingFooterMobile = require("../assets/LandingPageFooter/landingFooterMobile.png");
export const navBackgroundImage = require("../assets/LandingPageNav/navBackgroundImage.png");
export const twinPhonesImage = require("../assets/LandingPageNav/twinPhonesImage.png");
export const windeverestIcon = require("../assets/LandingPageNav/windeverestLogo.png");
export const carImg = require("../assets/LandingPage2/car_img.png");
export const poster = require('../assets/LandingPage3/contactus_poster.png');
export const questionIcon = require("../assets/LandingPage6/quesiton-icon.png");
