Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";
exports.getNotificationAPIEndPoint = "bx_block_notifsettings/notification_reminder_settings";
exports.notificationAPIEndPoint = "bx_block_notifsettings/notification_reminder_settings";
exports.notificationVacationModeAPIEndPoint = "bx_block_notifsettings/vacation_mode_settings";
exports.exampleMessage = "Notifications cannot be displayed";
exports.exampleCalendarLoadingError = "Vacation Calendar is showing an error";
exports.LoadingCalendar = "Loading...";
exports.notificationEasyModesAPIEndPoint = "bx_block_notifsettings/easy_modes";

exports.notificationApiContentType = "application/json";

// Customizable Area End