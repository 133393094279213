import React from "react";
// Customizable Area Start
import { Container, Box,Typography,Divider } from "@material-ui/core";
import "../css/LandingPage5.css";
const classes: any = {
    root: {
        borderRadius: 30,
      },
      container: {
        width: "100%",
      },
      box5:{
        width: "100%",
        marginLeft: "10%",
        marginTop: "12%",
        marginBottom: "10%", 
      },
      heading: {
        color: "rgb(51, 102, 153)",
        fontWeight: "bolder",
        marginTop: "15%",
        marginLeft: "32%",
        marginBottom: "4%",
        fontSize: "2.45rem",
      },
      divider: {
        marginTop: 10,
        marginBottom: 60,
        backgroundColor: "#336699",
        width: "80%",
        height:"2px",
      },
      description: {
        display:"inline-block",
        width:"80%",
        textAlign: "justify",
        marginTop: 10,
        marginBottom: 10,
        marginRight: 25
      },
      headingDates:{
        textAlign: "center",
        marginTop: "-4%",
        marginBottom: "4%",
        color: "#369",
        marginLeft: "-18%",
        fontSize:"0.95rem"
      }
  }
  
// Customizable Area End

const SplashscreenLanding5 = (props: any) => {
  // Customizable Area Start
  const renderPolicyList=()=>{
    const policyList = props.privacyPolicy && props.privacyPolicy.map((item:any)=>{
     return (
       <Typography style={classes.description}>{item}</Typography>
     )
   })
   return policyList;  
 }
  // Customizable Area End
  return (
    // Customizable Area Start
    <Container maxWidth="lg" className="landingPage5Container">
          <Box style={classes.box5}>
          <Typography className="privacyHeading" variant="h5">
            Privacy Policy
          </Typography>
          <Typography variant="body2" style={classes.headingDates}>{props.policyStartDate} / {props.policyEndDate}</Typography>
          <Divider light={false}  style={classes.divider} variant="fullWidth"/>
          {renderPolicyList()}
          </Box>
    </Container>
    // Customizable Area End
  );
};

export default SplashscreenLanding5;
