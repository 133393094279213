import React from "react";
// Customizable Area Start
import { Container, Grid, Typography } from "@material-ui/core";
import {landingFooterMobile,googlePlayIcon,appleStoreIcon} from "./assets";
import "../css/LandingPageFooter.css";
// Customizable Area End

const SplashscreenFooter = (props: any) => {
  // Customizable Area Start
  // Customizable Area End
  return (
    // Customizable Area Start
    <Container maxWidth="lg" className="landingPageFooterContainer">
        <img src={landingFooterMobile} className="footerImageBackground"/>
        <Grid container className="footerInfomation">
            <Grid item xs={12} md={12} className="getAppGrid">    
                <Typography variant="h6" className="getAppTypo">Get the APP!</Typography>
            </Grid>
            <Grid container className="iconAppsGrids">
                <Grid item xs={4} md={4} className="googleAppGrids">
                    <img src={googlePlayIcon} className="googlePlayIconImg"></img>
                </Grid>
                <Grid item xs={4} md={4} className="appleAppGrids">
                    <img src={appleStoreIcon} className="appleStoreIconImg"></img>
                </Grid>
            </Grid>
            <Grid xs={12} md={12} className="continueGrid">
                <Typography variant="h6" className="websiteInfo"> Or continue using website</Typography>
            </Grid>
        </Grid>
        <Grid container className="linksFooterSection">
            <Grid container className="linksFooters">
                
                <Grid item xs={2} md={2}>
                    <Typography className="footerAbout" onClick={()=>props.handleNavigation(2)}>About Us</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography className="footerContactUS" onClick={()=>props.handleNavigation(3)}>Contact Us</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography className="footerPrivacy" onClick={()=>props.handleNavigation(5)}>Privacy Policy</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography className="footerTC" onClick={()=>props.handleNavigation(4)}>Terms & Conditions</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography className="footerFAQ" onClick={()=>props.handleNavigation(6)}>FAQ's</Typography>
                </Grid>
            </Grid>
            <hr className="footerHR"/>
            <Typography className="footerCopyright"> Windeverest. Copyright 2023. All rights reserved.</Typography>
        </Grid>
    </Container>
    // Customizable Area End
  );
};

export default SplashscreenFooter;
