import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Grid,Switch, Paper,Tab,List,Divider,Button,Modal } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import  Chart  from "react-google-charts";
import "../css/VisualAnalyticsWeb.css";
import { COTABS, TABS } from "../../../components/src/Constants";
import { blue,orange,share,toggle} from "./assets";
import { FacebookIcon,TwitterIcon, InstapaperIcon, InstapaperShareButton, FacebookShareButton,TwitterShareButton } from "react-share";
import Loader from "../../../components/src/Loader.web";
import { configJSON} from "./VisualAnalyticsController.web";
// Customizable Area End

import VisualAnalyticsController, {
  Props,
} from "./VisualAnalyticsController.web";
import Gamification from "../../Gamification/src/Gamification.web";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  tabDays = ( item:any ) =>{
    const tabList = item.map((tab:any)=>{
      return (
        <Tab
        label={tab?.name}
        data-test-id="vADaysTestTab"
        onChange={() => this.onTab2Click(tab)} 
        className={(this.state.activeTab?.id==tab?.id)?"activeVAPageTab":"inactiveVAPageTab"}
        />
      )
    })
    return tabList;
  }
  cO2tabDays = ( item:any ) =>{
    const tabList = item.map((tab:any)=>{
      return (
        <Tab
        label={tab?.name} 
        data-test-id="tabCO2Days"
        onChange={() => this.onTab4Click(tab)} 
        className={(this.state.activeCOTab?.id==tab?.id)?"activeCO2PageTab":"inactiveCO2PageTab"}
        />
      )
    })
    return tabList;
  }
  handleMessageGPA = ()=>{
    this.setState({
      shareGPAModal:false
    })
  }
  shareGPA=()=>{
    this.getVisualAnalyticsShareGPA();
  }

  _render30daysTab = () => {
    return (
      <>
      {
        this.state.shareGPAModal ? (
          <Grid item xs={12} md={12} lg={6}>
              <Modal open={this.state.shareGPAModal} onClose={this.handleMessageGPA} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="shareMessageGPA">
                  <Typography variant="body1" className="shareWithLabel">Share with...</Typography>
                    <Box className="twitterBox">
                    <TwitterShareButton url={this.state.sharingImageURL}>
                        <TwitterIcon style={{borderRadius:"20px"}} size={36} />
                        <Typography variant="body1"  className="twitterLabel">Twitter</Typography>  
                    </TwitterShareButton>
                    </Box>
                    <hr className="dividerHR"/>
                    <Box className="instagramBox">
                    <InstapaperShareButton url={this.state.sharingImageURL}>
                      <InstapaperIcon style={webStyle.extraView} size={36} />
                      <Typography variant="body1"  className="instagramLabel">Instagram</Typography>
                    </InstapaperShareButton>
                   </Box>
                   <hr className="dividerHR"/>
                    <Box className="facebookBox">
                    <FacebookShareButton url={this.state.sharingImageURL}>
                      <FacebookIcon style={{borderRadius:"20px"}} size={36} />
                      <Typography variant="body1" className="facebookLabel">Facebook</Typography>
                    </FacebookShareButton>
                    </Box>
                </Box>
              </Modal>
             </Grid>
        ):null
      }
      <Box display="flex" flexDirection="column" alignItems="center" className="gpaVA">
        <Typography variant="h6" className="gpaBarValueVA">GPA</Typography>
        <Box className="gpaChartVAContainer">
        { this.state.loadingData ? (<Loader loading={true}></Loader>): 
        (
        <Chart
          chartType="LineChart"
          data={[
            ["x","Your GPA","Benchmark"],
            ...this.state.labelsgpa2.map((label:any,i:any)=>[
              label,
              parseFloat(this.state.datagpa2[i]),
              parseFloat(this.state.datagpa1[i]),
          ])
          ]}
          options={{
            baselineColor:"black",
            backgroundColor: "#f8f8f8",
            series:{
              0: {type:'line',color:'rgb(32, 93, 134)',pointSize:10,pointShape:"circle"},
              1: {type:'line',color:'rgb(255,0,0)',pointSize:3,pointShape:"circle",lineDashStyle:[6,2]}
            },
            lineWidth: 2,
            legend: {
              position: "none",
            },
            hAxis: {
              fontSize:'5px',
              showTextEvery:3,
              slantedTextFalse:false,
              baselineColor:"black"
            },
            vAxis: {
              fontSize:'5px',
              maxValue:4,
              baselineColor:"black",
              gridlines:{
                color:"rgba(0,0,0,0.4)",
                dashStyle:[2,4]
              }
            }
          }}
        />
        )}
        </Box>
        <Divider style={{ margin: "20px 0", width: "100%" }} />
        <Box display="flex" alignItems="center" className="labelsforVA">
          <Box mr={1}>
            <img src={blue} alt="blue" className="blueLabelVA"/>
          </Box>
          <Typography className="yourGPALabel">Your GPA</Typography>
          <Box ml={4}>
            <img src={orange} alt="orange" className="orangeLabelVA"/>
          </Box>
          <Typography className="benchmarkLabel">Benchmark</Typography>
        </Box>
          <Button className="shareButtonVA" onClick={this.shareGPA}>
            <img src={share} className="shareIconVA"></img>
            <Typography variant="h6" className="shareTextVA">Share</Typography>
          </Button>
      </Box>
    </>
    );
  }
  handleMessageCO2 = ()=>{
    this.setState({
      shareCO2Modal:false
    })
  }
  shareCO2=()=>{
    this.getVisualAnalyticsShareCO2();
  }
  _renderCO2Tab = () => {
    return (
      <>
      {
        this.state.shareCO2Modal ? (
          <Grid item xs={12} md={12} lg={6}>
              <Modal className="shareModalCO2" open={this.state.shareCO2Modal} onClose={this.handleMessageCO2} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="shareMessageCO2">
                  <Typography variant="body1" className="shareWithLabel">Share with...</Typography>
                  <Box className="twitterBox">
                     <TwitterShareButton url={this.state.sharingData}>
                        <TwitterIcon style={{borderRadius:"20px"}} size={36} />
                        <Typography variant="body1"  className="twitterLabel">Twitter</Typography>  
                     </TwitterShareButton>  </Box>
                    <hr className="dividerHR"/>
                    <Box className="instagramBox" data-test-id="shareInstaTest">
                    <InstapaperShareButton url={this.state.sharingData}>
                      <InstapaperIcon style={webStyle.extraView} size={36} />
                      <Typography variant="body1"  className="instagramLabel">{configJSON.instagramText}</Typography>
                    </InstapaperShareButton>
                     
                   </Box>
                   <hr className="dividerHR"/>
                    <Box className="facebookBox">
                      <FacebookShareButton url={this.state.sharingData}>
                      <FacebookIcon style={{borderRadius:"20px"}} size={36} />
                      <Typography variant="body1" className="facebookLabel">Facebook</Typography>
                       </FacebookShareButton>
                    </Box>
                </Box>
              </Modal>
             </Grid>
        ):null
      }
      <Box display="flex" flexDirection="column" alignItems="center" className="cO2VA">
        <Typography variant="h6" className="cO2BarValueVA">CO2</Typography>
        <Box className="cO2ChartVAContainer">
        { this.state.loadingDataCO2 ? (<Loader loading={true}></Loader>): 
        (
          <Chart
          className={(this.state.activeTab.id === 0) ? "barDaysChart":"barChart"}
          chartType="ComboChart"
          data={[["x","CO2","Value"],
            ...this.state.data.monthly.labels.map((label:any,i:any)=>[
              label,
              parseInt(this.state.data.monthly.data[i][0]),
              parseInt(this.state.datagpa5[i])
          ])
          ]}
          options={{
            baselineColor:"black",
            backgroundColor: "#f8f8f8",
            series:{
              0: {
                
                type: 'bars',
                color: this.state.data.monthly.barColors[0],
                borderRadius:10,
              
  
              },
              1: {type:'line',color:'rgb(231, 76, 60)',pointSize:3,pointShape:"circle",lineDashStyle:[6,2]}
            },
            bars:{borderRadius:20},
            lineWidth: 3,
            legend: {
              position: "none",
            },
            hAxis: {
              baseLineColor:'black',
              fontSize:'5px',
              showTextEvery:4,
              slantedTextFalse:false,
              },
            vAxis: {
              baseLineColor:'black',
              fontSize:'5px',
              gridlines:{
                color:'rgba(0,0,0,0.4)',
                dashStyle:[2,4]
              }
            }
          }}
        />
        )}
       
      </Box>
        <Divider style={{ margin: "20px 0", width: "100%" }} />
        <Box display="flex" alignItems="center" className="labelsforCO2">
          <Box className="label1CO2">
            <div className="greenLabelCO2"/>
            <Typography className="yourCO2Label">Your CO2 g/kWh</Typography>
          </Box>
          <Box className="label2CO2">
            <img src={orange} alt="orange" className="orangeLabelCO2"/>
            <Typography className="benchmarkCO2Label">Benchmark</Typography>
          </Box>
        </Box>
          <Button className="shareButtonVA" onClick={()=>{this.shareCO2()}}>
            <img src={share} className="shareIconVA"></img>
            <Typography variant="h6" className="shareTextVA">Share</Typography>
          </Button>
      </Box>

    </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Container maxWidth="xl" className="visualAnalyticsDashboard">
        <Box sx={{ flexGrow:1 }} className="visualAnalyticsMainBox"> 
          <Typography variant="h5"  className="visualAnalyticsHeading">
            Personal Statistics
          </Typography>
          <Grid container spacing={2} className="visualContainers">
            <Grid item lg={6} md={12} xs={12} className="gridstatsm">
              <Paper className="visualAnalyticsPaper">
              <Box className="tabListBox">
                <List className="tabVABoxStat">{this.tabDays(TABS)}</List>
                <Box>
                {this.state.activeTab.id <= 2 && this._render30daysTab()}
                </Box>
              </Box>
              </Paper>
            </Grid>
              <Grid item lg={6} md={12} xs={12} style={{marginTop:"20px"}} className="cO2Tab">
                <Grid container direction="column" spacing={2}>
                  <Grid item className="cO2ToggleGrid">
                    <Paper className={this.state.isOn ? "cO2TabPaperOn":"cO2TabPaper"}>
                      <Box className="cO2TabInnerBox">
                        <img src={toggle} className="toggleCO2Image"></img>
                        <Typography variant="body1" className="toggleCO2Text">Your CO2 g/kWh</Typography>
                        <Switch color="primary" checked={this.state.isOn} onChange={()=>this.setState({isOn:!this.state.isOn})} className={this.state.isOn ? "cO2TabOn" : "cO2TabOff"} ></Switch>
                      </Box>
                    </Paper>
                  </Grid>
                  {
                    (this.state.isOn) ?
                    <Grid item lg={6} md={12} xs={12} className="gridCO2sm">
                      <Paper className="visualAnalyticscO2Paper">
                      <Box className="cO2TabListBox">
                        <List className="cO2TabVABox">{this.cO2tabDays(COTABS)}</List>
                        <Box>
                                {this.state.activeCOTab.id === 0 && this._renderCO2Tab()}
                                {this.state.activeCOTab.id === 1 && this._renderCO2Tab()}
                                {this.state.activeCOTab.id === 2 && this._renderCO2Tab()}
                        </Box>
                      </Box>
                      </Paper>
                    </Grid>
                    :
                    <Grid item md={12} xs={12} lg={6} style={{marginTop:"20px"}} className="visualAnalyticsRight">
                      <Box className="gamificationContainer">
                        <Gamification navigation={undefined} id={""} cO2Tab={this.state.isOn} ></Gamification>
                      </Box>
                    </Grid>
                  }
                </Grid>
              </Grid>
            {
              (this.state.isOn) ? 
              <Grid md={12} xs={12}>
                <Box className="gamificationContainer">
                        <Gamification navigation={undefined} id={""} cO2Tab={this.state.isOn}></Gamification>
                </Box>
              </Grid> 
            :
              null
            }
          </Grid>
        </Box>
      </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  extraView:{
    borderRadius:"20px"
  },
};
// Customizable Area End
