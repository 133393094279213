import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Divider,
  Grid,
  Paper
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import "../css/TermsAndConditions.css";
const MyTeme = createTheme({
  palette: {
    primary: {
      main: "#006633",
      contrastText: "#336699",
    }
  }
});

const classes:any = {
  root: {
    // borderRadius: 30
    paddingRight: "60px"
  },
  container: {
   
    marginRight: 20,
    padding: "40px",
    borderRadius:"25px",
    maxWidht: "87%",
    width:"87%",
    paddingLeft: "80px",
    paddingTop:"80px",
    paddingRight:"60px",

  },
  header: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    color: "#336699",
    width: "100%"
  },
  headerHeadingContainer: {},
  headingContainerHeading: {
    fontWeight: "bold"
  },
  divider: {
    marginTop: 10,
    marginBottom: 60,
    backgroundColor: "#336699",
    width: "100%",
    height:"2px",
  },
  description: {
    display:"inline-block",
    width:"100%",
    textAlign: "justify",
    marginTop: 10,
    marginBottom: 10,
    marginRight: 25
  },
  description1: {
    display:"block",
    textAlign: "left",
    marginTop: 20,
    marginBottom: 40,
    marginRight: 25
  },
  btnContainer: {
    display: "flex",
    gap: 20
  },
  outlinebtn: {
    backgroundColor: "white",
    
  },
  btn: {
    backgroundColor: "#006633",
    color: "white"
  },
  inputContainer: {
    marginBottom: 10,
    marginTop: 10
  },
  lable: {
    marginBottom: "5px"
  },
  changePasswordBtn: {
    borderRadius: '1rem',
    color: "#336699",
    border: "2px solid #336699",
    fontWeight: "bold",
    textTransform: "none",
  },
  disabledDeclineBtn: {
    borderRadius: '1rem',
    color: "rgba(0, 0, 0, 0.26)",
    border: "2px solid rgba(0, 0, 0, 0.26)",
    fontWeight: "bold",
    textTransform: "none",
  },
  questionTitle: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  button: {
    backgroundColor: "#006633",
    color: "white",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: '1rem',
  },
  disabledAcceptbutton: {
    backgroundColor: "rgba(0, 0, 0, 0.26)",
    color: "white",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: '1rem',
  }
  ,
  icon: {
    width: "30px",
    marginBottom:"15px",
  }
}
// Customizable Area End

import TermsAndConditionsController, {
  Props,
  configJSON,
} from "./TermsAndConditionsController";
import { termIcon } from "./assets";

export default class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.privacyPolicyApiCall();
    // Customizable Area End
  }

  // Customizable Area Start
  renderTermsList(){
     const termsListItems = this.state.termsList[0] && this.state.termsList[0].map((item:any)=>{
      return (
        <Typography style={classes.description}>{item}</Typography>
      )
    })
    return termsListItems;  
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={MyTeme}>
      <Container maxWidth="xl" style={classes.root}>

      <Paper elevation={2}  style={classes.container} >
        <Box style={classes.header}>
          <img style={classes.icon} src={termIcon} alt="" />
          <Box style={classes.headerHeadingContainer}>
            <Typography
              style={classes.headingContainerHeading}
              variant="h6"
            >
              Terms and Conditions
            </Typography>
            <Typography variant="body2">{this.state.termsStartDate} / {this.state.termsEndDate}</Typography>
          </Box>
        </Box>
        <Divider light={false}  style={classes.divider} variant="fullWidth"/>
          <Box className="termsBoxRendered">
          {this.renderTermsList()}
          </Box>
        <Grid item xs={12} md={6} style={{ marginTop:"60px" }}>
          <Grid container direction="row" alignItems="center" spacing={3} style={{ textAlign: 'center' }}>
            
            <Grid item xs={12} md={5}>
              <Button data-test-id="decline-button" fullWidth variant="outlined" style={ this.state.isTermsAccepted ? classes.disabledDeclineBtn : classes.changePasswordBtn } onClick={this.declineTerms} disabled={this.state.isTermsAccepted}>Decline</Button>
            </Grid>
            <Grid item xs={12} md={5}>
              <Button data-test-id="accept-button" fullWidth style={ this.state.isTermsAccepted ? classes.disabledAcceptbutton : classes.button } onClick={this.acceptTerms} disabled={this.state.isTermsAccepted}>Accept</Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      </Container>
    </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
