import React from "react";
import {
    makeStyles,
  } from "@material-ui/core/styles";
  import { Container,Grid,Typography,Link } from "@material-ui/core";
import { greenCircleIcon,onboardingFourIcon,badgesIcon,popupBadgeIcon,redArrowIcon,barIcon,circleTopLeft,topRightCircle,windEverest} from "./assets";
  const useStyles = makeStyles({
      
  })
import "../css/OnboardingFourWeb.css"
const navigateToPages=(navItem:string)=>{
    if(navItem.includes("termsandconditions")){
     localStorage.setItem("windUserNotificationLink","TermsConditions");
    }
    else if(navItem.includes("privacypolicy")){
      localStorage.setItem("windUserNotificationLink","PrivacyPolicy");
    }
    else if(navItem.includes("contactus")){
        localStorage.setItem("windUserNotificationLink","ContactUs");
    }
  }
const OnboardingFour = ()=>{
    return (
        <Container className="OnboardingFourContainer">
            <Grid container className="topRowGrid">
                <Grid item md={3} className="logoGridFour">
                    <img src={circleTopLeft} className="circleTopLeftImgFour"/>
                    <img src={windEverest} className="windEverestLogoFour"/>
                </Grid>
                <Grid item md={6} className="infoGridFour">
                    <Grid container>
                       <Grid item md={1} className="infoGridIcon">
                            <img src={redArrowIcon} className="arrowIconsFour"/>
                        </Grid>
                        <Grid item md={11}>
                            <Typography className="arrowOneInfoFour">This is your trophy case. The longer you can avoid charges, the higher your grades, the more CO2 efficient you are... get badges for just about every metric.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} className="rightIconGridFour">
                    <img src={topRightCircle} className="rightCircleIconFour" />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={3}>
                    <img src={greenCircleIcon} className="circleBottomLeftFour"></img> 
                </Grid>
                <Grid item md={6}>
                    <img src={onboardingFourIcon} className="laptopImageFour"></img>
                </Grid>
                <Grid item md={3} className="popupBadgeGridFour">
                    <img src={popupBadgeIcon} className="popupBadgeIconFour"></img>
                    <Typography variant="h6" className="badgeHeadingOnboarding">Badges</Typography>
                    <img src={barIcon} className="badgeBarIconOnboarding"></img>             
                    <Typography variant="body1" className="allBadgeInfoOnboarding">As you achieve trickier charging milestones, watch your badges rack up. </Typography>
                     <img src={badgesIcon} className="badgesIconOnboarding"></img>
                     <img src={greenCircleIcon} className="circleBottomRightFour"></img> 
           
                </Grid>
            </Grid>
            <Grid container className="footerOnBoardingEvenFour">    
                <Grid item md={4} className="typographyTACGrid">
                    <Link variant="body1" className="typographyTACFour" href="/WebNavigationMenu" onClick={()=>navigateToPages("termsandconditions")}>Terms and Conditions</Link>
                </Grid>
                <Grid item md={4} className="typographyPolicyGrid">
                    <Link variant="body1" className="typographyPolicyFour" href="/WebNavigationMenu" onClick={()=>navigateToPages("privacypolicy")}>Policy</Link>
                </Grid>
                <Grid item md={4} className="typographyContactGrid">
                    <Link variant="body1" className="typographyContactFour" href="/WebNavigationMenu" onClick={()=>navigateToPages("contactus")}>Contact us</Link>
                </Grid>
            </Grid>            
        </Container>
    )
}
export default OnboardingFour;