import React from "react";
// Customizable Area Start
import { Box, Grid, Typography, Paper } from "@material-ui/core";
import "../css/RolesPermissionsFleetStatus.css";
import { redBarGraph, greenBarGraph, yellowBarGraph } from "./assets";
// Customizable Area End

const RolesPermissionsFleetStatus = (props: any) => {
  // Customizable Area Start
//   const barGraphMsg = props && props.showbatteryInfoMsg;
  const fleetStatus = props && props.fleetStatus
  let yellowMargin,
    redMargin = 0;
  redMargin = fleetStatus.charged - (7 % fleetStatus.decline);
  yellowMargin = redMargin + fleetStatus.decline - (7 % fleetStatus.undecided);
  const weekdays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
  const today = weekdays[new Date().getDay()]
  // Customizable Area End
  return (
    // Customizable Area Start
    <Paper
      elevation={3}
      className="fleetStatusPaper"
    >
      <Box className="fleetStatusDiv">
        <Grid>
          <Typography
            variant="body1"
            className= "fleetStatusRPHeading"
          >
            Late Charge Recording for {today}
          </Typography>
        </Grid>
        <Grid className="fleetStatusIconDiv">
          <Grid container className="fleetStatusIconSubDiv">
            <Grid item md={2} sm={2}>
              <img src={greenBarGraph} className="fleetStatusIcons" />
            </Grid>
            <Grid item md={2} sm={2} className="fleetStatusChartTitleGrid">
              <Typography variant="body1" className="fleetStatusIconChartLabel">
                Charged
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="fleetStatusIconSubDiv">
            <Grid item md={2} sm={2} >
              <img src={redBarGraph} className="fleetStatusIcons" />
            </Grid>
            <Grid item md={2} sm={2} className="fleetStatusChartTitleGrid">
              <Typography variant="body1" className="fleetStatusIconChartLabel">
                Declined
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="fleetStatusIconSubDiv">
            <Grid item md={2} sm={2}>
              <img src={yellowBarGraph} className="fleetStatusIcons" />
            </Grid>
            <Grid item md={2} sm={2} className="fleetStatusChartTitleGrid">
              <Typography variant="body1" className="fleetStatusIconChartLabel">
                Undecided
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="fleetStatusRPGraphDiv">
          <div
            className="fleetStatusGreenRPDiv"
            style={{ width: `${fleetStatus.charged}%` }}
          />
          <div
            className="fleetStatusYellowRPDiv"
            style={{
              width: `${fleetStatus.undecided}%`,
              marginLeft: `${yellowMargin}%`
            }}
          />
          <div
            className="fleetStatusRedRPDiv"
            style={{
              width: `${fleetStatus.decline}%`,
              marginLeft: `${redMargin}%`
            }}
          />
        </Grid>
        
      </Box>
    </Paper>

    // Customizable Area End
  );
};

export default RolesPermissionsFleetStatus;
