import React from "react";
// Customizable Area Start
import { Container, Grid, Typography,Button } from "@material-ui/core";
import {windeverestIcon,twinPhonesImage,navBackgroundImage} from "./assets";
import "../css/LandingPageNavWeb.css";
// Customizable Area End

const SplashscreenNav = (props: any) => {
  // Customizable Area Start
  const scrollDownToMain=()=>{
    window.scrollTo({top:650,left:0,behavior:'smooth'});
  }

  // Customizable Area End
  return (
    // Customizable Area Start
    <Container maxWidth="lg" className="landingPageNavContainer">
        <Grid container className="navbarLandingPage">
            <Grid item xs={6} md={6} className="iconLandingGrid">
            <img src={windeverestIcon} className="windeverestIconLandingPage"></img>
            </Grid>
            <Grid item xs={6} md={6} className="linksLandingPage">
                <Grid item xs={2} md={2} className="smallerLinkGrid">
                    <Typography className="howItWorksLabel" variant="h6" onClick={()=>props.handleMainNavigation(1)}>How it Works</Typography>
                </Grid>
                <Grid item xs={2} md={2} className="aboutTitleGrid">
                    <Typography className="aboutLabel" variant="h6" onClick={()=>props.handleNavigation(2)}>About Us</Typography>
                </Grid>
                <Grid item xs={2} md={2} className="otherTitleGrid">
                    <Button className="loginButtonLandingPage" onClick={props.goToLogin}>Login</Button>
                </Grid>
            </Grid>
        </Grid>
        <Grid container className="lowerNavBarGrid">
            <img src={navBackgroundImage} className="navbarBackground"></img>
            <Grid item xs={4} md={4} className="lowerNavBarGridInfo">
                <Typography variant="h4" className="breezyHeading">Breezy just became easy</Typography>
                <Typography variant="h6" className="windEverestTitle">WindEverest helps EV drivers keep CO2 out of our air.</Typography>
                <Typography variant="body1" className="navInformation">Schedule your charges to match the nights when the wind blows and to avoid those nights coal and natural gas make most of the electricity.</Typography>
                <Button className="startButton" onClick={()=>scrollDownToMain()}>Let's Start!</Button>
            </Grid>
            <Grid item xs={8} md={8} className="lowerNavBarGridImage">
                <img src={twinPhonesImage} className="twinPhonesNavBar"></img>
            </Grid>
        </Grid>
    </Container>
    // Customizable Area End
  );
};

export default SplashscreenNav;
